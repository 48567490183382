// Sidebar.js
import React, { useState } from 'react';
import './Sidebar.css';

const DatabaseIcon = '/images/database_svg.svg'; // Update the path as needed
const ProposalIcon = '/images/write_svg.svg'; // Update the path as needed
const OutlineIcon = '/images/edit_svg.svg'; // Update the path as needed
const DraftIcon = './images/view_svg.svg'; // Update the path as needed
const WorkflowIcon = '/images/workflow.svg'; // Update the path as needed
const ComplianceMatrixIcon = '/images/matrix.svg'; // Update the path as needed
const ProposalStructureIcon = '/images/structure.svg'; // Update the path as needed
const HelpIcon = '/images/help.svg'; // Update the path as needed
const TourIcon = '/images/tourguide.svg'; // Update the path as needed

const Sidebar = ({ 
  isSidebarCollapsed, 
  setSelectedTab, 
  toggleSidebar, 
  handleLogout, 
  userInfo, 
  currentTab, 
  groups, 
  selectedGroup, 
  setSelectedGroup,
  handleStartTour
}) => {

  const isSelected = (tab) => currentTab === tab;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleGroupClick = (group) => {
    console.log("handleGroupClick: " + group);
    setSelectedGroup(group);
    setIsDropdownOpen(false); // Close dropdown after selection
  };


  return (
    <>
      <button className={`toggle-button ${!isSidebarCollapsed ? 'toggle-button-collapsed' : ''}`} onClick={toggleSidebar}>
        <span className="icon"><i className="fas fa-angle-left"></i></span>  {/* Left Arrow */}
      </button>
      <div className={`sidebar ${isSidebarCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-content">
          <div className="company-logo-container">
            {isSidebarCollapsed ? (
              <img src="/images/govex_logo_G.png" alt="Collapsed Logo" className="company-logo-collapsed" />
            ) : (
              <img src="/images/govex_logo_transparent.png" alt="Company Logo" className="company-logo" />
            )}
          </div>
          
          {/*
          <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('samOpportunities') ? 'selected' : ''}`} onClick={() => setSelectedTab('samOpportunities')}>
            <img src={ProposalIcon} alt="Manage Database" className="icon" />
            <span className="button-text">Add to Pipeline</span>
            {!isSidebarCollapsed && <div className="coming-soon-banner">Coming Soon</div>}
          </button>
          */}
          <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('companyProfile') ? 'selected' : ''}`} onClick={() => setSelectedTab('companyProfile')}>
            <img src={OutlineIcon} alt="Manage Database" className="icon" />
            <span className="button-text">Create/Edit Profile</span>
          </button>
          <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('grantsOpportunities') ? 'selected' : ''}`} onClick={() => setSelectedTab('grantsOpportunities')}>
            <img src={ComplianceMatrixIcon} alt="Manage Database" className="icon" />
            <span className="button-text">Find New Opportunities</span>
          </button>
          

          <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('viewPipeline') ? 'selected' : ''}`} onClick={() => setSelectedTab('pipeline')}>
            <img src={ProposalStructureIcon} alt="Manage Database" className="icon" />
            <span className="button-text">View Pipeline</span>
          </button>
          {(userInfo.email === 'bike.maly1@gmail.com' || userInfo.email === 'tyler@govexai.com') && (
            <button
              className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('uploadGrantsToDB') ? 'selected' : ''}`}
              onClick={() => setSelectedTab('uploadGrantsToDB')}
            >
              <img src={ProposalIcon} alt="Manage Database" className="icon" />
              <span className="button-text">Upload Grants File to Database (Admin Only)</span>
            </button>
          )}
          
          {/*
          <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('viewAnalysis') ? 'selected' : ''}`} onClick={() => setSelectedTab('viewAnalysis')}>
            <img src={DraftIcon} alt="View Analysis" className="icon" />
            <span className="button-text">Analyze Previous Awards</span>
            <div className="coming-soon-banner">Coming Soon</div>
          </button>
          */}
          <button className={`dashboard-sidebar-button1 ${isSelected('nameProposal') ? 'selected' : ''}`} onClick={() => setSelectedTab('nameProposal')}>
            <img src={WorkflowIcon} alt="Manage Database" className="icon" />
            <span className="button-text">Select Workflow</span>
          </button>
          <button className={`dashboard-sidebar-button1 ${isSelected('manageDatabase') ? 'selected' : ''}`} onClick={() => setSelectedTab('manageDatabase')}>
            <img src={DatabaseIcon} alt="Manage Database" className="icon" />
            <span className="button-text">Manage Database</span>
          </button>
          <button 
            className={`dashboard-sidebar-button1 ${isSelected('guidedTour') ? 'selected' : ''}`} 
            onClick={() => {setSelectedTab('nameProposal'); handleStartTour();}}
          >
            <img src={TourIcon} alt="Guided Tour" className="icon" />
            <span className="button-text">Guided Tour</span>
          </button>
          {(userInfo.email === 'bike.maly1@gmail.com' || userInfo.email === 'tyler@govexai.com') && (
            <button  className={`dashboard-sidebar-button1 sidebar-button-disabled ${isSelected('adminDashboard') ? 'selected' : ''}`} onClick={() => setSelectedTab('adminDashboard')}> 
              <img src={DraftIcon} alt="Admin Dashboard" className="icon" />
              <span className="button-text">Admin Dashboard (Admin Only)</span>
            </button>
          )}

        </div>
        <div className="sidebar-footer-div">
          {userInfo && (
            <>
              <button className={`dashboard-sidebar-button-HIW ${isSidebarCollapsed ? 'hide-info' : ''}`} onClick={() => setSelectedTab('viewHowItWorks')}>
                <img src={HelpIcon} alt="View How It Works" className="icon" />
                <span className="button-text">Help</span>
              </button>
              <div className={`additional-info-footer ${isSidebarCollapsed ? 'hide-info' : ''}`}>
                <div className="company-name-div">
                  {Array.isArray(groups) && groups.length > 1 ? (
                    <div className="custom-dropdown">
                      <div className="dropdown-header" onClick={toggleDropdown}>
                        {selectedGroup.replace(/_\w+$/, "")} {/* Filter group name */}
                        <span className="custom-arrow">{isDropdownOpen ? '▲' : '▼'}</span>
                      </div>
                      {isDropdownOpen && (
                        <div className="dropdown-list">
                          {groups.map((group, index) => (
                            <div
                              key={index}
                              className={`dropdown-item ${group === selectedGroup ? 'selected' : ''}`}
                              onClick={() => handleGroupClick(group)}
                            >
                              {group.replace(/_\w+$/, "")} {/* Filter group names */}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ) : (
                    <>{groups[0]?.replace(/_\w+$/, "")}</>
                  )}
                </div>


                <div className="number-proposals-div">
                  <div className="number-proposals-text">Number of Proposal Tokens left: </div>
                  <div className="number-proposals-number">{userInfo.numProposals}</div>
                </div>
                {/*
                <div className="number-proposals-div">
                  <div className="number-proposals-text">Number of RFx Tokens left: </div>
                  <div className="number-proposals-number">{userInfo.numProposalsRFx}</div>
                </div>
                */}
              </div>
              <div className={`logout-section ${isSidebarCollapsed ? 'hide-info' : ''}`}>
                <div className="user-info" onClick={() => window.location.href='https://billing.stripe.com/p/login/6oEcOJcKs2dDb4I3cc'}>
                  <div className="username">
                    {userInfo.username} <i className="fas fa-pencil-alt" style={{ marginLeft: '8px', cursor: 'pointer' }}></i>
                  </div>
                  <div className="email">{userInfo.email}</div>
                </div>
                <button className="sidebar-logout-button" onClick={handleLogout}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" width="24" height="24"
                        viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round"
                        strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                        <path d="M9 12h12l-3 -3"></path>
                        <path d="M18 15l3 -3"></path>
                  </svg>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Sidebar;
