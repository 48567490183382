import React, { useState, useRef } from 'react';
import axios from 'axios';
import LoadingBar from 'react-top-loading-bar';

function GenerateFirstDraftSBIR({
  onFirstDraftCreated,
  apiKey,
  setSelectedTab,
  setStreamedContent,
  companyName,
  outlineHeaders,       // old outline headers (may be stale)
  outlineTitle,
  setOutlineContent,    // function to update outline content
  proposalProcessName,
  setFirstDraftSteaming,
  userID,
  volumeTag,
  rfpFile,
  numProposals,
  selectedRefFiles,
  selectedTopics,
  handleSave,           // Our async save function (passed from parent)
  setShowAlert,         // For general alerts
  setShowErrorAlert,    // For error-specific alerts
  aiOutlinePromptMessage,
  groupId,
  setIsGeneratingFirstDraftPopup,
  setCurrentFirstDraftStep
}) {
  const [isLoading, setIsLoading] = useState(false);
  const loadingBarRef = useRef(null);

  const ENV = process.env.NODE_ENV;
  const BASE_URL =
    window.location.hostname === 'localhost'
      ? process.env.REACT_APP_TEST_URL
      : process.env.REACT_APP_LIVE_URL;

  const selectedFilesArray = Object.entries(selectedRefFiles)
    .filter(([_, isSelected]) => isSelected)
    .map(([fileName]) => fileName);

  // This function performs the steps after saving the updated outline.
  const handleAfterSave = async (updatedOutline) => {
    setShowAlert(true);
    setShowErrorAlert(false);
    setIsGeneratingFirstDraftPopup(true);
    setCurrentFirstDraftStep(0);

    setStreamedContent('');
    setSelectedTab('viewGenerationFirstDraft');
    setFirstDraftSteaming(true);

    if (loadingBarRef.current) {
      loadingBarRef.current.continuousStart();
    }

    try {
      // Pass the updated outline to the proposal generation function.
      await generateProposalWithRetry(updatedOutline);
    } catch (error) {
      console.error('Error processing proposal after retry:', error);
      setShowErrorAlert(true);
      setSelectedTab('viewOutline');
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
      setIsLoading(false);
    }
  };

  // New onClick handler that awaits handleSave then continues.
  const handleClick = async () => {
    try {
      // Await handleSave and get the updated outline headers.
      const newOutline = await handleSave();
      console.log("Updated outline header111111s:", newOutline);

      // Proceed with the next steps, passing the updated outline.
      await handleAfterSave(newOutline);
    } catch (error) {
      console.error("Error during handleSave or subsequent actions:", error);
    }
  };

  // Modify generateProposalWithRetry to accept the updated outline as a parameter.
  const generateProposalWithRetry = async (updatedOutline, retryCount = 1) => {
    try {
      const formData = new FormData();
      // Use the updated outline instead of the (possibly stale) prop outlineHeaders.
      formData.append('outlineHeaders', updatedOutline);
      console.log("Using outline headers:", updatedOutline);
      formData.append('outlineTitle', outlineTitle);
      //console.log("Outline title:", outlineTitle);
      formData.append('userID', userID);
      formData.append('rfpFile', rfpFile);
      formData.append('selectedTopics', JSON.stringify(selectedTopics));
      formData.append('aiOutlinePromptMessage', JSON.stringify(aiOutlinePromptMessage));
      //console.log("AI Outline Prompt Message:", aiOutlinePromptMessage);
      formData.append('groupID', groupId);

      selectedFilesArray.forEach(fileName => {
        formData.append('selectedRefFiles', fileName);
      });
      // Optionally update the outline content state (if needed):
      setOutlineContent(updatedOutline);

      const streamResponse = await fetch(`${BASE_URL}/govex/generate-proposal-stream-sbir`, {
        method: 'POST',
        headers: {
          'X-API-KEY': apiKey,
          'X-Company-Name': companyName
        },
        body: formData,
      });

      if (!streamResponse.ok) {
        throw new Error(`Stream response was not ok: ${streamResponse.statusText}`);
      }

      const reader = streamResponse.body.getReader();
      const decoder = new TextDecoder();
      let completeProposalContent = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          //console.log("Streaming complete");
          break;
        }
        const chunk = decoder.decode(value, { stream: true });
        completeProposalContent += chunk;
        setCurrentFirstDraftStep(6);

        // Optionally hide the popup after a delay.
        setTimeout(() => {
          setIsGeneratingFirstDraftPopup(false);
        }, 4000);
        setStreamedContent(prevContent => prevContent + chunk);
      }

      const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal`, {
        method: 'POST',
        headers: {
          'X-API-KEY': apiKey,
          'Content-Type': 'application/json',
          'X-Group-ID': groupId
        },
        body: JSON.stringify({ proposalContent: completeProposalContent }),
      });

      if (!finalizeResponse.ok) {
        throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
      }

      const finalizeData = await finalizeResponse.json();

      axios.post(`${BASE_URL}/govex/convert`, completeProposalContent, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'text/plain',
          'X-Proposal-Name': proposalProcessName,
        },
      })
        .then((response) => {
          const contentDisposition = response.headers['content-disposition'];
          let fileName = 'download.docx'; // Default filename if not found.
          if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
            if (fileNameMatch && fileNameMatch.length > 1) {
              fileName = fileNameMatch[1];
            }
          }

          const blob = new Blob([response.data]);
          const file = new File(
            [blob],
            fileName,
            { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' }
          );
          const documentFile = file;

          onFirstDraftCreated(completeProposalContent, finalizeData.new_num_proposals, documentFile, volumeTag, updatedOutline);
        })
        .catch((error) => {
          console.error('Error downloading the document:', error);
        });

    } catch (error) {
      if (retryCount > 0) {
        console.warn('Retrying proposal generation due to error:', error);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Delay before retrying.
        await generateProposalWithRetry(updatedOutline, retryCount - 1); // Retry once.
      } else {
        throw error;
      }
    }
  };

  return (
    <div>
      <button
        onClick={handleClick}
        className="govex-draft-primary"
        disabled={isLoading || (numProposals === 0)}
        id="step27"
      >
        {isLoading
          ? 'Generating...'
          : (numProposals === 0
            ? 'You are out of tokens. Contact us to purchase more'
            : 'Create a new draft →')}
      </button>
      <LoadingBar color="#f11946" ref={loadingBarRef} />
    </div>
  );
}

export default GenerateFirstDraftSBIR;
