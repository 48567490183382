import React, { useState } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams if you're using React Router for routing
import './ReferralLandingPage.css';
import './PricingModelPage.css';

const PricingOld = () => {

  // State for each slider
  const [numDBs, setNumDBs] = useState(1); // Default is 1 DB
  const [numGrantTokens, setNumGrantTokens] = useState(5); // Default is 0 Grant Tokens
  const [numRFxTokens, setNumRFxTokens] = useState(0); // Default is 0 RFx Tokens
  const [pricingPeriod, setPricingPeriod] = useState('monthly');

  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;

  // Pricing logic
  const calculateMonthlyPrice = () => {
    // DB Pricing
    const dbPrice = (() => {
      if (numDBs === 1) return 100.00;
      if (numDBs === 2) return 189.99;
      if (numDBs === 3) return 269.99;
      if (numDBs === 4) return 339.99;
      if (numDBs === 5) return 399.99;
      if (numDBs === 6) return 449.99;
      if (numDBs === 7) return 489.99;
      if (numDBs === 8) return 519.99;
      if (numDBs === 9) return 539.99;
      if (numDBs === 10) return 549.99;
      return 0;
    })();
  
    // Grant Token Pricing
    const grantTokenPrice = (() => {
      if (numGrantTokens === 1) return 499.99;
      if (numGrantTokens === 2) return 799.99;
      if (numGrantTokens === 3) return 899.99;
      if (numGrantTokens === 4) return 999.99;
      if (numGrantTokens === 5) return 1099.99;
      if (numGrantTokens === 6) return 1199.99;
      if (numGrantTokens === 7) return 1299.99;
      if (numGrantTokens === 8) return 1399.99;
      if (numGrantTokens === 9) return 1499.99;
      if (numGrantTokens === 10) return 1599.99;
      return 0;
    })();
  
    // RFx Token Pricing
    const rfxTokenPrice = (() => {
      if (numRFxTokens === 1) return 99.99;
      if (numRFxTokens === 2) return 189.99;
      if (numRFxTokens === 3) return 269.99;
      if (numRFxTokens === 4) return 339.99;
      if (numRFxTokens === 5) return 399.99;
      if (numRFxTokens === 6) return 449.99;
      if (numRFxTokens === 7) return 489.99;
      if (numRFxTokens === 8) return 519.99;
      if (numRFxTokens === 9) return 539.99;
      if (numRFxTokens === 10) return 549.99;
      return 0;
    })();
  
    // Total monthly price
    return dbPrice + grantTokenPrice + rfxTokenPrice;
  };
  
  const calculateAnnualPrice = () => {
    return calculateMonthlyPrice() * 6; // 50% discount for annual pricing
  };
  

  return (
    <div className="referral-landing-page">
      <div className="animated-border">
        <div className="pricing-section" id="Pricing">
          <div className="pricing-section-text-div">
            <h2 className="pricing-section-title">Enhance your proposal strategy today</h2>
            <span className="pricing-section-span">Customize your plan to suit your needs</span>
          </div>

          <div className="pricing-period-tabs">
            <button
              className={`billing-cycle-option ${pricingPeriod === 'monthly' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('monthly')}
            >
              Monthly
            </button>
            <button
              className={`billing-cycle-option ${pricingPeriod === 'annual' ? 'selected' : ''}`}
              onClick={() => setPricingPeriod('annual')}
            >
              Annual
            </button>
          </div>

          <div className="pricing-sliders-container">
            {/* Slider for Core Product (DBs) */}
            <div className="slider-pricing-container">
              <label htmlFor="db-slider">
                Select number of secure file storage units (DBs):
                <span className="tooltip-icon">?
                  <div className="tooltip-content">
                    <p>
                      A secure file storage unit (DB) allows you to store your company's documents privately. For teams, multiple storage units may be necessary to keep files separate and secure for each team or department.
                    </p>
                  </div>
                </span>
              </label>
              <input
                type="range"
                id="db-slider"
                min="1"
                max="10"
                value={numDBs}
                onChange={(e) => setNumDBs(Number(e.target.value))}
              />
              <span>{numDBs} DB{numDBs > 1 ? 's' : ''}</span>
            </div>        

            {/* Slider for Grant Tokens Add-on */}
            <div className="slider-pricing-container">
              <label htmlFor="grant-token-slider">Select number of Grant Tokens per month:</label>
              <input
                type="range"
                id="grant-token-slider"
                min="5"
                max="10"
                value={numGrantTokens}
                onChange={(e) => setNumGrantTokens(Number(e.target.value))}
              />
              <span>{numGrantTokens} Grant Token{numGrantTokens > 1 ? 's' : ''}</span>
            </div>     
          </div>


          <div className="pricing-summary">
            <h3>Pricing Summary</h3>
            <p>
              {pricingPeriod === 'monthly' ? (
                <>
                  Monthly Total: <strong>${calculateMonthlyPrice().toLocaleString()}</strong>
                </>
              ) : (
                <>
                  Annual Total – Save 50% with a Full-Year Subscription! <br /><strong>${calculateAnnualPrice().toLocaleString()}</strong>
                </>
              )}
            </p>
          </div>

          <form action={`${BASE_URL}/create-checkout-session1`} method="POST">
            <input type="hidden" name="dbs" value={numDBs} />
            <input type="hidden" name="grantTokens" value={numGrantTokens} />
            <input type="hidden" name="rfxTokens" value={numRFxTokens} />
            <input type="hidden" name="billingCycle" value={pricingPeriod} />
            <button className="checkout-button" type="submit">Proceed to Checkout</button>
          </form>

        </div>
      </div>
    </div>
  );
};

export default PricingOld;