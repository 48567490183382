import React, { useState, useEffect } from 'react';
import './AISidebar.css';

const AISidebar = ({
  isOpen,
  onClose,
  onTextChange,
  text,
  isAICollapsed,
  setIsAICollapsed,
  selectedRefFiles,  // Passed in as an object; e.g., { "BigID.docx": true, "GovEx_Overview.pptx": true, ... }
  companyName,
  groupId,
  userID
}) => {
  // NODE_ENV=test in .env
  const ENV = process.env.NODE_ENV; // 'test' or 'live'

  // Select the URL based on the environment
  const BASE_URL = window.location.hostname === 'localhost'
    ? process.env.REACT_APP_TEST_URL
    : process.env.REACT_APP_LIVE_URL;

  const toggleAISidebar = () => {
    setIsAICollapsed(!isAICollapsed);
  };

  const [copySuccess, setCopySuccess] = useState(false);
  const [additionalText, setAdditionalText] = useState('');
  const [chatResponse, setChatResponse] = useState('');

  // When selectedRefFiles is an object, we want two things:
  // 1. An array of all file names (for displaying the checklist)
  // 2. An array of the files that are currently "selected" (checked)
  const allFiles = selectedRefFiles && typeof selectedRefFiles === 'object' && !Array.isArray(selectedRefFiles)
    ? Object.keys(selectedRefFiles)
    : [];

  // Initialize checkedFiles as the files with a truthy value.
  const getInitialCheckedFiles = () => {
    if (selectedRefFiles && typeof selectedRefFiles === 'object' && !Array.isArray(selectedRefFiles)) {
      return Object.keys(selectedRefFiles).filter(file => selectedRefFiles[file]);
    }
    return [];
  };

  const [checkedFiles, setCheckedFiles] = useState(getInitialCheckedFiles());

  // Update the local checkedFiles state if the prop changes.
  useEffect(() => {
    setCheckedFiles(getInitialCheckedFiles());
  }, [selectedRefFiles]);

  // Toggle a file's selection
  const toggleFileSelection = (file) => {
    if (checkedFiles.includes(file)) {
      setCheckedFiles(checkedFiles.filter(f => f !== file));
    } else {
      setCheckedFiles([...checkedFiles, file]);
    }
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(chatResponse)
      .then(() => {
        console.log("Text copied to clipboard");
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 1000);
      })
      .catch(err => {
        console.error("Failed to copy text: ", err);
      });
  };

  const handleSubmit = async () => {
    // Combine the highlighted text with additional text
    const combinedInput = `${text}\n${additionalText}`;
    console.log("Combined input:", combinedInput);
    console.log("Selected ref files (from checklist):", checkedFiles);
    
    try {
      // Pass the currently selected reference files along with the prompt and company info.
      const response = await fetch(`${BASE_URL}/govex/aichat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          input: combinedInput,
          selectedRefFiles: checkedFiles,  // Now an array of file names
          companyName: companyName,
          groupId: groupId,
          userID: userID
        })
      });

      if (!response.ok) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let completeResponse = '';

      while (true) {
        const { value, done } = await reader.read();
        if (done) break;
        const chunk = decoder.decode(value, { stream: true });
        completeResponse += chunk;
        if (chunk) setChatResponse(completeResponse);
      }
      console.log("Complete response received:", completeResponse);
    } catch (error) {
      console.error('Error communicating with ChatGPT:', error);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  if (!isOpen) return null;

  const handleAdditionalTextChange = (e) => {
    setAdditionalText(e.target.value);
  };

  return (
    <>
      <button
        className={`toggle-button-ai ${!isAICollapsed ? 'toggle-button-ai-collapsed' : ''}`}
        onClick={toggleAISidebar}
      >
        <span className="ai-icon">&#11166;</span>
      </button>

      <div className={`AIsidebar ${isAICollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          {isAICollapsed ? 'AI' : 'AI Assistant'}
        </div>

        {/* Checklist for selected reference files */}
        <div className="ref-file-checklist">
          <h3>Select Reference Files</h3>
          {allFiles.length > 0 ? (
            allFiles.map((file, index) => (
              <div key={index} className="ref-file-item">
                <label>
                  <input
                    type="checkbox"
                    checked={checkedFiles.includes(file)}
                    onChange={() => toggleFileSelection(file)}
                  />
                  <span className="file-name">{file}</span>
                </label>
              </div>
            ))
          ) : (
            <p>No reference files available</p>
          )}
        </div>


        <div className={`chat-response-textarea ${isAICollapsed ? 'collapsed' : ''} ${!chatResponse ? 'placeholder' : ''}`}>
          <button
            className={`copyButton ${isAICollapsed ? 'collapsed' : ''} ${copySuccess ? 'copy-success' : ''}`}
            onClick={handleCopyToClipboard}
            aria-label="Copy"
          >
            {/* Optionally, include an icon or text here */}
          </button>
          {chatResponse || "Hi! How can I assist you today?"}
        </div>

        <div className={`highlighted-text-display ${isAICollapsed ? 'collapsed' : ''}`}>
          <div className="highlighted-text-label">Our AI will use this highlighted text:</div>
          <div className="highlighted-text-content">{text}</div>
        </div>

        <div className={`additional-notes-container ${isAICollapsed ? 'collapsed' : ''}`}>
          <textarea
            className="additional-notes-textarea"
            placeholder="What would you like to do with this?"
            onChange={handleAdditionalTextChange}
            onKeyDown={handleKeyDown}
            value={additionalText}
          />
          <button onClick={handleSubmit}>Submit</button>
        </div>
      </div>
    </>
  );
};

export default AISidebar;
