import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import './Navbar.css';
import GoogleSignIn from './GoogleSignIn';
import { useNavigate } from 'react-router-dom';

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  const location = useLocation();
  const isDashboard = location.pathname === '/';
  const isDashboardSBIR = location.pathname === '/dashboard-grants' || location.pathname === '/dashboard' || location.pathname === '/pricingmodel';




  useEffect(() => {
    if (isLoggedIn) {
      navigate('/dashboard');
    }
  }, [isLoggedIn, navigate]);

  const handleLoginSuccess = (response) => {
    // Process the login success, store the token, etc.
    console.log("login success");
    setIsLoggedIn(true);
  };


    const handleLoginFailure = (response) => {
      //console.log('Login Failed:', response);
    };

    const toggleLoginModal = () => {
      setShowLogin(!showLogin);
    };

  useEffect(() => {
    const checkScreenWidth = () => {
      setIsMobileScreen(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check
    checkScreenWidth();

    // Event listener for window resize
    window.addEventListener('resize', checkScreenWidth);

    // Cleanup
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const closeMobileMenu = () => {
      if (isMobileScreen) {
        setIsMobileMenuOpen(false);
      }
    };

  if (isDashboard || isDashboardSBIR) return null;

  // Smooth scroll function
  const scrollToSection = (event, sectionId) => {
    event.preventDefault(); // Prevent default anchor link behavior
    closeMobileMenu(); // Close the mobile menu
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // Fallback for when the section ID is not found; navigate to the homepage
      // This is useful if the Navbar is used on other pages and the target section doesn't exist there
      navigate('/'); // Navigate to the homepage first
      setTimeout(() => {
        // Wait for the navigation to complete and the page to load
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    }
  };

  return (
      <nav>
       <div className ="nav-wrapper">
        <div className="logo">
          <a href="/" /* Add the link to your homepage here */>
            <img src="/images/govex_name_transparent.png" alt="Your Tech Company Logo" />
          </a>
        </div>
        {isMobileScreen && (
          <div className={`menu-icon ${isMobileMenuOpen ? 'active' : ''}`} onClick={handleMobileMenuToggle}>
            &#9776; {/* Unicode hamburger icon */}
          </div>
        )}
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
        {/*
          <li className="mainNavLink">
            <NavLink className="mainNavLinkText">Resources</NavLink>
            <ul className="submenu">
              <li><NavLink to="/whycapi"><span className="subLinkText">What is GovEx?</span></NavLink></li>
              <li><NavLink to="/security"><span className="subLinkText">Proposal Writer</span></NavLink></li>
              <li><NavLink to="/blog"><span  className="subLinkText">Blog</span></NavLink></li>
            </ul>
          </li>
          <li className="mainNavLink"><NavLink to="/blog" className="mainNavLinkText">Blog</NavLink></li>
          <li className="mainNavLink"><NavLink to="/team" className="mainNavLinkText">Team</NavLink></li>
        */}
          <li className="mainNavLink">
            <a href="#Proposal-Writer" className="mainNavLinkText" onClick={(e) => scrollToSection(e, 'Proposal-Writer')}>Proposal Writer</a>
          </li>
          <li className="mainNavLink">
            <a href="#Customer-Stories" className="mainNavLinkText" onClick={(e) => scrollToSection(e, 'Customer-Stories')}>Customer Stories</a>
          </li>
          <li className="mainNavLink">
            <NavLink to="/blog" className="mainNavLinkText" onClick={closeMobileMenu}>Blog</NavLink>
          </li>


          {isMobileScreen && (
          <>
            <li className="mainNavLink">
                <div className="dashboard-button-navbar">
                    <li className="mainNavLink1">
                      <NavLink to="/dashboard" className="mainNavLinkText1" onClick={closeMobileMenu}>Log in</NavLink>

                    </li>
                </div>
            </li>
            <li className="mainNavLink">
                <div className="dashboard-button-navbar">
                    <button className="dashboard-button" onClick={closeMobileMenu}>
                      <a href="/contact" className="dashboard-button-text">Book a Demo ➾</a>
                    </button>
                </div>
            </li>

          </>
          )}

        </ul>
        {!isMobileScreen && (
            <div className="dashboard-button-navbar">
              <li className="mainNavLink1">
                <NavLink to="/dashboard" className="mainNavLinkText1">Log in</NavLink>
              </li>
              <button className="dashboard-button" onClick={(e) => scrollToSection(e, 'Pricing')}>
                <a href="/contact" className="dashboard-button-text">Book a Demo ➾</a>
              </button>
            </div>
          )}


       </div>
      </nav>
    );
}

export default Navbar;
