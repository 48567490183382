import React, { useState, useEffect } from 'react';
import './AdminDashboard.css';

const AdminDashboard = ({ keyCloakToken }) => {
  // State for Keycloak groups and their users
  const [groups, setGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]); // Array of group IDs that are expanded
  const [isLoading, setIsLoading] = useState(false);

  // Fields for creating a new user
  const [newUserEmail, setNewUserEmail] = useState('');
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [tempPassword, setTempPassword] = useState('');
  const [selectedGroups, setSelectedGroups] = useState([]);

  // Group attributes editing (for existing groups)
  // Stored as: { groupId: { attrName: value, ... }, ... }
  const [groupEdits, setGroupEdits] = useState({});

  // Fields for creating a new group
  const [newGroupCompanyName, setNewGroupCompanyName] = useState('');
  const [newGroupNumProposals, setNewGroupNumProposals] = useState('');

  // State for modal controls for adding existing users to a group
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [modalGroupId, setModalGroupId] = useState('');
  const [selectedUsersToAdd, setSelectedUsersToAdd] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const BASE_URL =
    window.location.hostname === 'localhost'
      ? process.env.REACT_APP_TEST_URL
      : process.env.REACT_APP_LIVE_URL;

  // Fetch groups and users on mount
  useEffect(() => {
    const fetchGroupsAndUsers = async () => {
      if (!keyCloakToken) return;
      try {
        setIsLoading(true);
        const resp = await fetch(`${BASE_URL}/admin/groups-and-users?briefRepresentation=false`, {
          headers: {
            Authorization: `Bearer ${keyCloakToken}`,
          },
        });
        if (!resp.ok) {
          throw new Error(`Failed to fetch groups, status: ${resp.status}`);
        }
        const data = await resp.json();
        setGroups(data);
      } catch (error) {
        console.error('Error fetching groups & users:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchGroupsAndUsers();
  }, [keyCloakToken, BASE_URL]);

  // Toggle expand/collapse for a group
  const toggleGroup = (groupId) => {
    setExpandedGroups((prev) => {
      if (prev.includes(groupId)) {
        return prev.filter((id) => id !== groupId);
      } else {
        return [...prev, groupId];
      }
    });
  };

  // Refresh groups after changes
  const refreshGroups = async () => {
    try {
      setIsLoading(true);
      const resp = await fetch(`${BASE_URL}/admin/groups-and-users?briefRepresentation=false`, {
        headers: {
          Authorization: `Bearer ${keyCloakToken}`,
        },
      });
      if (!resp.ok) {
        throw new Error(`Failed to fetch groups, status: ${resp.status}`);
      }
      const data = await resp.json();
      setGroups(data);
    } catch (error) {
      console.error('Error refreshing groups & users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Remove a user from a group
  const handleRemoveUser = async (userId, groupId) => {
    if (!window.confirm('Are you sure you want to remove this user from this group?')) {
      return;
    }
    try {
      setIsLoading(true);
      const resp = await fetch(`${BASE_URL}/admin/remove-user-from-group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keyCloakToken}`,
        },
        body: JSON.stringify({ userId, groupId }),
      });
      if (!resp.ok) {
        throw new Error(`Failed to remove user, status: ${resp.status}`);
      }
      refreshGroups();
    } catch (error) {
      console.error('Error removing user from group:', error);
      setIsLoading(false);
    }
  };

  // Create a new user (without using a <form> tag)
  const handleCreateUser = async () => {
    if (!newUserEmail || !newFirstName || !newLastName || !tempPassword) {
      alert('Please fill out Email, First Name, Last Name, and Temp Password.');
      return;
    }
    if (selectedGroups.length === 0) {
      alert('Select at least one group to assign.');
      return;
    }
    try {
      setIsLoading(true);
      const resp = await fetch(`${BASE_URL}/admin/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keyCloakToken}`,
        },
        body: JSON.stringify({
          email: newUserEmail,
          firstName: newFirstName,
          lastName: newLastName,
          tempPassword,
          groupIds: selectedGroups,
        }),
      });
      if (!resp.ok) {
        throw new Error(`Failed to create user, status: ${resp.status}`);
      }
      setNewUserEmail('');
      setNewFirstName('');
      setNewLastName('');
      setTempPassword('');
      setSelectedGroups([]);
      refreshGroups();
    } catch (error) {
      console.error('Error creating user:', error);
      setIsLoading(false);
    }
  };

  // Handle multi-group selection for user creation
  const handleGroupSelectionChange = (groupId) => {
    setSelectedGroups((prev) => {
      if (prev.includes(groupId)) {
        return prev.filter((g) => g !== groupId);
      }
      return [...prev, groupId];
    });
  };

  // Start editing group attributes for an existing group
  const handleEditAttributes = (group) => {
    const existingAttrs = group.attributes || {};
    const flatAttrs = {};
    Object.entries(existingAttrs).forEach(([k, v]) => {
      flatAttrs[k] = Array.isArray(v) ? v[0] : v;
    });
    setGroupEdits((prev) => ({
      ...prev,
      [group.groupId]: flatAttrs,
    }));
  };

  // Update local state when editing group attributes
  const handleAttributeChange = (groupId, attrKey, newVal) => {
    setGroupEdits((prev) => ({
      ...prev,
      [groupId]: {
        ...prev[groupId],
        [attrKey]: newVal,
      },
    }));
  };

  // Save group attributes to backend
  const handleSaveAttributes = async (groupId) => {
    const attrObj = groupEdits[groupId] || {};
    try {
      setIsLoading(true);
      const resp = await fetch(`${BASE_URL}/admin/update-group-attributes`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keyCloakToken}`,
        },
        body: JSON.stringify({
          groupId,
          attributes: attrObj,
        }),
      });
      if (!resp.ok) {
        throw new Error(`Failed to update group attributes, status: ${resp.status}`);
      }
      setGroupEdits((prev) => ({
        ...prev,
        [groupId]: null,
      }));
      refreshGroups();
    } catch (error) {
      console.error('Error updating group attributes:', error);
      setIsLoading(false);
    }
  };

  // Create a new group
  const handleCreateGroup = async () => {
    if (!newGroupCompanyName || !newGroupNumProposals) {
      alert('Please fill out Company Name and Number of Proposals.');
      return;
    }
    try {
      setIsLoading(true);
      const response = await fetch(`${BASE_URL}/admin/create-group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keyCloakToken}`,
        },
        body: JSON.stringify({
          company_name: newGroupCompanyName,
          num_proposals: newGroupNumProposals,
        }),
      });
      if (!response.ok) {
        throw new Error(`Failed to create group, status: ${response.status}`);
      }
      console.log("New group created successfully!");
      setNewGroupCompanyName('');
      setNewGroupNumProposals('');
      refreshGroups();
    } catch (error) {
      console.error("Error creating group:", error);
      setIsLoading(false);
    }
  };

  // Open modal to add existing users to a group
  const openAddUserModal = async (groupId) => {
    setModalGroupId(groupId);
    setSelectedUsersToAdd([]);
    setShowAddUserModal(true);
    if (allUsers.length === 0) {
      try {
        setIsLoading(true);
        const resp = await fetch(`${BASE_URL}/admin/all-users`, {
          headers: {
            Authorization: `Bearer ${keyCloakToken}`,
          },
        });
        if (!resp.ok) {
          throw new Error(`Failed to fetch all users, status: ${resp.status}`);
        }
        const data = await resp.json();
        setAllUsers(data);
      } catch (error) {
        console.error('Error fetching all users:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  // Toggle selection of a user in the modal
  const toggleUserSelection = (userId) => {
    setSelectedUsersToAdd((prev) => {
      if (prev.includes(userId)) {
        return prev.filter((id) => id !== userId);
      }
      return [...prev, userId];
    });
  };

  // Handle adding selected users to the group
  const handleAddUsersToGroup = async () => {
    if (!modalGroupId || selectedUsersToAdd.length === 0) {
      alert("Select at least one user to add.");
      return;
    }
    try {
      setIsLoading(true);
      const resp = await fetch(`${BASE_URL}/admin/add-users-to-group`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${keyCloakToken}`,
        },
        body: JSON.stringify({
          groupId: modalGroupId,
          userIds: selectedUsersToAdd,
        }),
      });
      if (!resp.ok) {
        throw new Error(`Failed to add users to group, status: ${resp.status}`);
      }
      setShowAddUserModal(false);
      setSelectedUsersToAdd([]);
      refreshGroups();
    } catch (error) {
      console.error('Error adding users to group:', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-dashboard-container">
      {isLoading && (
        <div className="loading-modal">
          <div className="loading-modal-content">
            <p>Loading...</p>
          </div>
        </div>
      )}

      <h2 className="admin-dashboard-title">Admin Dashboard</h2>
      <p className="admin-dashboard-desc">
        Keycloak Groups, their attributes, and their members. You can create users and groups,
        remove users, edit group attributes, and add existing users to groups.
      </p>

      {/* GROUP LIST */}
      <div className="group-list">
        {groups.map((group) => {
          const isExpanded = expandedGroups.includes(group.groupId);
          const editingAttrs = groupEdits[group.groupId];
          return (
            <div key={group.groupId} className="group-container">
              <div className="group-header">
                <span className="group-name">{group.groupName}</span>
                <div className="group-header-buttons">
                  <button className="admin-btn-toggle" onClick={() => toggleGroup(group.groupId)}>
                    {isExpanded ? 'Hide' : 'Show'}
                  </button>
                  <button className="admin-btn-toggle" onClick={() => openAddUserModal(group.groupId)}>
                    Add Users
                  </button>
                </div>
              </div>
              {isExpanded && (
                <div className="group-details">
                  {/* GROUP ATTRIBUTES */}
                  <h4>Group Attributes</h4>
                  {!editingAttrs ? (
                    <div className="group-attributes-container">
                      {group.attributes 
                        ? Object.entries(group.attributes).map(([k, v]) => {
                            const val = Array.isArray(v) ? v.join(', ') : v;
                            return (
                              <div key={k} className="group-attribute-item">
                                <strong>{k}:</strong> {val}
                              </div>
                            );
                          })
                        : <em>No attributes</em>
                      }
                      <button className="admin-btn-toggle" onClick={() => handleEditAttributes(group)}>
                        Edit
                      </button>
                    </div>
                  ) : (
                    <div className="group-attributes-edit">
                      {Object.entries(editingAttrs).map(([k, v]) => (
                        <div key={k} className="group-attribute-edit-item">
                          <label>
                            {k}:
                            <input
                              type="text"
                              value={v}
                              onChange={(e) => handleAttributeChange(group.groupId, k, e.target.value)}
                            />
                          </label>
                        </div>
                      ))}
                      <button className="admin-btn-submit" onClick={() => handleSaveAttributes(group.groupId)}>
                        Save
                      </button>
                      <button className="admin-btn-toggle" onClick={() => setGroupEdits((prev) => ({ ...prev, [group.groupId]: null }))}>
                        Cancel
                      </button>
                    </div>
                  )}

                  {/* GROUP USERS */}
                  <h4>Group Members</h4>
                  <ul className="user-list">
                    {group.users.map((user) => {
                      let displayName = user.username;
                      const maxLen = 20;
                      if (displayName.length > maxLen) {
                        displayName = displayName.slice(0, maxLen - 3) + '...';
                      }
                      return (
                        <li key={user.userId} className="user-item">
                          {displayName}
                          <button className="admin-btn-remove" onClick={() => handleRemoveUser(user.userId, group.groupId)}>
                            Remove
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          );
        })}
      </div>
      
      <hr className="admin-hr" />

      {/* CREATE NEW USER SECTION */}
      <h3 className="admin-form-title">Create New User</h3>
      <div className="admin-user-creation">
        <div className="form-field">
          <label>Email (Username):</label>
          <input type="email" value={newUserEmail} onChange={(e) => setNewUserEmail(e.target.value)} />
        </div>
        <div className="form-field">
          <label>First Name:</label>
          <input type="text" value={newFirstName} onChange={(e) => setNewFirstName(e.target.value)} />
        </div>
        <div className="form-field">
          <label>Last Name:</label>
          <input type="text" value={newLastName} onChange={(e) => setNewLastName(e.target.value)} />
        </div>
        <div className="form-field">
          <label>Temporary Password:</label>
          <input type="text" value={tempPassword} onChange={(e) => setTempPassword(e.target.value)} />
        </div>
        <div className="form-field-group-assignment">
          <h4>Assign to Groups:</h4>
          <p className="group-assignment-hint">Select all groups you want this user to belong to:</p>
          {groups.map((group) => (
            <div key={group.groupId} className="group-checkbox">
              <label>
                <input type="checkbox" checked={selectedGroups.includes(group.groupId)} onChange={() => handleGroupSelectionChange(group.groupId)} />
                {group.groupName}
              </label>
            </div>
          ))}
        </div>
        <button className="admin-btn-submit" onClick={handleCreateUser}>
          Create User
        </button>
      </div>

      <hr className="admin-hr" />

      {/* CREATE NEW GROUP SECTION */}
      <h3 className="admin-form-title">Create New Group</h3>
      <div className="admin-group-creation">
        <div className="form-field">
          <label>Company Name:</label>
          <input type="text" value={newGroupCompanyName} onChange={(e) => setNewGroupCompanyName(e.target.value)} />
        </div>
        <div className="form-field">
          <label>Number of Proposals:</label>
          <input type="number" value={newGroupNumProposals} onChange={(e) => setNewGroupNumProposals(e.target.value)} />
        </div>
        <button className="admin-btn-submit" onClick={handleCreateGroup}>
          Create Group
        </button>
      </div>

      {/* ADD USERS TO GROUP MODAL */}
      {showAddUserModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Select Users to Add</h3>
            <ul className="modal-user-list">
              {allUsers.map((user) => {
                let displayName = user.username;
                const maxLen = 20;
                if (displayName.length > maxLen) {
                  displayName = displayName.slice(0, maxLen - 3) + '...';
                }
                return (
                  <li key={user.userId} className="modal-user-item">
                    <label>
                      <input
                        type="checkbox"
                        checked={selectedUsersToAdd.includes(user.userId)}
                        onChange={() => toggleUserSelection(user.userId)}
                      />
                      {displayName}
                    </label>
                  </li>
                );
              })}
            </ul>
            <div className="modal-actions">
              <button className="admin-btn-submit" onClick={handleAddUsersToGroup}>
                Add Selected Users
              </button>
              <button className="admin-btn-toggle" onClick={() => setShowAddUserModal(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
