import React, { useState } from 'react';
import './ReferralLandingPage.css';
import './PricingModelPage.css';

const PricingModelWizard = () => {
  // All your state
  const [step, setStep] = useState(1);              // which tab/step are we on
  const [numDBs, setNumDBs] = useState(1);
  const [numGrantTokens, setNumGrantTokens] = useState(0);
  const [hasGrantMatching, setHasGrantMatching] = useState(false);
  const [pricingPeriod, setPricingPeriod] = useState('monthly');

  const BASE_URL =
    window.location.hostname === 'localhost'
      ? process.env.REACT_APP_TEST_URL
      : process.env.REACT_APP_LIVE_URL;

  // Convert a numeric price to a string with commas, exactly 2 decimals
  // e.g., 1399.99 -> "1,399.99"
  const formatPrice = (value) => {
    return value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  };


  // Pricing logic
  const getDBPrice = (dbCount) => {
    switch (dbCount) {
      case 1:  return 99.99;
      case 5:  return 399.99;
      case 10: return 699.99;
      default: return 0;
    }
  };

  const getGrantTokenPrice = (tokenCount) => {
    switch (tokenCount) {
      case 0:
        return 0;
      case 5:
        return 449.99;
      case 10:
        return 599.99;
      case 15:
        return 719.99;
      case 20:
        return 809.99;
      case 25:
        return 899.99;
      case 30:
        return 989.99;
      case 35:
        return 1079.99;
      case 40:
        return 1169.99;
      case 45:
        return 1259.99;
      case 50:
        return 1349.99;
      default:
        return 0;
    }
  };
  
  const getGrantMatchingPrice = (matchingSelected, tokenCount) => {
    if (!matchingSelected) return 0;
    // If no tokens, return 299.99; otherwise, 149.99.
    return tokenCount === 0 ? 299.99 : 149.99;
  };
  
  
  const calculateMonthlyPrice = () => {
    const dbPrice = getDBPrice(numDBs);
    const tokenPrice = getGrantTokenPrice(numGrantTokens);
    const matchingPrice = getGrantMatchingPrice(hasGrantMatching, numGrantTokens);
    return dbPrice + tokenPrice + matchingPrice;
  };

  const calculateAnnualPrice = () => {
    // 50% discount approach => monthly * 6
    return calculateMonthlyPrice() * 6;
  };

  // Helper to move between steps
  const goNext = () => setStep((prev) => prev + 1);
  const goBack = () => setStep((prev) => prev - 1);

  // The 4 "tabs" or steps
  const renderStepContent = () => {
    switch (step) {
      case 1:
      // Step 1: DB Tiers
      return (
        <div className="wizard-step">
          <h3>Select Core DB Tier</h3>
          <div className="db-radio-group">
      
            <label className="db-radio-option">
              <input
                type="radio"
                className="db-radio-input"
                name="numDBs"
                value={1}
                checked={numDBs === 1}
                onChange={() => setNumDBs(1)}
              />
              <span className="db-radio-label">
                1 DB <span className="db-price-label">($99.99/mo)</span>
              </span>
            </label>
      
            <label className="db-radio-option">
              <input
                type="radio"
                className="db-radio-input"
                name="numDBs"
                value={5}
                checked={numDBs === 5}
                onChange={() => setNumDBs(5)}
              />
              <span className="db-radio-label">
                5 DBs <span className="db-price-label">($399.99/mo)</span>
              </span>
            </label>
      
            <label className="db-radio-option">
              <input
                type="radio"
                className="db-radio-input"
                name="numDBs"
                value={10}
                checked={numDBs === 10}
                onChange={() => setNumDBs(10)}
              />
              <span className="db-radio-label">
                10 DBs <span className="db-price-label">($699.99/mo)</span>
              </span>
            </label>
      
          </div>
        </div>
      );


      case 2:
        // Step 2: Grant Tokens
        const currentTokenMonthlyPrice = getGrantTokenPrice(numGrantTokens); // e.g. 199.99
        return (
          <div className="wizard-step">
            <h3>Select Proposal Tokens</h3>
            <label htmlFor="grant-token-slider">
              Choose how many Proposal Tokens you want per month:
            </label>
        
            <div className="grant-token-count-p-container">
              <p className="grant-token-count-p">
                {numGrantTokens} Proposal Token{numGrantTokens !== 1 ? 's' : ''}
              </p>
              {/* Show the monthly cost for the current token selection */}
              <p className="grant-token-price-p">
                {numGrantTokens > 0
                  ? `$${formatPrice(currentTokenMonthlyPrice)}/mo`
                    : '$0.00/mo'}
              </p>
            </div>
        
            <input
              className="grant-token-slider"
              type="range"
              id="grant-token-slider"
              min="0"
              max="50"
              step="5"
              value={numGrantTokens}
              onChange={(e) => setNumGrantTokens(Number(e.target.value))}
            />

            
          </div>
        );


        case 3:
          // Step 3: Matching Module
          return (
            <div className="wizard-step matching-module-step">
              <h3>Opportunity Matching Module</h3>
        
              <div className="matching-module-row">
                <label
                  htmlFor="grant-matching-checkbox"
                  className="grant-matching-label"
                >
                  Add Opportunity Matching?
                </label>
                <input
                  type="checkbox"
                  id="grant-matching-checkbox"
                  className="grant-matching-checkbox"
                  checked={hasGrantMatching}
                  onChange={(e) => setHasGrantMatching(e.target.checked)}
                />
                {hasGrantMatching && (
                  <span className="grant-matching-price">
                    {numGrantTokens === 0 ? '(+ $299.99)' : '(+ $149.99)'}
                  </span>
                )}
              </div>
            </div>
          );
        

        case 4:
          // Step 4: Summary + Billing Option
        
          // Calculate partial costs (monthly)
          const dbCostMonthly = getDBPrice(numDBs); 
          const tokensCostMonthly = getGrantTokenPrice(numGrantTokens);
          const matchingCostMonthly = getGrantMatchingPrice(hasGrantMatching, numGrantTokens);
        
          // Calculate partial costs (annual = monthly * 6 in your 50% discount model)
          const dbCostAnnual = dbCostMonthly * 6;
          const tokensCostAnnual = tokensCostMonthly * 6;
          const matchingCostAnnual = matchingCostMonthly * 6;
        
          // Full monthly vs. annual totals
          const monthlyTotal = dbCostMonthly + tokensCostMonthly + matchingCostMonthly;
          const annualTotal = monthlyTotal * 6;
        
          // Step 4: Summary + Billing Option

          // ...some lines omitted for brevity...

          return (
            <div className="wizard-step">
              {/* Billing Cycle Toggle */}
              <div className="pricing-period-tabs">
                <button
                  className={`billing-cycle-option ${
                    pricingPeriod === 'monthly' ? 'selected' : ''
                  }`}
                  onClick={() => setPricingPeriod('monthly')}
                >
                  Monthly
                </button>
                <button
                  className={`billing-cycle-option ${
                    pricingPeriod === 'annual' ? 'selected' : ''
                  }`}
                  onClick={() => setPricingPeriod('annual')}
                >
                  Annual
                </button>
              </div>
                
              <h3 className="summary-h3">Summary</h3>
                
              {/* DB Tier */}
              <p className="summary-row">
                <span className="summary-label">Core DB Tier</span>
                <span className="summary-value">
                  {numDBs}{' '}
                  {pricingPeriod === 'monthly'
                    ? `($${formatPrice(dbCostMonthly)}/mo)`    // <--- formatPrice
                    : `($${formatPrice(dbCostAnnual)}/yr)`}
                </span>
              </p>
                  
              {/* Grant Tokens */}
              <p className="summary-row">
                <span className="summary-label">Proposal Tokens</span>
                <span className="summary-value">
                  {numGrantTokens}{' '}
                  {pricingPeriod === 'monthly'
                    ? `($${formatPrice(tokensCostMonthly)}/mo)`
                    : `($${formatPrice(tokensCostAnnual)}/yr)`}
                </span>
              </p>
                  
              {/* Grant Matching */}
              <p className="summary-row">
                <span className="summary-label">Opportunity Matching</span>
                <span className="summary-value">
                  {hasGrantMatching ? 'Yes' : 'No'}
                  {hasGrantMatching && (
                    <>
                      {' '}
                      {pricingPeriod === 'monthly'
                        ? `($${formatPrice(matchingCostMonthly)}/mo)`
                        : `($${formatPrice(matchingCostAnnual)}/yr)`}
                    </>
                  )}
                </span>
              </p>
                
              {/* Final Total */}
              {pricingPeriod === 'monthly' ? (
                <p className="summary-row final-total-row">
                  <span className="summary-label">Monthly Total</span>
                  <span className="summary-value">
                    <strong>${formatPrice(monthlyTotal)}/mo</strong>
                  </span>
                </p>
              ) : (
                <p className="summary-row final-total-row">
                  <span className="summary-label">Annual Total – Save 50%</span>
                  <span className="summary-value">
                    <strong>${formatPrice(annualTotal)}/yr</strong>
                  </span>
                </p>
              )}

              {/* Checkout Form */}
              <form action={`${BASE_URL}/create-checkout-session`} method="POST">
                <input type="hidden" name="dbs" value={numDBs} />
                <input type="hidden" name="grantTokens" value={numGrantTokens} />
                <input type="hidden" name="grantMatching" value={hasGrantMatching} />
                <input type="hidden" name="billingCycle" value={pricingPeriod} />
                <button className="checkout-button" type="submit">
                  Proceed to Checkout
                </button>
              </form>
            </div>
          );

          
        

      default:
        return null;
    }
  };

  return (
    <div className="referral-landing-page">
      <div className="animated-border">
        <div className="pricing-section" id="Pricing">
          <div className="pricing-section-text-div">
            <h2 className="pricing-section-title">
              Enhance Your Proposal Strategy
            </h2>
            <span className="pricing-section-span">
              Customize your plan in 4 simple steps
            </span>
          </div>

          {/* Render the content for the current step */}
          {renderStepContent()}

          {/* Render step navigation buttons */}
          <div className="wizard-nav">
            {step > 1 && step <= 4 && (
              <button className="back-button" onClick={goBack}>
                Back
              </button>
            )}
            {step < 4 && (
              <button className="next-button" onClick={goNext}>
                Next
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingModelWizard;
