import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './DashboardSBIR.css';
import Keycloak from 'keycloak-js';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Joyride from 'react-joyride';

import Modal from 'react-modal';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus, faEdit } from '@fortawesome/free-solid-svg-icons';


import EditorSBIR from './EditorSBIR';
import Sidebar from './Sidebar';
import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import TreeViewPlugin from "./plugins/TreeViewPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import ConvertFirstDraftFromMarkdown from "./plugins/ConvertFirstDraftFromMarkdown";
import UpdateTextContent from "./plugins/UpdateTextContent";
import GenerateFullOutline from "./plugins/GenerateFullOutline";
import GenerateFirstDraftSBIR from "./plugins/GenerateFirstDraftSBIR";
import GenerateFirstDraftWithLexicalSBIR from "./plugins/GenerateFirstDraftWithLexicalSBIR";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { $createTextNode, $createParagraphNode, $getRoot } from 'lexical';
import { $createHeadingNode, $createQuoteNode } from '@lexical/rich-text';
import {$createListItemNode, $createListNode} from '@lexical/list';
import {$createLinkNode} from '@lexical/link';
import { useLexicalComposerContext } from "@lexical/react/LexicalComposer";
import {OnChangePlugin} from '@lexical/react/LexicalOnChangePlugin';

import FloatingToolbar from "./plugins/FloatingToolbar";
import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevelPlugin";
import CodeHighlightPlugin from "./plugins/CodeHighlightPlugin";
import AutoLinkPlugin from "./plugins/AutoLinkPlugin";
import StepIndicator from './StepIndicator';
import HowToUseGovExAISBIR from './HowToUseGovExAISBIR';
import ReferralPage from './ReferralPage';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';


import AISidebar from "./plugins/AISidebar"

import { jwtDecode } from 'jwt-decode';
import ReactMarkdown from 'react-markdown';

import GenerateComplianceMatrix from "./plugins/GenerateComplianceMatrix";
import GenerateProposalStructure from "./plugins/GenerateProposalStructure";
import ScanSBIR from "./plugins/ScanSBIR";
import GenerateComplianceMatrixSBIR from "./plugins/GenerateComplianceMatrixSBIR";
import GenerateProposalStructureSBIR from "./plugins/GenerateProposalStructureSBIR";
import GenerateNEWProposalStructureSBIR from "./plugins/GenerateNEWProposalStructureSBIR";
import OutlineAIPrompts from './plugins/OutlineAIPrompts';
import ConfirmRemoveVolumeModal from './plugins/ConfirmRemoveVolumeModal';
import AdminDashboard from './AdminDashboard';
import { Document, Packer, Paragraph, HeadingLevel } from 'docx'; // Impo

import remarkGfm from 'remark-gfm'

import * as XLSX from 'xlsx';

// NODE_ENV=test in .env
const ENV = process.env.NODE_ENV; // 'test' or 'live'

// Select the URL based on the environment
const BASE_URL = window.location.hostname === 'localhost' ? process.env.REACT_APP_TEST_URL : process.env.REACT_APP_LIVE_URL;


//////console.log(`Current NODE_ENV: ${process.env.NODE_ENV}`);
//////console.log(`REACT_APP_TEST_URL: ${process.env.REACT_APP_TEST_URL}`);
//////console.log(`REACT_APP_LIVE_URL: ${process.env.REACT_APP_LIVE_URL}`);
//////console.log(`Resolved BASE_URL: ${BASE_URL}`);




let initOptions = {
  url: 'https://keycloak.govexai.com/auth',
  realm: 'GovEx',
  clientId: 'GovExClient',
  onLoad: 'login-required',
  KeycloakResponseType: 'code',
}

let kc = new Keycloak(initOptions);

function MyCustomAutoFocusPlugin({ onTriggerFocus }) {
  //////console.log("hit");


  return null;
}

function DashboardSBIR() {
  const [tourActive, setTourActive] = useState(false);
  const [stepIndex, setStepIndex] = useState(0); // Track the current step index

  const editorRef = useRef();


  const [selectedTab, setSelectedTab] = useState('nameProposal'); // manageDatabase | generateProposal | aiProposals
  const [selectedFile, setSelectedFile] = useState(null);
  const [excelFileSelected, setExcelFileSelected] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [downloadLink, setDownloadLink] = useState('');
  const [selectedPreviousFile, setSelectedPreviousFile] = useState(null);
  const [isAddingProduct, setIsAddingProduct] = useState(true);
  const [productName, setProductName] = useState('');
  const [proposalProcessName, setProposalProcessName] = useState('');
  const [proposalProcessNameInText, setProposalProcessNameInText] = useState('');
  const [productFiles, setProductFiles] = useState([]);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [isUploadingProductToDB, setIsUploadingProductToDB] = useState(false);
  const [products, setProducts] = useState([]);
  const [proposalProcesses, setProposalProcesses] = useState([]);

  const [selectedSources, setSelectedSources] = useState([]);



  const [selectedReferenceFilesAI, setSelectedReferenceFilesAI] = useState([]);  // Selected reference files for AI
  const [availableReferenceFilesAI, setAvailableReferenceFilesAI] = useState([]);  // Available reference files fetched from backend
  const [isAiProfileModalOpen, setIsAiProfileModalOpen] = useState(false);  // Modal visibility
  const [isGeneratingProfile, setIsGeneratingProfile] = useState(false);


  
  // State for the uploaded XML file
  const [selectedGrantsXMLFile, setSelectedGrantsXMLFile] = useState(null);
  const [isGrantsUploadLoading, setIsGrantsUploadLoading] = useState(false);


  const [profileName, setProfileName] = useState('');
  const [companyDescription, setCompanyDescription] = useState('');
  const [naicsCodes, setNaicsCodes] = useState('');
  const [pscCodes, setPscCodes] = useState('');
  const [capabilities, setCapabilities] = useState('');
  const [pastPerformance, setPastPerformance] = useState('');
  const [targetKeywords, setTargetKeywords] = useState('');
  const [location, setLocation] = useState('');
  const [fundingNeeds, setFundingNeeds] = useState('');


  const [showModalIndex, setShowModalIndex] = useState(null);

  const [companyUrl, setCompanyUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [keycloakToken, setKeyCloakToken] = useState('');

  const [isAICollapsed, setIsAICollapsed] = useState(true);

  const [outlineContent, setOutlineContent] = useState('');
  const [showEditor, setShowEditor] = useState(false);
  const [editedOutlineHeaders, setEditedOutlineHeaders] = useState('');
  const [outlineHeadersGenerated, setOutlineHeadersGenerated] = useState(false);
  const [isEditorVisible, setIsEditorVisible] = useState(false);


  const [outlineFirstDraftMarkdown, setOutlineFirstDraftMarkdown] = useState('');
  const [outlineFirstDraftConverted, setFirstDraftConverted] = useState('');
  const [firstDraftGenerated, setFirstDraftGenerated] = useState(false);
  const [firstDraftStreaming, setFirstDraftStreaming] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [isAISidebarOpen, setIsAISidebarOpen] = useState(true);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(true);

  const [selectedRefFiles, setSelectedRefFiles] = useState({});
  const [showCheckList, setShowCheckList] = useState(false);

  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const [showReferenceForm, setShowReferenceForm] = useState(false);
  const [referenceFileTitle, setReferenceFileTitle] = useState('');
  const [referenceFileDescription, setReferenceFileDescription] = useState('');
  const [companyOverview, setCompanyOverview] = useState('');
  const [companyBackground, setCompanyBackground] = useState('');
  const [productDetails, setProductDetails] = useState('');
  const [uniqueProduct, setUniqueProduct] = useState('');
  const [certifications, setCertifications] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');

  const [activeVolumeIndex, setActiveVolumeIndex] = useState(0);
  const [activeVolumeTag, setActiveVolumeTag] = useState('');

  // Add these hooks and functions at the top of your component
  const [expandedSectionsDescription, setExpandedSectionsDescription] = useState([]);

  const [isProcessingProfile, setIsProcessingProfile] = useState(false); // NEW: Lock flag


  const handleToggleSectionDescription = (index) => {
    ////console.log("Toggling section for index:", index);
    setExpandedSectionsDescription((prev) => {
      const newSections = { ...prev };
      newSections[index] = !newSections[index];
      ////console.log("Updated expandedSectionsDescription:", newSections);
      return newSections;
    });
  };
  

  const [fileListVisibility, setFileListVisibility] = useState({});
  const [expandedSections, setExpandedSections] = useState({});
  const contentRefs = useRef({}); // Store refs for each section
  const scrollContainerRefOpportunities = useRef(null); // Reference for the scrollable div

  const handleSaveEditor = () => {
    ////console.log("Saving editor");
    editorRef.current.save();
  }

  const toggleSection1 = (key) => {
    setExpandedSections((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  
  const renderSectionContent = (content) => {
    const lines = content.split("\n").filter((line) => line.trim() !== "");
    const header = lines[0]; // Main header
    const subheaders = lines.slice(1); // Subheaders

    return { header, subheaders };
  };
  useEffect(() => {
    Object.keys(contentRefs.current).forEach((key) => {
      const contentElement = contentRefs.current[key]?.current;
      if (contentElement) {
        contentElement.style.height = expandedSections[key]
          ? `${contentElement.scrollHeight}px`
          : "0px";
      }
    });
  }, [expandedSections]);


  //useEffect(() => {
  //  //console.log("selectedProfile111: " + selectedProfile);
  //  if (selectedProfile !== '' && profiles.length > 0 && selectedTab === 'companyProfile') {
  //    //console.log("profiles[0]: " + profiles);
  //    handleProfileSelection(profiles[0].suffix);
  //    //console.log("selectedProfile222: " + selectedProfile);
  //  }
  //  else if (selectedTab === 'companyProfile') {
  //    handleProfileSelection(selectedProfile);
  //    //console.log("selectedProfile333: " + selectedProfile);
  //  }
  //}, [selectedTab]);

  

  const handleProfileSelection = (suffix) => {
    ////console.log("Profile selection triggered for suffix:", suffix);
  
    if (suffix === "new") {
      console.log("Creating a new profile...");
      // Clear the form for a new profile
      setSelectedProfile("new");
      setProfileSuffix("new");
      setProfileName("");
      setCompanyDescription("");
      setNaicsCodes("");
      setPscCodes("");
      setCapabilities("");
      setPastPerformance("");
      setTargetKeywords("");
      setLocation("");
      setFundingNeeds("");
    } else {
      const profile = profiles.find((p) => p.suffix === suffix);
      if (profile) {
        ////console.log("Profile loaded:", profile);
        setProfileName(profile.profileName);
        setCompanyDescription(profile.companyDescription);
        setNaicsCodes(profile.naicsCodes);
        setPscCodes(profile.pscCodes);
        setCapabilities(profile.capabilities);
        setPastPerformance(profile.pastPerformance);
        setTargetKeywords(profile.targetKeywords);
        setLocation(profile.location);
        setFundingNeeds(profile.fundingNeeds);
        setSelectedProfile(profile);
        setProfileSuffix(suffix);
      } else {
        console.warn("Profile not found for suffix:", suffix);
      }
    }
  };
  const handleProfileSelectionOnLoad = (suffix, profilesList = profiles) => {
    console.log("Profile selection triggered for suffix:", suffix);

    if (!profilesList || profilesList.length === 0) {
        // 🛑 No profiles exist → Default to "new" profile
        console.log("No profiles found. Defaulting to new profile.");
        setProfileSuffix("new");
        setProfileName("");
        setCompanyDescription("");
        setNaicsCodes("");
        setPscCodes("");
        setCapabilities("");
        setPastPerformance("");
        setTargetKeywords("");
        setLocation("");
        setFundingNeeds("");
        setSelectedProfile(null);
        return;
    }

    if (suffix === "new") {
        // ✅ Explicitly creating a new profile → Set all fields to blank
        console.log("Creating a new profile...");
        setProfileSuffix("new");
        setProfileName("");
        setCompanyDescription("");
        setNaicsCodes("");
        setPscCodes("");
        setCapabilities("");
        setPastPerformance("");
        setTargetKeywords("");
        setLocation("");
        setFundingNeeds("");
        setSelectedProfile(null);
    } else {
        // 🔍 Find the profile with the **lowest number suffix**
        const lowestProfile = profilesList.reduce((min, current) =>
            Number(current.suffix) < Number(min.suffix) ? current : min
        );

        // 🎯 Use the provided suffix if valid, otherwise use the lowest suffix
        const selectedSuffix = profilesList.some(p => p.suffix === suffix) ? suffix : lowestProfile.suffix;
        const profile = profilesList.find(p => p.suffix === selectedSuffix);

        if (profile) {
            console.log("Profile loaded:", profile);
            setProfileSuffix(selectedSuffix);
            setProfileName(profile.profileName);
            setCompanyDescription(profile.companyDescription);
            setNaicsCodes(profile.naicsCodes);
            setPscCodes(profile.pscCodes);
            setCapabilities(profile.capabilities);
            setPastPerformance(profile.pastPerformance);
            setTargetKeywords(profile.targetKeywords);
            setLocation(profile.location);
            setFundingNeeds(profile.fundingNeeds);
            setSelectedProfile(profile);
        } else {
            console.warn("Profile not found for suffix:", suffix);
        }
    }
};

  
  
  



  

  const handleCompanyProfileSubmit = async (e) => {
    e.preventDefault();

    if (isProcessingProfile) {
        alert("Your profile is already being processed. Please wait for an email confirmation.");
        return;
    }

    setIsProcessingProfile(true); // Lock further submissions

    const companyProfile = {
        userID,
        profileSuffix: selectedProfile === "new" ? undefined : selectedProfile,
        profileName,
        companyDescription,
        naicsCodes,
        pscCodes,
        capabilities,
        pastPerformance,
        targetKeywords,
        location,
        fundingNeeds,
        userEmail
    };

    try {
        const response = await fetch(`${BASE_URL}/api/save-company-profile`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(companyProfile),
        });

        if (response.ok) {
            alert("✅ Your profile is being processed. You will receive an email when matching is complete.");
            fetchUserProfiles(); // Refresh the profile list
        } else {
            const errorData = await response.json();
            console.error("❌ Error saving profile:", errorData);
            setIsProcessingProfile(false); // Unlock if failed
        }
    } catch (error) {
        console.error("❌ Unexpected error:", error);
        setIsProcessingProfile(false); // Unlock if failed
    }
};

  const handleDeleteProfile = async (profileSuffix) => {
    if (!window.confirm("Are you sure you want to delete this profile?")) {
      return;
    }
    console.log("profileSuffix", profileSuffix)
  
    try {
      const response = await fetch(`${BASE_URL}/api/delete-profile`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: userID, // Pass the userId from the Keycloak token
          profileSuffix: profileSuffix.suffix, // The suffix of the profile to delete
        }),
      });
  
      if (response.ok) {
        const result = await response.json();
        //console.log(result.message);
        alert("Profile deleted successfully!");
        handleProfileSelection("new");
  
        // Refresh profiles
        await fetchUserProfiles();
      } else {
        const errorData = await response.json();
        console.error("Error deleting profile:", errorData);
        alert(`Error deleting profile: ${errorData.error || "Unknown error"}`);
      }
    } catch (error) {
      console.error("Unexpected error deleting profile:", error);
      alert("Unexpected error occurred while deleting the profile.");
    }
  };
  
  
  
  
  
  
  const fetchUserProfiles = async () => {
    try {
      const response = await fetch(`${BASE_URL}/api/get-user-profiles?userID=${userID}`);
      if (response.ok) {
        const profiles = await response.json();
        //console.log("Fetched user profiles:", profiles);
        setProfiles(profiles); // Set profiles in state
      } else {
        const error = await response.json();
        console.error("Error fetching profiles:", error);
      }
    } catch (err) {
      console.error("Unexpected error fetching profiles:", err);
    }
  };

  
  

  const tourSteps = [
    {
      target: '#step1',
      title: 'Welcome to GovEx AI!',
      placement: 'top',
      content: (
        <span>
          This is the main dashboard. Here is where you create a new workflow.{' '}
          <br></br>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Give it a name and date to get started.
          </span>
        </span>
      ),
      
      disableBeacon: true,
    },
    {
      target: '#step2',
      title: 'Track Your Progress',
      content: 'This is an 8-step process. Here, you can track your progress as you move through the steps.',
      disableBeacon: true,
    },
    {
      target: '#step3',
      title: 'Create New Workflow',
      content: 'Click this button to create a new workflow.',
      disableBeacon: true,
      placement: 'left',
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step4',
      title: 'View Current Workflow',
      content: 'Here is where you can see what workflow you are currently working on.',
      disableBeacon: true,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step5',
      title: 'Upload Reference Material',
      content: (
        <span>
          Here is where you can upload your reference material.{' '}
          <br></br>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Don't upload the solicitation here! We will be uploading that later.
          </span>
        </span>
      ),
      disableBeacon: true,
      placement: 'bottom',
      spotlightClicks: false,
    },
    {
      target: '#step6',
      title: 'Categorize Reference Material',
      content: (
        <span>
          If your reference material has a category name such as whitepapers or resumes, it will be organized here. 
          Give it a category name, upload a file(s), and click the upload button.{' '}
          <br></br>
          <span style={{ color: 'red', fontWeight: 'bold' }}>
            Don't upload the solicitation here! We will be uploading that later.
          </span>
        </span>
      ),
      disableBeacon: true,
      placement: 'bottom',
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step7',
      title: 'Manage Reference Material',
      content: 'Here is where your reference material is stored. You can delete any files from here if needed.',
      disableBeacon: true,
      spotlightClicks: false,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step8',
      title: 'Continue to the Next Step',
      content: 'Now that we have our reference material uploaded, let’s continue to the next step.',
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step9',
      title: 'Upload the Solicitation',
      content: 'Here is where you upload the solicitation itself.',
      placement: 'top',
      disableBeacon: true,
      disableNext: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step10',
      title: 'Select Reference Files',
      content: 'Here is where you select which reference files you want the AI to use for this workflow.',
      disableBeacon: true,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      }
    },
    {
      target: '#step11',
      title: 'Choose Uploaded Files',
      content: 'Select one of your uploaded reference files from this section.',
      disableBeacon: true,
    },
    {
      target: '#step12',
      title: 'Analyze the Solicitation',
      content: 'Click this button to analyze the solicitation. Be patient after clicking, this may take a few minutes.',
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step13',
      title: 'Solicitation Overview',
      content: 'Now that our AI has finished analyzing, here is an overview of your solicitation.',
      disableBeacon: true,
      spotlightClicks: false,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step14',
      title: 'Key Details Extracted',
      content: 'Here you can find an overview with key details extracted.',
      disableBeacon: true,
    },
    {
      target: '#step15',
      title: 'Proposal Stage Selection',
      content: 'Here you can choose which stage of the proposal you want to work on. We will come back to this step shortly.',
      disableBeacon: true,
      spotlightClicks: false,
    },
    {
      target: '#step16',
      title: 'Select Topics/Subtopics',
      content: (
        <span>
          Here are the extracted topics/subtopics.{" "}
          <br></br>
          <span style={{ color: "#ff5733", fontWeight: "bold" }}>
            Be sure to select one or more here.
          </span>
        </span>
      ),
      disableBeacon: true,
    },
    {
      target: '#step17',
      title: 'Respond to Open Topics',
      content: 'If you want to respond to an open topic, click add new topic here. For now, let\'s continue with an extracted topic.',
      disableBeacon: true,
      spotlightClicks: false,
    },
    {
      target: '#step18',
      title: 'Full Application Selection',
      content: 'Now that you have selected a topic, click the drop down arrow here to view what the full application entails and to move to the next step.',
      disableBeacon: true,
      placement: 'top',
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#FAstep19',
      title: 'Extract Outlines/Table of Contents',
      content: 'Click generate proposal structure to continue on to the next step.',
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step20',
      title: 'Add Keywords',
      content: (
        <div>
          Now follow the directions and optionally add a <strong>keyword</strong> for our AI to use.
          <br />
          If no keyword is provided, our AI will look at the solicitation and generate <strong>volume outlines</strong> for you to work with.
          <br />
          Click <strong>Generate Proposal Structure</strong> to continue on to the next step.
        </div>
      ),
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step21',
      title: 'Watch GovEx AI Magic',
      content: (
        <div>
          Watch our AI work its magic to extract relevant outlines, or build one if no keyword is provided.
          <br />
          Once complete, you will be moved to the next step automatically.
        </div>
      ),
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step22',
      title: 'Review Extracted Outlines',
      content: 'Here are your extracted outlines to work with.',
      disableBeacon: true,
      spotlightClicks: false,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step23',
      title: 'Edit the Outline',
      content: 'Click edit outline at the bottom of the outline to continue on to the next step.',
      disableBeacon: true,
      styles: {
        buttonNext: {
          pointerEvents: "none",
          opacity: 0.5,
          backgroundColor: "grey",
        },
      },
    },
    {
      target: '#step24',
      title: 'Outline Editor',
      content: 'Here you can edit your outline. This step is important, as our AI will follow this to build your proposal. The more sections you have, the longer the proposal will be.',
      disableBeacon: true,
      styles: {
        buttonBack: {
          pointerEvents: "none", // Disable clicks for the Back button
          opacity: 0.5, // Make it visually indicate a disabled state
          backgroundColor: "lightgrey", // Use a slightly lighter grey to differentiate it
          color: "darkgrey", // Optional: Muted text color for the Back button
        },
      },
    },
    {
      target: '#step25',
      title: 'Save Outline',
      content: 'Save your outline and click next to continue.',
      disableBeacon: true,
    },
    {
      target: '#step26',
      title: 'Optional: Add AI Instructions',
      content: (
        <div>
          Here you can provide additional instructions for our AI to follow when building your proposal.
          <br />
          You can instruct the AI to reference <strong>specific content</strong> from particular files in your reference material, 
          or you can guide the AI to <strong>talk about specific subjects</strong> for each section.
          <br />
          Pretty cool, right? 😎
        </div>
      ),disableBeacon: true,
    },
    {
      target: '#step27',
      title: '🎉 Congratulations!',
      content: (
        <div>
          You have successfully completed the <strong>GovEx AI tutorial</strong> for creating a first draft.
          <br />
          Click the button to continue and watch your proposal being generated. 🚀
          <br />
          If you'd like to revisit this tutorial at any time, simply click <strong>"Guided Tour"</strong> on the left-hand sidebar.
        </div>
      ),
      disableBeacon: true,
    }    
  ];

  const formatDate = (dateString) => {
    if (!dateString) return "N/A"; // Handle missing dates
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);
    return `${month}-${day}-${year}`; // Format as mm-dd-yyyy
  };
  const formatCurrency = (amount) => {
    if (!amount || isNaN(amount)) return "N/A"; // Handle invalid or missing values
    return `$${parseInt(amount).toLocaleString()}`; // Format number with commas and add $
  };
  
  
  
    
  

  const handleStartTour = () => {
    setTourActive(false); // Ensure a clean state
    setTimeout(() => setTourActive(true), 500); // Start the tour
  };

  const handleTourClose = () => {
    setTourActive(false); // Stop the tour
    setStepIndex(0); // Reset the step index
  };
  const handleMoveToStep4 = () => {
    setTimeout(() => {
      setStepIndex(3); // Move directly to step 4 (index 4)
    }, 2000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep6 = () => {
    setTimeout(() => {
      setStepIndex(5); // Move directly to step 6 (index 5)
    }, 2000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep7 = () => {
    setTimeout(() => {
      setStepIndex(6); // Move directly to step 7 (index 6)
    }, 2000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep9 = () => {
    setTimeout(() => {
      setStepIndex(8); // Move directly to step 9 (index 8)
    }, 500); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep10 = () => {
    setTimeout(() => {
      setStepIndex(9); // Move directly to step 9 (index 8)
    }, 500); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep12 = () => {
    setTimeout(() => {
      //console.log("handleMoveToStep12");
      setStepIndex(11); // Move directly to step 9 (index 8)
    }, 500); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep13 = () => {
    
    setTimeout(() => {
      //console.log("handleMoveToStep13");
      setStepIndex(12); // Move directly to step 9 (index 8)
    }, 3000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep14 = () => {
    setTimeout(() => {
      setStepIndex(13); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep15 = () => {
    setTimeout(() => {
      setStepIndex(14); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep16 = () => {
    setTimeout(() => {
      setStepIndex(15); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep17 = () => {
    setTimeout(() => {
      setStepIndex(16); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep18 = () => {
    //console.log("handleMoveToStep18");
    setTimeout(() => {
      setStepIndex(17); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep19 = () => {
    //console.log("handleMoveToStep19 triggered");
    requestAnimationFrame(() => {
        setStepIndex(18);
    });
};

  const handleMoveToStep20 = () => {
    setTimeout(() => {
      //console.log("handleMoveToStep20");
      setStepIndex(19); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep21 = () => {
    
    setTimeout(() => {
      //console.log("handleMoveToStep21");
      setStepIndex(20); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep22 = () => {
    setTimeout(() => {
      //console.log("handleMoveToStep22");
      setStepIndex(21); // Move directly to step 9 (index 8)
    }, 4000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep23 = () => {
    setTimeout(() => {
      setStepIndex(22); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep24 = () => {
    setTimeout(() => {
      //console.log("handleMoveToStep24");
      setStepIndex(23); // Move directly to step 9 (index 8)
    }, 2000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep25 = () => {
    setTimeout(() => {
      setStepIndex(24); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep26 = () => {
    setTimeout(() => {
      setStepIndex(25); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };
  const handleMoveToStep27 = () => {
    setTimeout(() => {
      setStepIndex(26); // Move directly to step 9 (index 8)
    }, 1000); // Delay of 3000 milliseconds (3 seconds)
  };

  

  



  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };


  Modal.setAppElement('#root');


  const [selectedText, setSelectedText] = useState('');
  const steps = [
    { key: 'nameProposal', label: 'Select Workflow', completed: false },
    { key: 'manageDatabase', label: 'Upload Company Material', completed: false },
    { key: 'generateProposal', label: 'Upload Solicitation', completed: false },
    { key: 'viewAnalyzedSBIR', label: 'View Analyzed Solicitation', completed: false },
    { key: 'viewProposalStructure', label: 'Proposal Structure', completed: false },
    // { key: 'viewGenerationOutline', label: 'View Outline Being Generated', completed: false },
    { key: 'viewOutline', label: 'View & Edit Outline', completed: false },
    { key: 'viewGenerationFirstDraft', label: 'View First Draft Being Generated', completed: false },
    { key: 'viewFirstDraft', label: 'Review Draft', completed: false },
  ];


  const [draftVolumes, setDraftVolumes] = useState({});
  const [tagForDraft, setTagForDraft] = useState('');
  const [volumeOutlines, setVolumeOutlines] = useState({});

  const [isScanningModal, setIsScanningModal] = useState(false);
  const [isGeneratingStructure, setIsGeneratingStructure] = useState(false);

  const proposalStructureMessages = [
    "Setting up data connections...",
    "Connection successful!",
    "Initializing proposal structure generation...",
    "Analyzing solicitation requirements...",
    "Setting up document framework...",
    "Reviewing key sections...",
    "Analyzing provided keywords...",
    "Trying to match keywords to provided outlines...",
    "Structuring document outline...",
    "Preparing topic and subtopic areas selected...",
    "Finalizing initial draft layout...",
    "Almost done! Preparing final structure...",
    "Completing proposal structure..."
];

  const analysisProgressMessages = [
    "Setting up data connections...",
    "Connection successful!",
    "Scanning solicitation text...",
    "Extracting key details...",
    "Examining key details...",
    "Organizing compliance requirements...",
    "Analyzing compliance metrics...",
    "Extracting Topical Areas...",
    "Extracting Subtopic Areas...",
    "Determining if two stage or one stage...",
    "Finalizing results...",
    "Almost there! Preparing your analysis...",
    "Reviewing final data..."
  ];

  const [currentAnalysisStep, setCurrentAnalysisStep] = useState(0);
  const totalAnalysisSteps = analysisProgressMessages.length;

  const [currentStructureStep, setCurrentStructureStep] = useState(0);
  const totalStructureSteps = proposalStructureMessages.length;

  useEffect(() => {
      let timeoutId;
      let alertTimeoutId;
      let isModalActive = true; // Tracks whether the modal is still active

      if (isScanningModal) {
          setCurrentAnalysisStep(0); // Reset on open

          const updateMessage = () => {
              setCurrentAnalysisStep(prevStep => {
                  if (prevStep < totalAnalysisSteps - 1) {
                      return prevStep + 1;
                  }
                  return prevStep; // Keep the last step
              });

              // Calculate a random delay between 4 and 8 seconds for step updates
              const randomDelay = Math.floor(Math.random() * (10000 - 4000 + 1)) + 4000;

              // Only set another timeout for step progression if not at the last step
              if (currentAnalysisStep < totalAnalysisSteps - 1 && isModalActive) {
                  timeoutId = setTimeout(updateMessage, randomDelay);
              }
          };

          // Start the initial timeout for step updates
          timeoutId = setTimeout(updateMessage, 4000);

          // Function to handle repeating alert
          const handleAlert = () => {
              if (isModalActive) {
                  alert("Processing is still ongoing; please be patient as we finalize the analysis.");
                  // Clear the previous alert timeout (if any) before setting a new one
                  clearTimeout(alertTimeoutId);
                  // Set a new timeout for the alert after 45 seconds
                  alertTimeoutId = setTimeout(handleAlert, 45000);
              }
          };

          // Start the alert timeout at 45 seconds
          alertTimeoutId = setTimeout(handleAlert, 45000);
      }

      return () => {
          // Cleanup function
          isModalActive = false; // Prevent further actions
          clearTimeout(timeoutId); // Clear step progression timeout
          clearTimeout(alertTimeoutId); // Clear alert timeout
      };
  }, [isScanningModal]);




  
  const analysisProgressPercentage = ((currentAnalysisStep + 1) / totalAnalysisSteps) * 100;

  useEffect(() => {
      if (isGeneratingStructure) {
          setCurrentStructureStep(0); // Reset on open

          const updateStructureMessage = () => {
              //console.log("current structure step: ", currentStructureStep);
              setCurrentStructureStep(prevStep => {
                  if (prevStep < totalStructureSteps - 1) {
                      return prevStep + 1;
                  } else {
                      return prevStep; // Keep the last message displayed
                  }
              });

              // Calculate a random delay between 4 and 8 seconds
              const randomDelay = Math.floor(Math.random() * (8000 - 4000 + 1)) + 4000;

              // Only set another timeout if we're not at the last message
              if (currentStructureStep < totalStructureSteps - 1) {
                  timeoutId = setTimeout(updateStructureMessage, randomDelay);
              }
          };

          // Start the initial timeout
          let timeoutId = setTimeout(updateStructureMessage, 4000);

          return () => clearTimeout(timeoutId); // Clear timeout on unmount or modal close
      }
  }, [isGeneratingStructure]);

  const structureProgressPercentage = ((currentStructureStep + 1) / totalStructureSteps) * 100;


    // New state for first draft generation popup
  const [isGeneratingFirstDraftPopup, setIsGeneratingFirstDraftPopup] = useState(false);

  // Messages for first draft generation progress
  const firstDraftProgressMessages = [
    "Establishing connection...",
    "Connection successful!",
    "Extracting additional information around selected key topics/subtopics...",
    "Gathering selected reference material...",
    "Analyzing solicitation...",
    "Examining additional AI section prompts...",
    "Almost done! Preparing to write draft..."
];


  // State for tracking first draft progress
  const [currentFirstDraftStep, setCurrentFirstDraftStep] = useState(0);
  const totalFirstDraftSteps = firstDraftProgressMessages.length;

  // New effect for tracking first draft generation progress
  useEffect(() => {
      if (isGeneratingFirstDraftPopup) {
          setCurrentFirstDraftStep(0); // Reset on open

          const updateFirstDraftMessage = () => {
              setCurrentFirstDraftStep(prevStep => {
                  if (prevStep < totalFirstDraftSteps - 1) {
                      return prevStep + 1;
                  } else {
                      return prevStep; // Keep the last message displayed
                  }
              });

              // Calculate a random delay between 4 and 8 seconds
              const randomDelay = Math.floor(Math.random() * (4500 - 2000 + 1)) + 2000;

              // Only set another timeout if we're not at the last message
              if (currentFirstDraftStep < totalFirstDraftSteps - 1) {
                  timeoutId = setTimeout(updateFirstDraftMessage, randomDelay);
              }
          };

          // Start the initial timeout
          let timeoutId = setTimeout(updateFirstDraftMessage, 4000);

          return () => clearTimeout(timeoutId); // Clear timeout on unmount or modal close
      }
  }, [isGeneratingFirstDraftPopup]);

  // Calculate progress percentage for first draft
  const firstDraftProgressPercentage = ((currentFirstDraftStep + 1) / totalFirstDraftSteps) * 100;






  const [groupId, setGroupId] = useState(null);

  const [samOpportunities, setSamOpportunities] = useState([]);
  const [pipelineOpportunities, setPipelineOpportunities] = useState([]);
  const sampleData = {
      "totalRecords": 1104,
      "limit": 3,
      "offset": 0,
      "opportunitiesData": [
          {
              "noticeId": "fff3017484454f7eafd01e91d20d30db",
              "title": "ACTUATOR,ELECTRO-ME",
              "solicitationNumber": "N0010424QLE23",
              "fullParentPathName": "DEPT OF DEFENSE.DEPT OF THE NAVY.NAVSUP.NAVSUP WEAPON SYSTEMS SUPPORT.NAVSUP WSS MECHANICSBURG.NAVSUP WEAPON SYSTEMS SUPPORT MECH",
              "postedDate": "2024-07-11",
              "type": "Solicitation",
              "archiveType": "auto15",
              "archiveDate": "2024-08-27",
              "responseDeadLine": "2024-08-12T16:30:00-04:00",
              "naicsCode": "332919",
              "classificationCode": "4810",
              "active": "Yes",
              "pointOfContact": [
                  {
                      "fax": null,
                      "type": "primary",
                      "email": "MARSHA.WILLIAMS@NAVY.MIL",
                      "phone": null,
                      "title": null,
                      "fullName": "Telephone: 7176052727"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=fff3017484454f7eafd01e91d20d30db",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "17050-0788",
                  "city": "MECHANICSBURG",
                  "countryCode": "USA",
                  "state": "PA"
              },
              "uiLink": "https://sam.gov/opp/fff3017484454f7eafd01e91d20d30db/view"
          },
          {
              "noticeId": "ffebd8530538472fa7fa5a1b397eb21b",
              "title": "Bison prairie seed spreading",
              "solicitationNumber": "12444524Q0076",
              "fullParentPathName": "AGRICULTURE, DEPARTMENT OF.FOREST SERVICE.USDA-FS, CSA EAST 6",
              "postedDate": "2024-07-11",
              "type": "Solicitation",
              "archiveType": "auto15",
              "archiveDate": "2024-08-19",
              "responseDeadLine": "2024-08-04T23:00:00-04:00",
              "naicsCode": "115112",
              "classificationCode": "F007",
              "active": "Yes",
              "pointOfContact": [
                  {
                      "fax": "",
                      "type": "primary",
                      "email": "Joshua.Franks@usda.gov",
                      "phone": "",
                      "title": null,
                      "fullName": "Joshua Franks"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ffebd8530538472fa7fa5a1b397eb21b",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "303092449",
                  "city": "Atlanta",
                  "countryCode": "USA",
                  "state": "GA"
              },
              "placeOfPerformance": {
                  "city": {
                      "code": "82088",
                      "name": "Wilmington"
                  },
                  "state": {
                      "code": "IL",
                      "name": "Illinois"
                  },
                  "zip": "60481",
                  "country": {
                      "code": "USA",
                      "name": "UNITED STATES"
                  }
              },
              "uiLink": "https://sam.gov/opp/ffebd8530538472fa7fa5a1b397eb21b/view"
          },
          {
              "noticeId": "ff76a84d2e084de5ad98ad4bfcae317a",
              "title": "S205--Medical Waste",
              "solicitationNumber": "36C24624Q0955",
              "fullParentPathName": "VETERANS AFFAIRS, DEPARTMENT OF.VETERANS AFFAIRS, DEPARTMENT OF.246-NETWORK CONTRACTING OFFICE 6 (36C246)",
              "postedDate": "2024-07-11",
              "type": "Award Notice",
              "archiveType": "autocustom",
              "archiveDate": "2024-08-10",
              "responseDeadLine": null,
              "naicsCode": "562111",
              "classificationCode": "S205",
              "active": "Yes",
              "award": {
                  "date": "2024-07-10",
                  "number": "36C24624D0062",
                  "amount": "997877.80",
                  "awardee": {
                      "name": "NEIE Medical Waste Services, LLC",
                      "location": {
                          "streetAddress": "",
                          "streetAddress2": "",
                          "city": {
                              "name": "BLAIRSVILLE"
                          },
                          "state": {
                              "name": "PA"
                          },
                          "zip": "15717",
                          "country": {
                              "name": "PA"
                          }
                      },
                      "ueiSAM": "XKA7FM3CLCX4",
                      "cageCode": "4WE82"
                  }
              },
              "pointOfContact": [
                  {
                      "fax": "",
                      "type": "primary",
                      "email": "byron.brown4@va.gov",
                      "phone": "757-315-2598",
                      "title": "Contracting Officer",
                      "fullName": "Byron L Brown"
                  }
              ],
              "description": "https://api.sam.gov/prod/opportunities/v1/noticedesc?noticeid=ff76a84d2e084de5ad98ad4bfcae317a",
              "organizationType": "OFFICE",
              "officeAddress": {
                  "zipcode": "23667",
                  "city": "HAMPTON",
                  "countryCode": "USA",
                  "state": "VA"
              },
              "uiLink": "https://sam.gov/opp/ff76a84d2e084de5ad98ad4bfcae317a/view"
          }
      ]
  };




  // Function to update state with new draft content
  const updateDraftVolume = (volumeTag, markdownContent) => {
      //console.log(`Updating draft volume ${volumeTag} with new content. Content length: ${markdownContent.length}`);

      // Log the state before the update for comparison
      //console.log('Current draftVolumes state before update:', draftVolumes);

      setDraftVolumes(prevVolumes => {
          // Log the previous state inside the update function
          //console.log('Previous state inside update function:', prevVolumes);

          // Calculate the new state but log it before actually setting it
          const updatedVolumes = {
              ...prevVolumes,
              [volumeTag]: markdownContent,
          };

          //console.log(`New draftVolumes state for volume ${volumeTag}:`, updatedVolumes);

          // Return the calculated new state
          return updatedVolumes;
      });

      // Note: Logging right after setDraftVolumes may not reflect the update immediately due to the asynchronous nature of state updates
  };

  const updateDraftVolumeOutline = (volumeIdentifier, markdownContent) => {
    //////console.log(`Updating draft volume outline ${volumeIdentifier} with new content. Content length: ${markdownContent.length}`);


      setVolumeOutlines(prevOutlines => {

          const updatedVolumes = {
            ...prevOutlines,
            [volumeIdentifier]: markdownContent
      };
      //////console.log(`New draftVolumesOutlines state for volume ${volumeIdentifier}:`, updatedVolumes);

      });

  };



  const [nextButtonActive, setNextButtonActive] = useState(false);
  const currentStep = steps.indexOf(selectedTab) + 1;

  const [streamedContent, setStreamedContent] = useState('');

  const [companyName, setCompanyName] = useState('');
  const [numProposals, setNumProposals] = useState(0);
  const [numProposalsRFx, setNumProposalsRFx] = useState(0);
  const [preferredName, setPreferredName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [pricingTier, setPricingTier] = useState('');
  const [userID, setUserID] = useState('');
  const [referralCode, setReferralCode] = useState('');
  const [numReferrals, setNumReferrals] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [profileSuffix, setProfileSuffix] = useState(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [isFetchingGrantOpps, setIsFetchingGrantOpps] = useState(false);
  const [allGrantsOpportunities, setAllGrantsOpportunities] = useState([]);

  const [selectedAgencies, setSelectedAgencies] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [userAlerts, setUserAlerts] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [newAgency, setNewAgency] = useState("");
  const [isAlertViewModalOpen, setIsAlertViewModalOpen] = useState(false);
  const [selectedAgenciesForAlert, setSelectedAgenciesForAlert] = useState([]);
  const sources = ["Grants.gov", "SAM.gov", "SBIR.gov"]; 
  const [selectedAgency, setSelectedAgency] = useState("");

  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null); // Reference to the dropdown container

  

  const [grantOpportunitiesCurrentPage, setGrantOpportunitiesCurrentPage] = useState(1); // Current page
  const [grantOpportunitiesPageSize, setGrantOpportunitiesPageSize] = useState(10); // Items per page


  const [aiOutlinePromptMessage, setAiOutlinePromptMessage] = useState([]);
  const [currentOutlineVolume, setCurrentOutlineVolume] = useState('');

  const [showAlert, setShowAlert] = useState(false); // State to control alert visibility

  const [complianceMatrixSummary, setComplianceMatrixSummary] = useState(""); // Example initial value

  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const [matchedOpportunities, setMatchedOpportunities] = useState([]);
  const [grantsOpportunities, setGrantsOpportunities] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);


  const [lastUpload, setLastUpload] = useState(0); // Initial value can be 0 or Date.now() or any other
  const [lastRemove, setLastRemove] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const handleShowError = () => {
    setShowErrorAlert(true);
    
    // Hide the error alert after 5 seconds
    setTimeout(() => {
        setShowErrorAlert(false);
    }, 5000);
  };


  const handleAIPromptSubmit = (prompts) => {
    //console.log("User's section prompts:", prompts);

    // Assuming you have a way to identify the current volume being edited

    setProposalVolumes(prevVolumes => {
        // Find the index of the volume to update
        const volumeIndex = prevVolumes.findIndex(volume => volume.tag === tagForDraft);

        if (volumeIndex === -1) {
            console.error("Volume not found for tag:");
            return prevVolumes;
        }

        // Create a new volume object with the updated AiOutlinePromptMessage
        const updatedVolume = {
            ...prevVolumes[volumeIndex],
            AiOutlinePromptMessage: prompts
        };

        // Create a new array with the updated volume
        const updatedVolumes = [...prevVolumes];
        updatedVolumes[volumeIndex] = updatedVolume;

        updateProposalStructureSaveAIPrompt(updatedVolumes, proposalStructureStage);

        return updatedVolumes;
    });

    


    //console.log("Updated proposalVolumes:", proposalVolumes);
};

const updateProposalStructureSaveAIPrompt = (finalizedProposalStructureArray, stage) => {
  //console.log(finalizedProposalStructureArray);
  setProposalVolumes(finalizedProposalStructureArray);
  //console.log("Current proposalVolumes:", proposalVolumes);
  setProposalStructureStage(stage);
  //console.log(stage);
  setProposalStructureGenerated(true);
  setNextButtonActive(true);
  
  const markdownStringProposalStructure = convertToMarkdownAIPrompt(finalizedProposalStructureArray);
  //console.log(markdownStringProposalStructure);
  //downloadAndSaveMarkdownAsDocxSBIR(markdownStringProposalStructure, stage);
};

const convertToMarkdownAIPrompt = (finalizedProposalStructureArray) => {
  let markdownString = "";

  finalizedProposalStructureArray.forEach(volume => {
    // Add volume title as a heading
    markdownString += `# ${volume.title}\n\n`;

    // Add each section within the volume
    volume.sections.forEach((section, index) => {
      // Add the section to the markdown
      markdownString += `${section}\n`;

      // Add the AI Prompt Message for this section if it exists
      if (volume.AiOutlinePromptMessage && volume.AiOutlinePromptMessage[index]) {
        const aiPrompt = volume.AiOutlinePromptMessage[index];
        markdownString += `\n**AI Prompt for Section ${index + 1}:** ${aiPrompt}\n\n`;
        //console.log("aiPrompt!!!!!!!!!!!!!!!!!!!!!!!: ", markdownString);
      }
    });

    // Add a newline for spacing between volumes
    markdownString += '\n';
  });

  return markdownString;
};
  
const toggleFileList = (productName) => {
  setFileListVisibility(prevState => ({
    ...prevState,
    [productName]: !prevState[productName] // Toggle the visibility for the specific product
  }));
};





  const handleNavigation = (type) => {
    if (type === 'SBIR') {
      navigate('/dashboard-grants');
    } else if (type === 'RFP') {
      navigate('/dashboard');
    }
  };

  const handleSelection = (selection) => {
      setIsModalOpen(false);
      if (selection === 'SBIR') {
        navigate('/dashboard-grants');
      } else if (selection === 'RFP') {
        navigate('/dashboard');
      }
    };

  const handleModalClose = () => {
      setIsModalOpen(false);
    };

  const [complianceMatrixStreamed, setComplianceMatrixStreamed] = useState('');
  const sbirMatrix = {
    "solicitation_details": {
      "description": "This solicitation seeks proposals for innovative research and development projects in the field of battery manufacturing. The goal is to enhance the manufacturing processes, materials, and technologies for next-generation batteries.",
      "point_of_contact": {
        "name": "Dr. John Doe",
        "email": "john.doe@example.com",
        "phone": "123-456-7890",
        "agency": "Department of Energy"
      },
      "due_dates": {
        "pre_application_deadline": "2024-08-15",
        "full_application_deadline": "2024-09-15",
        "is_two_stage": true
      },
      "funding": {
        "total_funding_available": "$10,000,000",
        "pre_application_award_amount": "$150,000",
        "full_application_award_amount": "$1,000,000"
      }
    },
    "requirements": {
      "pre_application": {
        "overview": "The pre-application stage is intended to screen initial concepts before the submission of full proposals.",
        "status": "in progress",  // New status property
        "detailed_requirements": [
          "Submit a brief summary of the proposed project.",
          "Include key objectives and potential impacts.",
          "Provide a preliminary budget estimate."
        ],
        "components": [
          {
            "step": "Step 1: Pre-Application Submission",
            "details": "All pre-application components must be submitted through eBRAP (https://eBRAP.org/). During the pre-application process, eBRAP assigns each submission a unique log number..."
          },
          {
            "step": "Pre-Application Components",
            "details": "Pre-application submissions must include the following components (refer to Appendix V, Pre-Application Submission, for additional information):",
            "subcomponents": [
              {
                "title": "Preproposal Narrative (three-page limit)",
                "details": "The Preproposal Narrative should include the following:",
                "requirements": [
                  {
                    "name": "Research Plan",
                    "description": "Concise statement of the scientific rationale, hypotheses, objectives, specific aims, and experimental approach."
                  },
                  {
                    "name": "Personnel",
                    "description": "Brief description of the qualifications of the PI and key personnel."
                  },
                  {
                    "name": "Impact and Relevance",
                    "description": "Explicit statement of how the proposed work will lead to translation of advances for improving medical readiness, mitigating fatalities, optimally treating life-threatening injuries, and promoting positive long-term outcomes for military health and medicine, as well as the general public."
                  },
                  {
                    "name": "Alignment with Focus Areas",
                    "description": "Identification and explanation of how the proposed work addresses at least one FY24 CRRP Focus Area."
                  }
                ]
              },
              {
                "title": "Pre-Application Supporting Documentation",
                "details": "The items to be included as supporting documentation for the pre-application must be uploaded as individual files and are limited to the following:",
                "requirements": [
                  {
                    "name": "References Cited (one-page limit)",
                    "description": "List the references cited in the Preproposal Narrative using a standard reference format."
                  },
                  {
                    "name": "List of Abbreviations, Acronyms, and Symbols",
                    "description": "Provide a list of abbreviations, acronyms, and symbols used in the Preproposal Narrative."
                  },
                  {
                    "name": "Key Personnel Biographical Sketches (five-page limit per individual)",
                    "description": "All biographical sketches should be uploaded as a single combined file to demonstrate background and expertise through education, positions, publications, and previous work accomplished."
                  }
                ]
              }
            ]
          },
          {
            "step": "Pre-Application Screening Criteria",
            "details": "To determine the technical merits of the pre-application and the relevance to the mission of the DHP and the CRRP, pre-applications will be screened based on the following criteria...",
            "requirements": [
              {
                "name": "Research Plan",
                "description": "How well the scientific rationale, hypotheses, objectives, specific aims, and experimental approach are described."
              },
              {
                "name": "Personnel",
                "description": "To what extent the qualifications and experience of the PI and key personnel are appropriate to perform the proposed research project."
              },
              {
                "name": "Impact and Relevance",
                "description": "How well the proposed work will lead to translation of advances for improving medical readiness, mitigating fatalities, optimally treating life-threatening injuries, and promoting positive long-term outcomes for military health and medicine, as well as the general public."
              },
              {
                "name": "Alignment with Focus Areas",
                "description": "To what extent the proposed work addresses at least one FY24 CRRP Focus Area."
              }
            ]
          },
          {
            "step": "Notification of Pre-Application Screening Results",
            "details": "Following the pre-application screening, PIs will be notified as to whether they are invited to submit full applications. The estimated date when PIs can expect to receive notification of an invitation to submit a full application is indicated in Section I, Overview of the Funding Opportunity..."
          }
        ]
      },
      "full_application": {
        "overview": "The full application stage focuses on establishing the technical merit and feasibility of the proposed research and development efforts.",
        "status": "Not started",  // New status property
        "detailed_requirements": [
          "Provide a detailed project description and technical approach.",
          "Identify potential commercial applications of the research.",
          "Demonstrate the qualifications of the research team."
        ]
      }
    },
    "topics": [
      {
        "topic_area": "Topic Area 1: Platforms for Next Generation Battery Manufacturing",
        "description": "Focus on platform technologies related to battery manufacturing, including R&D of innovative materials, processing, and manufacturing capability for emerging battery technologies.",
        "location": "Section I.B.i",
        "selected": false,
        "subtopics": [
          {
            "subtopic_area": "Subtopic 1.1: Processes and Machines for Sodium-Ion Batteries",
            "description": "Develop processing machines that enable innovative sodium-ion battery manufacturing processes, focusing on adaptability and cross-cutting capabilities.",
            "location": "Section I.B.i",
            "selected": false
          },
          {
            "subtopic_area": "Subtopic 1.2: Processes and Design for Manufacturability of Flow Batteries",
            "description": "Focus on the manufacturing processes and design considerations for flow batteries to enhance scalability and manufacturability.",
            "location": "Section I.B.i",
            "selected": false
          },
          {
            "subtopic_area": "Subtopic 1.3: Scalable Manufacturing of Nanolayered Films for Energy Storage",
            "description": "Advance manufacturing platforms for low-cost, high-performing nanolayered films for use in energy storage devices.",
            "location": "Section I.B.i",
            "selected": false
          }
        ]
      },
      {
        "topic_area": "Topic Area 2: Smart Manufacturing Platforms for Battery Production",
        "description": "Develop smart manufacturing technologies to enhance battery production processes, focusing on data-driven decision-making and process optimization.",
        "location": "Section I.B.ii",
        "selected": false,
        "subtopics": []
      }
    ]
  };




  const [matrix, setMatrix] = useState(sbirMatrix);
  const [proposalStructureStage, setProposalStructureStage] = useState(null);

  const [workflowDetails, setWorkflowDetails] = useState({}); 

  const [showDetails, setShowDetails] = useState({
      dueDates: false,
      funding: false,
      pointOfContact: false,
      preApplication: false,
      fullApplication: false
    });

  const [preApplicationProposalStructure, setPreApplicationProposalStructure] = useState(null);
  const [fullApplicationProposalStructure, setFullApplicationProposalStructure] = useState(null);


  const [complianceMatrixStreaming, setComplianceMatrixStreaming] = useState(false);
  const [complianceMatrix, setComplianceMatrix] = useState('');
  const [complianceMatrixCreated, setComplianceMatrixCreated] = useState(false);
  const [analysisSBIR, setAnalysisSBIR] = useState(false);


  const [proposalStructureStreamed, setProposalStructureStreamed] = useState('');
  const [proposalStructureStreaming, setProposalStructureStreaming] = useState(false);
  const [proposalStructure, setProposalStructure] = useState('');
  const [proposalVolumes, setProposalVolumes] = useState([]);

  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  //const [proposalVolumes, setProposalVolumes] = useState([
  //                                                  {
  //                                                    title: "Volume I: Technical Proposal",
  //                                                    sections: [
  //                                                      "1. **Letter of Transmittal** - Compliance with sections 2.1.1, 2.1.1 (a), 2.1.1 (b), 2.1.1 (c), 2.1.1 (d), 2.1.1 (e), 2.1.1 (f)\n\n- **1.1 Authority to Bind Statement** - Compliance with section 2.1.1 (a)\n\n- **1.2 Contact Information** - Compliance with section 2.1.1 (b)",
  //                                                      "2. **Scope of Work**",
  //                                                      "3. **System Design** - [Compliance with sections 3.1, 3.2]",
  //                                                      "4. **Technical Approach and Methodology**",
  //                                                      "5. **Project Management Plan**",
  //                                                      "6. **Testing and Quality Assurance**",
  //                                                      "7. **Post Go-Live Support Plan**",
  //                                                      "8. **Security Protocols and Disaster Recovery Plan**",
  //                                                      "9. **Appendices**"
  //                                                    ]
  //                                                  },
  //                                                  {
  //                                                    title: "Volume II: Management Proposal",
  //                                                    sections: [
  //                                                      "1. **Introduction** - [Compliance with sections 10.1, 10.2]",
  //                                                      "2. **Organizational Structure** - [Compliance with section 11.1]",
  //                                                      "3. **Management Approach**",
  //                                                      "4. **Contract Management Plan**",
  //                                                      "5. **Quality Management Plan** - [Compliance with sections 14.1, 14.2]",
  //                                                      "6. **Transition Plan** - [Compliance with sections 15.1, 15.2]",
  //                                                      "7. **Appendices**"
  //                                                    ]
  //                                                  }
  //                                                ]);
  const [proposalStructureGenerated, setProposalStructureGenerated] = useState(false);
  const [editableVolumeIndex, setEditableVolumeIndex] = useState(null);
  const [outlineTitle, setOutlineTitle] = useState('');

  // Add a useEffect hook to log the updated outlineTitle when it changes
  useEffect(() => {
      //console.log("Updated outline title:", outlineTitle);
  }, [outlineTitle]);


  useEffect(() => {
  if (scrollContainerRef.current) {
    scrollContainerRef.current.scrollTo({ top: 0, behavior: "smooth" });
  }
}, [grantOpportunitiesCurrentPage]); // Runs when the page changes

  const toggleEditability = (volumeIndex, volumeTag) => {
    // Join the sections array into a single string, separating each section by two newlines for clear separation
    handleMoveToStep24();
    setActiveVolumeIndex(volumeIndex);
    //console.log("active volume index - ", volumeIndex);
    setActiveVolumeTag(volumeTag);
    const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
    //console.log("proposal volumes - ", proposalVolumes);
    //console.log("selected volume title - ", selectedVolumeTitle);
    setOutlineTitle(selectedVolumeTitle);
    //console.log("outline title - ", outlineTitle);
    //console.log("outline title - ", selectedVolumeTitle);
    //////console.log("vl tag herehehre: ", volumeTag);
    setTagForDraft(volumeTag);
    const selectedVolumeContent = proposalVolumes[volumeIndex].sections.join('\n\n');
    setOutlineContent(selectedVolumeContent); // Update the outline content with the selected volume's content
    setOutlineFirstDraftMarkdown(selectedVolumeContent);

    setOutlineHeadersGenerated(true); // Assuming this flag is necessary for your application logic

    const volumeKey = toRoman(volumeIndex + 1); // Adding 1 because Roman numerals start from I, not 0
    //console.log(`View & Edit outline clicked for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);

    // Assuming updatedVolumes is an object with Roman numeral keys
    // //console.log("draft volumes: ", draftVolumes);
    // //console.log("draft volumes key: ", volumeKey);
    // //console.log("draft volumes key draft: ", draftVolumes[volumeKey]);
    // //console.log("draft volumes key draft 0: ", draftVolumes[volumeTag]);
    const selectedVolumeContent2 = draftVolumes[volumeTag];

    if (selectedVolumeContent2) {
      setOutlineFirstDraftMarkdown(selectedVolumeContent2);
      setFirstDraftGenerated(true);
      const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
      setOutlineTitle(selectedVolumeTitle);
      const selectedVolumeOutlineContent = proposalVolumes[volumeIndex].sections.join('\n\n');
      setOutlineContent(selectedVolumeOutlineContent); // Update the outline content with the selected volume's content
      //setOutlineContent(volume)
      setTagForDraft(volumeTag);
      setStreamedContent(selectedVolumeContent2);
      setSelectedTab("viewOutline");
      //console.log(firstDraftGenerated);
    } else {
      //console.log(`No updated content found for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);
      setFirstDraftGenerated(false);
      setOutlineFirstDraftMarkdown('');
      setStreamedContent('');
      
    }
    setCurrentOutlineVolume({
      sections: proposalVolumes[volumeIndex].sections, // Using the original sections array here
    });


    setSelectedTab("viewOutline"); // Switch to the "viewOutline" tab
  };
  const toggleEditabilityDraft = (volumeIndex, volumeTag) => {
    // Join the sections array into a single string, separating each section by two newlines for clear separation
    setActiveVolumeIndex(volumeIndex);
    setActiveVolumeTag(volumeTag);
    const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
    setOutlineTitle(selectedVolumeTitle);
    //////console.log("vl tag herehehre: ", volumeTag);
    setTagForDraft(volumeTag);
    const selectedVolumeContent = proposalVolumes[volumeIndex].sections.join('\n\n');
    setOutlineContent(selectedVolumeContent); // Update the outline content with the selected volume's content
    setOutlineFirstDraftMarkdown(selectedVolumeContent);

    setOutlineHeadersGenerated(true); // Assuming this flag is necessary for your application logic

    const volumeKey = toRoman(volumeIndex + 1); // Adding 1 because Roman numerals start from I, not 0
    //console.log(`View & Edit Draft clicked within outline for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);

    // Assuming updatedVolumes is an object with Roman numeral keys
    const selectedVolumeContent2 = draftVolumes[volumeKey];

    if (selectedVolumeContent2) {
      setOutlineFirstDraftMarkdown(selectedVolumeContent2);
      setFirstDraftGenerated(true);
      const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
      setOutlineTitle(selectedVolumeTitle);
      const selectedVolumeOutlineContent = proposalVolumes[volumeIndex].sections.join('\n\n');
      setOutlineContent(selectedVolumeOutlineContent); // Update the outline content with the selected volume's content
      //setOutlineContent(volume)
      setTagForDraft(volumeTag);
      setStreamedContent(selectedVolumeContent2);
      setSelectedTab("viewFirstDraft");
    } else {
      //console.log(`No updated content found for volume index: ${volumeIndex}, Roman numeral: ${volumeKey}`);
    }
    setCurrentOutlineVolume({
      sections: proposalVolumes[volumeIndex].sections, // Using the original sections array here
    });


    setSelectedTab("viewFirstDraft"); // Switch to the "viewOutline" tab
  };






  // Function to toggle sidebar
  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const handleTextSelection = (text) => {
    setSelectedText(text);
  };
  useEffect(() => {
    if (complianceMatrixStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      ////console.log("compl matrix streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [complianceMatrixStreaming]); // Reacts whenever complianceMatrixStreaming changes
  useEffect(() => {
    if (proposalStructureStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      ////console.log("prop struc streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [proposalStructureStreaming]); // Reacts whenever complianceMatrixStreaming changes
  useEffect(() => {
    if (firstDraftStreaming) {
      // Perform actions that should happen after complianceMatrixStreaming is set to true
      ////console.log("first draft streaming!");
      // Reset the streaming state if needed after the operation
      // setComplianceMatrixStreaming(false); // Uncomment if needed
    }
    // Make sure to include other dependencies if they're used inside this effect
  }, [firstDraftStreaming]); // Reacts whenever complianceMatrixStreaming changes

  useEffect(() => {
          //console.log('Draft volumes updated:', draftVolumes);

          const timeoutId = setTimeout(() => {
              //console.log('Timeout after draftVolumes update:', draftVolumes);
          }, 2000);

          // Cleanup function to clear the timeout if draftVolumes changes before the timeout completes
          return () => clearTimeout(timeoutId);
      }, [draftVolumes]);


  const streamedContentRef = useRef(null);
  useEffect(() => {
    if (streamedContentRef.current) {
      streamedContentRef.current.scrollTop = streamedContentRef.current.scrollHeight;
    }
  }, [complianceMatrixStreamed, streamedContent, outlineContent, proposalStructureStreamed]); // Dependency array ensures this runs on complianceMatrixStreamed updates
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const handleScrollToTop = () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.scrollTop = 0; // Scroll to the top
      }
    };

    if (selectedTab === 'viewFirstDraft') {
      const timeoutId = setTimeout(handleScrollToTop, 50); // Delay 500ms
      return () => clearTimeout(timeoutId); // Cleanup the timeout
    } else {
      handleScrollToTop(); // Scroll immediately for other tabs
    }
  }, [selectedTab]);


  //useEffect(() => {
  //    if (selectedTab === 'nameProposal') {
  //        //console.log('Fetching SAM opportunities...');
  //        axios.get(`${BASE_URL}/govex/sam_opportunities`)
  //            .then(response => {
  //                //console.log('SAM opportunities fetched successfully:', response.data);
  //                setSamOpportunities(response.data.opportunities);
  //            })
  //            .catch(error => {
  //                console.error('Error fetching SAM opportunities:', error);
  //            });
  //    }
  //}, [selectedTab]);

  useEffect(() => {
      if (selectedTab === 'samOpportunities') {
          //console.log('Setting SAM opportunities with sample data...');
          setSamOpportunities(sampleData.opportunitiesData);
      }
  }, [selectedTab]);

  useEffect(() => {
      if (selectedTab === 'viewPipeline') {
          axios.get(`${BASE_URL}/get_pipeline`, {
              headers: {
                  'X-API-KEY': apiKey
              }
          })
          .then(response => setPipelineOpportunities(response.data))
          .catch(error => console.error('Error fetching pipeline opportunities:', error));
      }
  }, [selectedTab]);

  useEffect(() => {
    const loadWorkflowDetails = async () => {
      //console.log("Starting to load workflow details...");
      
      // Iterate over proposalProcesses
      for (const [productName, files] of Object.entries(proposalProcesses)) {
        // Skip if productName is empty, null, or just "/"
        if (!productName || productName === '/') {
          //console.log(`Skipping empty or invalid workflow: ${productName}`);
          continue; // Skip this iteration
        }
        
        //console.log(`Processing workflow: ${productName}`);
        //console.log(`Files associated with ${productName}:`, files);

        // Find the analysis-matrix.json file
        const jsonFile = files.find(file => file.name.endsWith('analysis-matrix.json'));
        if (jsonFile) {
          //console.log(`Found analysis-matrix.json file for ${productName}:`, jsonFile);

          // Fetch contents of the analysis-matrix.json file
          const jsonData = await fetchFileContents(jsonFile);
          //console.log(`Fetched JSON data for ${productName}:`, jsonData);

          if (jsonData) {
            setWorkflowDetails(prevDetails => ({
              ...prevDetails,
              [productName]: {
                solicitation_details: jsonData.solicitation_details,
                solicitation_requirements: jsonData.requirements
              }
            }));
            //console.log(`Updated workflow details for ${productName}`);
          } else {
            //console.log(`No JSON data found for ${productName}`);
          }
        } else {
          //console.log(`No analysis-matrix.json file found for ${productName}`);
        }
      }
      
      //console.log("Finished loading workflow details.");
    };

    loadWorkflowDetails();
  }, [proposalProcesses]);

  const fetchMatchedOpportunities = async () => {
    try {
        const response = await fetch(`${BASE_URL}/api/get-matched-opportunities?groupId=${groupId}`);
        if (response.ok) {
            const data = await response.json();
            setMatchedOpportunities(data);
            alert('Matched opportunities fetched successfully!');
        } else {
            const errorData = await response.json();
            console.error("Error fetching matched opportunities:", errorData);
            alert(`Error fetching opportunities: ${errorData.error || 'Unknown error'}`);
        }
    } catch (error) {
        console.error("Unexpected error fetching opportunities:", error);
        alert("An unexpected error occurred while fetching opportunities.");
    }
};
// Function to remove a source from the selected list
const handleRemoveSource = (sourceToRemove) => {
  setSelectedSources(selectedSources.filter(source => source !== sourceToRemove));
};
// Function to add a source when clicked in the dropdown
const handleSourceChange = (source) => {
  if (!selectedSources.includes(source)) {
    setSelectedSources([...selectedSources, source]);
  }
};
useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false); // Close dropdown
    }
  };

  document.addEventListener("mousedown", handleClickOutside);
  return () => document.removeEventListener("mousedown", handleClickOutside);
}, []);

// Function to handle click outside dropdown to close it
const handleClickOutside = (event) => {
  if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
    setIsDropdownOpen(false);
  }
};

// Effect to detect click outside dropdown
useEffect(() => {
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);

const toggleDropdown = () => setIsDropdownOpen((prev) => !prev);

const handleAgencyChange = (agency) => {
  setSelectedAgencies((prevSelected) => {
    if (prevSelected.includes(agency)) {
      return prevSelected.filter((item) => item !== agency); // Remove agency if it's already selected
    } else {
      return [...prevSelected, agency]; // Add new agency to the list
    }
  });
  setSelectedAgenciesForAlert((prev) => [...prev, agency]);
};

const handleRemoveAgency = (agency) => {
  setSelectedAgencies((prevSelected) => prevSelected.filter((item) => item !== agency)); // Remove agency
  setSelectedAgenciesForAlert((prev) => prev.filter((item) => item !== agency));
};
const handleSaveAlert = async () => {
  try {
    //console.log("profile suffix - ", profileSuffix);
    const response = await fetch(`${BASE_URL}/api/set-user-alert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userID,
        sources: selectedSources,  // 🔹 Updated to filter by sources
        profileSuffix: profileSuffix,
        userEmail: userEmail,
      }),
    });

    if (response.ok) {
      fetchAlerts(profileSuffix);  // Fetch the updated list of alerts
      alert("Your alert preferences have been saved!");
    } else {
      alert("Failed to save alert preferences.");
    }
  } catch (error) {
    console.error("Error saving alert preferences:", error);
    alert("Error saving alert preferences.");
  }
};

// Handle adding a new alert
const handleAddAlert = async () => {
  try {
    const response = await fetch(`${BASE_URL}/api/set-user-alert`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userID,
        sources: selectedSources,  // 🔹 Updated to filter by sources
        profileSuffix: profileSuffix,
        userEmail: userEmail,
      }),
    });

    const data = await response.json();
    if (data.message === "Alert saved successfully!") {
      fetchAlerts(profileSuffix);
      setSelectedSources([]);
      handleCloseAlertModal(); // Close the modal after adding
    }
  } catch (error) {
    console.error("Error adding alert:", error);
  }
};


// Handle deleting an alert
const handleRemoveAlert = async (alertId, profileSuffix) => {
  try {
    //console.log("alertId: ", alertId);
    //console.log("profileSuffix: ", profileSuffix);
    const response = await fetch(`${BASE_URL}/api/delete-user-alert`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId: userID, alertId, profileSuffix: profileSuffix }),
    });

    const data = await response.json();
    if (data.message === "Alert deleted successfully!") {
      setAlerts(alerts.filter(alert => alert.alertId !== alertId)); // Remove alert from the list
      fetchAlerts(profileSuffix);
    }
  } catch (error) {
    console.error("Error deleting alert:", error);
  }
};
const handleAlertButtonClick = () => {
  if (!selectedProfile.profileName) {
    alert("Please select a profile before setting alerts.");
    return;
  }

  console.log("Selected Profile:", selectedProfile);
  
  console.log("Opening Alert Modal for Profile:", selectedProfile.profileName);
  setIsAlertModalOpen(true);
};


const handleCloseModal = () => {
  setIsAlertModalOpen(false);
};



const fetchGrantsOpportunities = async (lastEvaluatedKey = null) => {
  setIsFetchingGrantOpps(true);
  
  try {
    if (!selectedProfile) {
      alert("Please select a profile before fetching grants opportunities.");
      return;
    }

    const agencyFilter = selectedAgencies.length ? `&agency=${selectedAgencies.join(',')}` : '';
    const sourceFilter = selectedSources.length ? `&source=${selectedSources.join(',')}` : '&source=all';
    const paginationKey = lastEvaluatedKey ? `&lastEvaluatedKey=${encodeURIComponent(lastEvaluatedKey)}` : '';

    const response = await fetch(
      `${BASE_URL}/api/get-grants-opportunities?userId=${userID}&profileSuffix=${profileSuffix}&limit=50${agencyFilter}${sourceFilter}${paginationKey}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    );

    const data = await response.json();
    console.log("🔍 API Response:", data);

    if (!data.opportunities || !Array.isArray(data.opportunities)) {
      console.error("❌ Invalid API response format:", data);
      return;
    }

    setGrantsOpportunities(prev => 
      lastEvaluatedKey ? [...prev, ...data.opportunities.map(opp => opp.FullOpportunity)] 
                       : data.opportunities.map(opp => opp.FullOpportunity) // If first fetch, clear previous results
    );

    setLastEvaluatedKey(data.lastEvaluatedKey);  // Store pagination key

  } catch (error) {
    console.error("❌ Error fetching grants opportunities:", error);
    alert("An error occurred while fetching grants opportunities.");
  } finally {
    setIsFetchingGrantOpps(false);
  }
};







// Fetch agencies and alerts on component mount
useEffect(() => {
  if (selectedTab === 'grantsOpportunities' || selectedTab === 'companyProfile') {
    // Fetch agencies from the backend
    const fetchAgencies = async () => {
      try {
        const response = await fetch(`${BASE_URL}/api/get-agencies`);
        if (response.ok) {
          const data = await response.json();
          setAgencies(data); // Set the fetched agencies
        } else {
          console.error("Failed to fetch agencies.");
        }
      } catch (error) {
        console.error("Error fetching agencies:", error);
      }
    };

    // Fetch user alerts based on profile suffix
    const fetchAlerts = async (profileSuffix) => {
      if (profileSuffix) {
        try {
          const response = await fetch(`${BASE_URL}/api/get-user-alerts?userId=${userID}&profileSuffix=${profileSuffix}`);
          const data = await response.json();
          //console.log("Alerts data:", data);
          setAlerts(data); // Set the alerts data in the state
        } catch (error) {
          console.error("Error fetching alerts:", error);
        }
      }
    };

    // Call the functions
    fetchAgencies();
    fetchAlerts(profileSuffix);
  }
}, [selectedTab, profileSuffix]); // Dependency array includes profileSuffix to ensure alerts are fetched when the profile changes


// Fetch user alerts from the backend
const fetchAlerts = async (profileSuffix) => {
  try {
    const response = await fetch(`${BASE_URL}/api/get-user-alerts?userId=${userID}&profileSuffix=${profileSuffix}`);
    const data = await response.json();

    // Log the fetched alerts
    //console.log("Fetched alerts:", data);

    // Check if any alerts are fetched
    if (data.length === 0) {
      //console.log("No alerts found for the provided userId and profileSuffix.");
    }

    setAlerts(data);  // Set the alerts data in the state
  } catch (error) {
    console.error("Error fetching alerts:", error);
  }
};




useEffect(() => {
  //console.log("Updated grantsOpportunities State:", grantsOpportunities);
}, [grantsOpportunities]);
useEffect(() => {
  //console.log("Current Page:", grantOpportunitiesCurrentPage);
  //console.log("Total Pages:", Math.ceil(grantsOpportunities.length / grantOpportunitiesPageSize));
}, [grantOpportunitiesCurrentPage, grantsOpportunities]);


// Handle opening the alerts modal
const handleOpenAlertModal = () => {
  setIsAlertViewModalOpen(true);
};

// Handle closing the alerts modal
const handleCloseAlertModal = () => {
  setIsAlertViewModalOpen(false);
};



const fetchReferenceFilesAI = async () => {
  try {
    const response = await axios.get(`${BASE_URL}/list-products`, {
      headers: {
        'X-Company-Name': companyName,
        'X-User-ID': userID,
        'X-Group-ID': groupId,
      },
    });

    //console.log("Fetched reference files:", response.data);
    setAvailableReferenceFilesAI(response.data);  // Set the nested data directly
  } catch (error) {
    console.error('Error fetching reference files:', error);
  }
};


// Open the AI profile modal and fetch reference files
const handleOpenAiProfileModal = () => {
  handleProfileSelection("new");
  fetchReferenceFilesAI();
  setIsAiProfileModalOpen(true);
};

// Close the AI profile modal
const handleCloseAiProfileModal = () => {
  setIsAiProfileModalOpen(false);
  setSelectedReferenceFilesAI([]);
};

const handleFileSelectionAI = (file) => {
  setSelectedReferenceFilesAI((prevSelectedFiles) => {
    // Toggle selection logic
    if (prevSelectedFiles.some((selectedFile) => selectedFile.name === file.name)) {
      // If the file is already selected, remove it
      //console.log("file already selected: ", file.name);
      return prevSelectedFiles.filter((selectedFile) => selectedFile.name !== file.name);
    } else {
      // Otherwise, add it to the selected list
      //console.log("file not selected: ", file.name);
      return [...prevSelectedFiles, file];
    }
  });
};

const handleGenerateAiProfile = async () => {
  if (selectedReferenceFilesAI.length === 0) {
    alert("Please select at least one reference file.");
    return;
  }

  setIsGeneratingProfile(true);

  try {
    //console.log("selectedReferenceFilesAI: ", selectedReferenceFilesAI);
    const response = await axios.post(`${BASE_URL}/api/generate-ai-profile`, {
      selectedFiles: selectedReferenceFilesAI.map((file) => ({
        name: file.name,
        downloadUrl: file.downloadUrl,
      })),
      companyName: companyName,
      userID: userID,
      groupId: groupId,
    });

    if (response.status === 200) {
      const profileData = response.data;

      // Populate the form fields with the AI-generated data
      setProfileName(profileData.profileName || "");
      setCompanyDescription(profileData.companyDescription || "");
      setNaicsCodes(profileData.naicsCodes || "");
      setPscCodes(profileData.pscCodes || "");
      setCapabilities(profileData.capabilities || "");
      setPastPerformance(profileData.pastPerformance || "");
      setTargetKeywords(profileData.targetKeywords || "");
      setLocation(profileData.location || "");
      setFundingNeeds(profileData.fundingNeeds || "");

      alert("Your AI-generated profile data has been loaded. Please review, edit, and press 'Create Profile' to save your profile.");
      setIsGeneratingProfile(false);
      handleCloseAiProfileModal();
    } else {
      console.error("Error generating AI profile:", response);
      setIsGeneratingProfile(false);
    }
  } catch (error) {
    console.error("Error generating AI profile:", error);
    setIsGeneratingProfile(false);
  }
};

// State variables
const [selectedSAMGovCSVFile, setSelectedSAMGovCSVFile] = useState(null);
const [isSAMGovUploadLoading, setIsSAMGovUploadLoading] = useState(false);

// Handle file change
const onSAMGovCSVFileChange = (e) => {
  const file = e.target.files[0];
  if (file && file.type === "text/csv") {
    setSelectedSAMGovCSVFile(file);
  } else {
    alert("Please upload a valid CSV file.");
  }
};

// Handle form submission
const handleSAMGovCSVUpload = async (e) => {
  e.preventDefault();
  if (!selectedSAMGovCSVFile) {
    alert("Please select a CSV file before uploading.");
    return;
  }

  setIsSAMGovUploadLoading(true);

  try {
    // Prepare form data
    const formData = new FormData();
    formData.append("file", selectedSAMGovCSVFile);

    // Make the API request
    const response = await fetch(`${BASE_URL}/api/handle-sam-gov-upload`, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      // Convert response to Blob (binary data)
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary <a> element to trigger the download
      const a = document.createElement("a");
      a.href = url;
      a.download = "processed_sam_data.csv"; // Set the filename
      document.body.appendChild(a);
      a.click(); // Trigger the download
      document.body.removeChild(a); // Cleanup

      alert("CSV file processed and downloaded successfully.");
    } else {
      const errorData = await response.json();
      alert(`Error processing CSV file: ${errorData.error}`);
    }
  } catch (error) {
    console.error("Error uploading SAM.gov CSV file:", error);
    alert("An unexpected error occurred while uploading the CSV file.");
  } finally {
    setIsSAMGovUploadLoading(false);
  }
};



// Handle file input change
const onGrantsXMLFileChange = (event) => {
    setSelectedGrantsXMLFile(event.target.files[0]);
};


const handleGrantsXMLUpload = async (event) => {
  event.preventDefault();

  if (!selectedGrantsXMLFile) {
      alert("Please select an XML file to upload.");
      return;
  }

  const formData = new FormData();
  formData.append("file", selectedGrantsXMLFile); // Use "file" to match Flask backend

  setIsGrantsUploadLoading(true); // Show loading state
  try {
      const response = await fetch(`${BASE_URL}/api/filter-xml`, {
          method: "POST",
          body: formData,
      });

      if (response.ok) {
          const blob = await response.blob(); // Get the file as a blob
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "filtered_grants.xml"; // Default download name
          document.body.appendChild(link);
          link.click();
          link.remove(); // Clean up the DOM
          window.URL.revokeObjectURL(url); // Revoke the object URL
          alert("XML file filtered and downloaded successfully!");
          setSelectedGrantsXMLFile(null);
      } else {
          alert("Failed to filter the XML file. Please try again.");
      }
  } catch (error) {
      console.error("Error during XML file upload:", error);
      alert("An error occurred while uploading the XML file.");
  } finally {
      setIsGrantsUploadLoading(false); // Hide loading state
  }
};



     


const fetchPipelineOpportunities = async () => {
  try {
      const response = await fetch(`${BASE_URL}/api/get-pipeline?userId=${userID}`);
      if (response.ok) {
          const data = await response.json();
          setPipelineOpportunities(data);
      } else {
          const errorData = await response.json();
          console.error('Error fetching pipeline:', errorData);
          alert(`Error fetching pipeline: ${errorData.error || 'Unknown error'}`);
      }
  } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred while fetching the pipeline.');
  }
};
useEffect(() => {
  if (selectedTab === 'pipeline') {
    fetchPipelineOpportunities();
  }
}, [selectedTab]);
useEffect(() => {
  if (selectedTab === 'samOpportunities') {
    fetchMatchedOpportunities();
  }
}, [selectedTab]);







const handleAddToPipeline = async (opportunity) => {
  try {
      const response = await fetch(`${BASE_URL}/api/add-to-pipeline`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              userId: userID,
              opportunity: opportunity
          })
      });

      if (response.ok) {
          alert('Opportunity added to pipeline!');
      } else {
          const errorData = await response.json();
          console.error('Error adding to pipeline:', errorData);
          alert(`Error: ${errorData.error || 'Unknown error'}`);
      }
  } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred while adding to the pipeline.');
  }
};


const handleRemoveFromPipeline = async (opportunityId) => {
  try {
      const response = await fetch(`${BASE_URL}/api/remove-from-pipeline`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({
              userId: userID,
              opportunityId: opportunityId
          })
      });

      if (response.ok) {
          alert('Opportunity removed from pipeline!');
          fetchPipelineOpportunities(); // Refresh the pipeline after removal
      } else {
          const errorData = await response.json();
          console.error('Error removing from pipeline:', errorData);
          alert(`Error: ${errorData.error || 'Unknown error'}`);
      }
  } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred while removing the opportunity.');
  }
};







  const initialConfig = {
    editorState: prepopulateEditor,
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Any custom nodes go here
    nodes: [
      HeadingNode,
      ListNode,
      ListItemNode,
      QuoteNode,
      CodeNode,
      CodeHighlightNode,
      TableNode,
      TableCellNode,
      TableRowNode,
      AutoLinkNode,
      LinkNode
    ]
  };
  function prepopulateEditor() {
    const root = $getRoot();
    if (root.getFirstChild() === null) {
      const heading = $createHeadingNode('h1');
      heading.append($createTextNode('Outline Headers'));
      root.append(heading);
      const paragraph = $createParagraphNode();
      paragraph.append($createTextNode('Your Outline Headers will go here for editing.'));
      root.append(paragraph);

    }
  }

  const draftConfig = {
      editorState: prepopulateEditorForDraft,
      // The editor theme
      theme: ExampleTheme,
      // Handling of errors during update
      onError(error) {
        throw error;
      },
      // Any custom nodes go here
      nodes: [
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
      ]
    };
  function prepopulateEditorForDraft() {
      const root = $getRoot();
      if (root.getFirstChild() === null) {
        const heading = $createHeadingNode('h1');
        heading.append($createTextNode('First Draft'));
        root.append(heading);
        const paragraph = $createParagraphNode();
        paragraph.append($createTextNode('Your First Draft will go here for editing.'));
        root.append(paragraph);
      }
    }
    useEffect(() => {
        //console.log("loadeD + " + window.location.origin);
        kc.init({
          onLoad: initOptions.onLoad,
          KeycloakResponseType: 'code',
          silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html", checkLoginIframe: false,
          pkceMethod: 'S256'
        }).then((auth) => {
          if (!auth) {
            //console.log("loadeD1");
            kc.login();
          } else {
            setIsAuthenticated(true);
            setIsPageLoading(false);
            const decodedToken = jwtDecode(kc.token);
            //console.log('Decoded Token:', decodedToken);

            setKeyCloakToken(kc.token);

            const companyNameFromToken = decodedToken.company_name;
            setCompanyName(companyNameFromToken);

            const lastLoginFromToken = decodedToken.last_login;

            // Extracting groups from the token
            const groupsFromToken = decodedToken.Group || [];

            // Remove leading '/' from each group
            const cleanedGroups = groupsFromToken.map(group => group.replace("/", ""));

            // Store cleaned groups in state
            setGroups(cleanedGroups);

            // Set default group to the first group in the cleaned array
            setSelectedGroup(cleanedGroups[0]);


            const numProposalsFromToken = decodedToken.num_proposals;
            setNumProposals(numProposalsFromToken);

            const numProposalsRFxFromToken = decodedToken.num_proposals_rfx;
            setNumProposalsRFx(numProposalsRFxFromToken);

            const preferredNameFromToken = decodedToken.name;
            setPreferredName(preferredNameFromToken);

            const userEmailFromToken = decodedToken.email;
            setUserEmail(userEmailFromToken);

            const apiKeyFromToken = decodedToken.api_key;
            setApiKey(apiKeyFromToken);

            const pricingPlanFromToken = decodedToken.pricing_plan;
            setPricingTier(pricingPlanFromToken);

            const userIDFromToken = decodedToken.sub;
            setUserID(userIDFromToken);

            const referralCodeFromToken = decodedToken.referral_code;
            setReferralCode(referralCodeFromToken);
            ////console.log(decodedToken);

            const numReferralsFromToken = decodedToken.num_referrals;
            setNumReferrals(numReferralsFromToken);

            const companyDescriptionFromToken = decodedToken.profile_companyDescription || 'N/A';
            setCompanyDescription(companyDescriptionFromToken);

            const naicsCodesFromToken = decodedToken.profile_naicsCodes || 'N/A';
            setNaicsCodes(naicsCodesFromToken);

            const pscCodesFromToken = decodedToken.profile_pscCodes || 'N/A';
            setPscCodes(pscCodesFromToken);

            const capabilitiesFromToken = decodedToken.profile_capabilities || 'N/A';
            setCapabilities(capabilitiesFromToken);

            const pastPerformanceFromToken = decodedToken.profile_pastPerformance || 'N/A';
            setPastPerformance(pastPerformanceFromToken);

            const fetchProfiles = async () => {
              try {
                const response = await fetch(`${BASE_URL}/api/get-user-profiles?userID=${userIDFromToken}`);
                if (response.ok) {
                  const data = await response.json();
                  if (data.length > 0) {
                    setProfiles(data); // Set the profiles in state
                    //console.log("Fetched profiles:", data);
            
                    // Immediately select the first profile after setting profiles
                    handleProfileSelectionOnLoad(data[0].suffix, data); // Use the data directly instead of relying on state
                  } else {
                    // Handle no profiles scenario
                    console.warn("No profiles found for this user.");
                    setProfiles([]); // Set an empty array to avoid rendering issues
                    setSelectedProfile(null); // Clear any previously selected profile
                    setProfileSuffix(null); // Clear profile suffix
                  }
                } else {
                  console.error("Failed to fetch profiles");
                }
              } catch (error) {
                console.error("Error fetching profiles:", error);
              }
            };
            
            
          
            fetchProfiles();

            const fetchGroupId = async () => {
              if (!userIDFromToken) {
                //console.log("User ID is not available");
                return; // Exit if the userIDFromToken is not set
              }
              ////console.log("Fetching Group ID for user:", userIDFromToken);

              try {
                // Adjust the request as needed to match your API endpoint and parameters
                const response = await fetch(`${BASE_URL}/govex/get-user-group?user_id=${userIDFromToken}`, {
                  method: 'GET', // or 'POST' if required by your endpoint
                  headers: {
                    'Authorization': `Bearer ${kc.token}`, // Ensure the token is attached if needed
                    'Content-Type': 'application/json'
                  },
                });

                if (!response.ok) {
                  throw new Error(`Failed to fetch group ID, status: ${response.status}`);
                }

                const text = await response.text(); // First get the response as text

                try {
                  const data = JSON.parse(text); // Then attempt to parse it as JSON
                  setGroupId(data.groupId); // Assuming your API returns a JSON object with a groupId property
                  ////console.log('Group ID1111111:', data.groupId);
                } catch (error) {
                  // This block catches any errors in the JSON parsing process
                  console.error('Error parsing JSON:', error);
                  console.error('Received:', text); // Logs the actual response text
                }
              } catch (error) {
                // This block catches network errors and errors thrown from within the try block
                console.error('Error fetching group ID:', error);
              }
            };

            

            const updateLastLogin = async () => {
              if (!userIDFromToken) {
                //console.log("User ID is not available");
                return;
              }
            
              // Get the current date/time and format it as 'YYYY-MM-DD HH:MM:SS'
              const now = new Date();
              const formattedLastLogin = now.getFullYear() + '-' +
                String(now.getMonth() + 1).padStart(2, '0') + '-' +
                String(now.getDate()).padStart(2, '0') + ' ' +
                String(now.getHours()).padStart(2, '0') + ':' +
                String(now.getMinutes()).padStart(2, '0') + ':' +
                String(now.getSeconds()).padStart(2, '0');
            
              try {
                const response = await fetch(`${BASE_URL}/govex/update-last-login`, {
                  method: 'POST',
                  headers: {
                    'Authorization': `Bearer ${kc.token}`,
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify({
                    user_id: userIDFromToken,
                    last_login: formattedLastLogin,
                    user_email: userEmailFromToken
                  })
                });
            
                if (!response.ok) {
                  throw new Error(`Failed to update last login, status: ${response.status}`);
                } else {
                  //console.log('Successfully updated last login');
                }
              } catch (error) {
                console.error('Error updating last login:', error);
              }
            };
            




            //////console.log('Company Name:', companyNameFromToken);
            //////console.log('Num Proposals:', numProposalsFromToken);
            //////console.log('Name:', preferredNameFromToken);
            //////console.log('Email:', userEmailFromToken);
            //////console.log('API Key:', apiKeyFromToken);
            //////console.log('Pricing Tier:', pricingPlanFromToken);
            //////console.log('User ID:', userIDFromToken);

            ////console.info("Authenticated");
            //////console.log('auth', auth)
            //////console.log('Keycloak', kc)
            fetchGroupId();
            setTimeout(() => {
              if (!lastLoginFromToken) {
                handleStartTour();
              }
            }, 5000); // 2000ms = 2 seconds
            
            updateLastLogin(); // Call to update last login
            kc.onTokenExpired = () => {
              //////console.log('token expired')
            }
          }
        }, () => {
          //console.error("Authenticated Failed");
        });
      }, []);


  // Function to handle group change
  const handleGroupChange = async (newGroupName) => {
    //console.log('Group selected:', newGroupName);  // Log the new group name
  
    setSelectedGroup(newGroupName); // Update selected group in state
    //console.log('Updated selected group in state:', newGroupName);
  
    try {
      //console.log('Sending API request to fetch new group data...');
      const response = await fetch(`${BASE_URL}/govex/get-new-user-group?user_id=${userID}&group_name=${encodeURIComponent(newGroupName)}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${kc.token}`, // Use Keycloak token for authentication
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch new group data, status: ${response.status}`);
      }
  
      const groupData = await response.json();
      ////console.log('Received group data from API:', groupData);
  
      // Update frontend state with new group-specific data
      setCompanyName(groupData.company_name);
      //console.log("groupData.company_name: " + groupData.company_name);
      setNumProposals(groupData.num_proposals);
      setNumProposalsRFx(groupData.num_proposals_rfx);
      setPricingTier(groupData.pricing_plan);
      setReferralCode(groupData.referral_code);
      setNumReferrals(groupData.num_referrals);
      setGroupId(groupData.id);
  
      //console.log('Updated state with new group data');
  
    } catch (error) {
      console.error('Error fetching new group data:', error);
    }
  };
  











  useEffect(() => {
      if (selectedTab === 'viewAnalyzedSBIR') {
          const timer = setTimeout(() => {
              const banner = document.getElementById('banner');
              if (banner) {
                  banner.style.display = 'none';
              }
          }, 10000); // Hide after 5000 milliseconds

          // Cleanup function to clear the timer if the component unmounts
          return () => clearTimeout(timer);
      }
  }, [selectedTab]);


  const handleOutlineHeadersChange = (e) => {
    setEditedOutlineHeaders(e.target.value);
    //////console.log("edited outline headers: " + editedOutlineHeaders);
  };

  useEffect(() => {
    //////console.log(`Selected tab is now: ${selectedTab}`);

    switch (selectedTab) {
      case 'nameProposal':
        // Logic for when the 'Select Workflow' tab is selected
        //////console.log("Handling 'Select Workflow'");
        if (proposalProcessName)
            setNextButtonActive(true);
        break;
      case 'manageDatabase':
        // Logic for when the 'Manage Database' tab is selected
        //////console.log("Handling 'Manage Database'");
        if (proposalProcessName)
            setNextButtonActive(true);
        break;
      case 'generateProposal':
        // Logic for when the 'Upload RFP/RFI/RFQ/SBIR' tab is selected
        //////console.log("Handling 'Upload RFP/RFI/RFQ/SBIR'");
        if (complianceMatrixCreated)
            setNextButtonActive(true);
        else
            setNextButtonActive(false);
        break;
      case 'viewAnalyzedSBIR':
        // Logic for when the 'Compliance Matrix' tab is selected
        //////console.log("Handling 'Compliance Matrix'");
        if (complianceMatrixCreated)
            setNextButtonActive(true);
        break;
      case 'viewProposalStructure':
        // Logic for when the 'Proposal Structure' tab is selected
        //////console.log("Handling 'Proposal Structure'");
        if (complianceMatrixCreated)
            setNextButtonActive(true);
        break;
      case 'viewOutline':
        // Logic for when the 'View & Edit Outline' tab is selected
        //////console.log("Handling 'View & Edit Outline'");
        if (firstDraftGenerated)
            setNextButtonActive(true);
        break;
      case 'viewGenerationFirstDraft':
        // Logic for when the 'View First Draft Being Generated' tab is selected
        //////console.log("Handling 'View First Draft Being Generated'");
        break;
      case 'viewFirstDraft':
        // Logic for when the 'Review Draft' tab is selected
        //////console.log("Handling 'Review Draft'");
        break;
      default:
        // Logic for when no specific tab is selected or an unrecognized tab is selected
        //////console.log('No specific logic for this tab');
    }

  }, [selectedTab]);







  useEffect(() => {
    // This log will now reflect the updated companyName when the useEffect runs
    // Only fetch products if companyName is not empty
    if (userID) {
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/list-products`, {
            headers: {
              'X-Company-Name': companyName,
              'X-User-ID': userID,
              'X-Group-ID': groupId,
            },
          });
          setProducts(response.data);
        } catch (error) {
          //console.error('Error fetching products:', error);
        }
      };
      const fetchProposalProcesses1 = async () => {
        //console.log('Fetching proposal processes for company:', companyName);
        try {
          const response = await axios.get(`${BASE_URL}/list-proposal-processes`, {
            headers: {
              'X-Company-Name': companyName,  // Use companyName from state
              'X-User-ID': userID,
              'X-Group-ID': groupId,  // Pass the group ID in the headers
            },
          });
          const updatedProposalProcesses = {};
          for (const [productName, files] of Object.entries(response.data)) {
            updatedProposalProcesses[productName] = files.map(file => ({
              name: file.file_name,  // Adjust according to your state structure
              downloadUrl: file.download_url,  // Adjust according to your state structure
            }));
          }
          setProposalProcesses(updatedProposalProcesses);
        } catch (error) {
          console.error('Error fetching proposal processes:', error);
        }
      };
      

      fetchProducts();
      fetchProposalProcesses1();
    }
  }, [userID, lastUpload, lastRemove]); // Add companyName as a dependency here

  useEffect(() => {
    // Only fetch products if userID is available
    if (userID) {
      const fetchProposalProcesses = async () => {
        //console.log('Fetching proposal processes for company:', companyName);
        try {
          const response = await axios.get(`${BASE_URL}/list-proposal-processes`, {
            headers: {
              'X-Company-Name': companyName,  // Use companyName from state
              'X-User-ID': userID,
              'X-Group-ID': groupId,  // Pass the group ID in the headers
            },
          });
  
          const updatedProposalProcesses = {};
          for (const [productName, files] of Object.entries(response.data)) {
            updatedProposalProcesses[productName] = files.map(file => ({
              name: file.file_name,  // Adjust according to your state structure
              downloadUrl: file.download_url,  // Adjust according to your state structure
            }));
          }
  
          setProposalProcesses(updatedProposalProcesses);
          //console.log('Proposal processes updated:', updatedProposalProcesses);
  
        } catch (error) {
          console.error('Error fetching proposal processes:', error);
        }
        
      };
      const fetchProducts = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/list-products`, {
            headers: {
              'X-Company-Name': companyName,
              'X-User-ID': userID,
              'X-Group-ID': groupId,
            },
          });
          setProducts(response.data);
        } catch (error) {
          //console.error('Error fetching products:', error);
        }
      };
  
      fetchProposalProcesses();
      fetchProducts();
      clearState();
    }
  }, [groupId]);  // Add companyName as a dependency here

  const clearState = () => {
    setProposalProcessName('');
    setNextButtonActive(false);
    setSelectedFile(null);
    setProposalVolumes([]);
    setProposalStructureGenerated(false);
    setProposalStructureStreaming(false);
    setComplianceMatrixStreaming(false);
    setComplianceMatrix('');
    setComplianceMatrixStreamed('');
    setComplianceMatrixCreated(false);
    setMatrix('');
    setAnalysisSBIR(false);
    setPreApplicationProposalStructure(null);
    setFullApplicationProposalStructure(null);
    setSelectedTab('nameProposal');
    setOutlineContent('');
    setComplianceMatrixStreamed(null);
    setComplianceMatrixCreated(false);
    setProposalStructureGenerated(false);
    setOutlineHeadersGenerated(false);
    setFirstDraftGenerated(false);
    setIsProcessing(false);
    setSelectedRefFiles({});
    //console.log('State cleared after groupId change');
  };
  
  
  
  

  const handleDownloadFromAllProductsDiv = (file) => {
    // Assuming 'file' is an object with { name, downloadUrl }
    const { downloadUrl, name } = file;

    if (!downloadUrl) {
      //console.error('Download URL not found for file:', name);
      return;
    }

    // Create an anchor element and trigger the download
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', name); // This will prompt a file download with the original file name
    document.body.appendChild(link);
    link.click();
    link.remove(); // Clean up
  };





  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: 'image/*', // specify file types
    onDrop: (acceptedFiles) => {
      handleProductFilesChange(acceptedFiles);
    },
  });




  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleMoveToStep10();
    if (file) {
      setSelectedFile(file);
      // Immediately clear the input after setting the file
      event.target.value = null;
    }
  };

  useEffect(() => {
    //console.log("selectedFile:", selectedFile);
  }, [selectedFile]);



  const handlePreviousFileChange = (event) => {
    setSelectedPreviousFile(event.target.files[0]);
  };
  const handleFileUploadMatrix = async () => {
    const formData = new FormData();
    formData.append('productFile', selectedFile);
    //////console.log("selected file - ", selectedFile);

    // Include any additional data as needed
    formData.append('tag', 'rfp');
    formData.append('userID', userID);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', selectedFile);
    formData.append('groupID', groupId);
    formData.append('companyName', companyName);


    // Include the previous proposal file if selected
    //if (selectedPreviousFile) {
    //  formData.append('previousFile', selectedPreviousFile);
    //}

    setIsProcessing(true);


    try {

        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        ////console.log("File saved with 'rfp' tag, continuing with outline header processing.");
        await new Promise(resolve => setTimeout(resolve, 2000));

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
  };

  const handleReferenceFileSubmit = async (e) => {
    e.preventDefault();
    //console.log('Form submission started');

    try {
        if (!referenceFileTitle) throw new Error('Reference file title is undefined.');
        if (!proposalProcessName) throw new Error('Proposal process name is undefined.');

        //console.log('Creating Word document');

        const doc = new Document({
            sections: [{
                properties: {},
                children: [
                    new Paragraph({
                        text: referenceFileTitle,
                        heading: HeadingLevel.HEADING_1,
                    }),
                    new Paragraph({
                        text: `Description: ${referenceFileDescription}`,
                    }),
                    new Paragraph({
                        text: `Company Overview: ${companyOverview}`,
                    }),
                    new Paragraph({
                        text: `Company Background: ${companyBackground}`,
                    }),
                    new Paragraph({
                        text: `Product Details: ${productDetails}`,
                    }),
                    new Paragraph({
                        text: `Unique Product Differentiator: ${uniqueProduct}`,
                    }),
                    new Paragraph({
                        text: `Certifications & Awards: ${certifications}`,
                    }),
                    new Paragraph({
                        text: `Additional Information: ${additionalInfo}`,
                    }),
                ]
            }]
        });

        //console.log('Converting document to base64 string');

        // Convert the document to a base64 string
        const base64String = await Packer.toBase64String(doc);

        // Create a Blob from the base64 string
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

        // Ensure the Blob object was created successfully
        if (!blob) throw new Error('Failed to create Blob from base64 string.');
        //console.log('Blob created successfully');

        // Create a File object from the Blob
        const file = new File([blob], `${referenceFileTitle}.docx`, {
            type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        });

        // Ensure the File object was created successfully
        if (!file) throw new Error('Failed to create File from Blob.');
        //console.log('File created successfully');

        // Upload the file to the database with the specified product name
        const formData = new FormData();
        formData.append('productName', 'GovEx AI Created Forms');
        formData.append('companyName', companyName);
        formData.append('productFiles', file);  // Since `getlist` is used on the backend, it expects multiple files, so send as a list
        formData.append('groupID', groupId);
        //console.log('Uploading file to the database');

        const response = await axios.post(`${BASE_URL}/upload`, formData, {
            headers: {
                Authorization: `Bearer ${kc.token}`,
            },
        });

        if (response.status === 200) {
            //console.log('Reference file uploaded successfully');
            setShowReferenceForm(false);
            setReferenceFileTitle('');
            setReferenceFileDescription('');
            setCompanyOverview('');
            setCompanyBackground('');
            setProductDetails('');
            setUniqueProduct('');
            setCertifications('');
            setAdditionalInfo('');
            setUploadSuccess(true); // Set success message state
            setIsAddingProduct(false); // Hide the upload form
            setLastUpload(Date.now()); // Update the timestamp or simply increment if using a counter
        } else {
            console.error('Error uploading reference file:', response.statusText);
            setUploadSuccess(false); // Set success message state
        }
    } catch (error) {
        console.error('Error in handleReferenceFileSubmit:', error.message);
    }
};

const handleGenerateReferenceFromUrl = async () => {

  setIsLoading(true);
  try {
      // Prepare the form data for the API request
      const formData = new FormData();
      formData.append('url', companyUrl);
      formData.append('tag', 'company_website'); // Add any additional tags or metadata

      // Send the URL to the backend to fetch, analyze, and summarize the reference file
      const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-url`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${keycloakToken}`, // Add your authentication token
          },
          body: formData,
      });

      if (!saveResponse.ok) {
        // Try to parse the error message from the response
        let errorMessage = `Error saving file from URL: ${saveResponse.statusText}`;
        try {
            const errorData = await saveResponse.json();
            if (errorData.error) {
                errorMessage += ` - ${errorData.error}`;
            }
            if (errorData.trace) {
                errorMessage += `\nTrace: ${errorData.trace}`;
            }
            if (errorData.debug_info) {
                errorMessage += `\nDebug Info: ${JSON.stringify(errorData.debug_info, null, 2)}`;
            }
        } catch (err) {
            console.error('Error parsing the response JSON:', err);
        }
    
        throw new Error(errorMessage);
    }

      // Get the backend response which includes the summarized website data
      const responseData = await saveResponse.json();

      //console.log('Backend response received, creating Word document');

      // Create a Word document based on the response data
      const doc = new Document({
          sections: [{
              properties: {},
              children: [
                  // Main Header with extra spacing after
                  new Paragraph({
                      text: `Website Analysis for: ${companyUrl}`,
                      heading: HeadingLevel.HEADING_1,
                      spacing: {
                          after: 300,  // Adds space after this paragraph
                      },
                  }),
                  // Company Overview Header
                  new Paragraph({
                      text: "Company Overview",
                      heading: HeadingLevel.HEADING_2,  // Second-level header for company overview
                      spacing: {
                          before: 200,  // Adds space before this header
                          after: 100,   // Adds space after this header
                      },
                  }),
                  // Company Overview Content
                  new Paragraph({
                      text: responseData.summary || 'No summary available',
                      spacing: {
                          after: 200,  // Adds space after the content
                      },
                  }),
                  // Product Details Header
                  new Paragraph({
                      text: "Product Details",
                      heading: HeadingLevel.HEADING_2,  // Second-level header for product details
                      spacing: {
                          before: 200,  // Adds space before this header
                          after: 100,   // Adds space after this header
                      },
                  }),
                  // Product Details Content
                  new Paragraph({
                      text: responseData.insights || 'No insights available',
                      spacing: {
                          after: 200,  // Adds space after the content
                      },
                  }),
                  // Additional Notes Header
                  new Paragraph({
                      text: "Additional Notes",
                      heading: HeadingLevel.HEADING_2,  // Second-level header for additional notes
                      spacing: {
                          before: 200,  // Adds space before this header
                          after: 100,   // Adds space after this header
                      },
                  }),
                  // Additional Notes Content
                  new Paragraph({
                      text: responseData.notes || 'No additional notes available',
                      spacing: {
                          after: 300,  // Adds space after the content
                      },
                  }),
              ]
          }]
      });









    
    

      //console.log('Converting document to base64 string');

      // Convert the document to a base64 string
      const base64String = await Packer.toBase64String(doc);

      // Convert the base64 string to a Blob object
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });

      if (!blob) throw new Error('Failed to create Blob from base64 string.');
      //console.log('Blob created successfully');

      // Create a File object from the Blob
      const file = new File([blob], `Website_Analysis_${companyUrl}.docx`, {
          type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      });

      if (!file) throw new Error('Failed to create File from Blob.');
      //console.log('File created successfully');

      // Upload the file to the server/database
      const uploadFormData = new FormData();
      uploadFormData.append('productName', 'GovEx AI Created Forms');
      uploadFormData.append('groupID', groupId);
      uploadFormData.append('productFiles', file);  // Upload the generated Word document
      uploadFormData.append('companyName', companyName);

      //console.log('Uploading Word document to the database');

      const uploadResponse = await fetch(`${BASE_URL}/upload`, {
          method: 'POST',
          headers: {
              Authorization: `Bearer ${keycloakToken}`, // Add your authentication token
          },
          body: uploadFormData,
      });

      if (!uploadResponse.ok) {
          throw new Error(`Error uploading file: ${uploadResponse.statusText}`);
      }

      // If upload is successful
      //console.log('Word document uploaded successfully');
      setUploadSuccess(true); // Update UI to reflect success
      setLastUpload(Date.now()); // Update the timestamp or simply increment if using a counter
  } catch (error) {
      console.error('Error in handleGenerateReferenceFromUrl:', error.message);
      console.error('Error in handleGenerateReferenceFromUrl:', error);
      setUploadSuccess(false); // Update UI to reflect error
  } finally {
    setIsLoading(false);
  }
};



  


  const handleFileUpload = async () => {

    const formData = new FormData();
    formData.append('productFile', selectedFile);
    //////console.log("selected file - ", selectedFile);

    // Include any additional data as needed
    formData.append('tag', 'rfp');
    formData.append('userID', userID);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', selectedFile);
    formData.append('groupID', groupId);
    formData.append('companyName', companyName);


    // Include the previous proposal file if selected
    //if (selectedPreviousFile) {
    //  formData.append('previousFile', selectedPreviousFile);
    //}

    setIsProcessing(true);


    try {

        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        ////console.log("File saved with 'rfp' tag, continuing with outline header processing.");
        await new Promise(resolve => setTimeout(resolve, 2000));



        //////console.log(`Sending request to ${BASE_URL}/govex/process-pdf-create-outline-headers`);
        const response = await fetch(`${BASE_URL}/govex/process-pdf-create-outline-headers`, {
            method: 'POST',
            body: formData,
        });

        //////console.log(`Response status: ${response.status}, Status text: ${response.statusText}`);
        if (!response.ok) {
            throw new Error(`Stream response was not ok: ${response.statusText}`);
        }

        //////console.log("Stream response received, starting to process");
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        //////console.log("Decoder and reader initialized");

        setSelectedTab("viewGenerationOutline");
        //////console.log("Selected tab set to viewGenerationOutline");

        let completeOutline = '';


        while (true) {
            const { value, done } = await reader.read();
            if (done) {
                //////console.log("Streaming complete");
                break;
            }
            const chunk = decoder.decode(value, { stream: true });
            //////console.log("Chunk received:", chunk);
            completeOutline += chunk;
            setOutlineContent((prevContent) => prevContent + chunk);
            setEditedOutlineHeaders((prevContent) => prevContent + chunk);
        }


        //// Now, send the complete proposal content to finalize the proposal
        //const finalizeOutline = await fetch('http://localhost:5000/govex/finalize-outline', {
        //    method: 'POST',
        //    body: JSON.stringify({ finalizedOutline: completeOutline }),
        //});
        //
        //if (!finalizeOutline.ok) {
        //    throw new Error(`Finalize response was not ok: ${finalizeOutline.statusText}`);
        //}
        //
        //const finalizeData = await finalizeOutline.json();
        //////console.log("Finalize response received:", finalizeData);
        //
        //setOutlineContent(finalizeData.finalizedOutline);


        setShowEditor(true);
        setOutlineHeadersGenerated(true);

        setSelectedTab("viewOutline");


        //setOutlineContent("");
        //setEditedOutlineHeaders("");
        //setShowEditor(true);
        //setOutlineHeadersGenerated(true);

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
  };
  const userInfo = {
    companyName: companyName,
    username: preferredName,
    email: userEmail,
    numProposals: numProposals,
    numProposalsRFx: numProposalsRFx,
    apiKey: apiKey,
    pricingTier: pricingTier
  };


  const handleLogout = () => {
    kc.logout({ redirectUri: 'https://govexai.com/' });
  };

  const handleProposalProcessNameChange = (event) => {
    // Prevent slashes in the proposal process name
    const updatedName = event.target.value.replace(/\//g, '');
    setProposalProcessNameInText(updatedName);
  };



  const handleProposalProcessSubmit = async (e) => {

      e.preventDefault();
      setProposalProcessName(proposalProcessNameInText);
      setNextButtonActive(true);
      const formData = new FormData();
      formData.append('proposalName', proposalProcessName);
      formData.append('groupID', groupId);

      try {
          const token = kc.token; // Assuming 'kc' is correctly initialized
          //////console.log("token: " + token);

          const response = await axios.post(`${BASE_URL}/govex/create-folder`, formData, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //////console.log(response.data);
          setSelectedFile(null);
          setFirstDraftGenerated(false);
          setOutlineFirstDraftMarkdown('');
          setOutlineHeadersGenerated(false);
          setOutlineContent('');
          setAnalysisSBIR(false);
          setComplianceMatrixCreated(false);
          setProposalStructureGenerated(false);
          setComplianceMatrixStreaming(false);
          setComplianceMatrix('');
          setOutlineFirstDraftMarkdown('');
          setOutlineHeadersGenerated(false);
          setOutlineContent('');
          setSelectedRefFiles([]);


          setSelectedTab("manageDatabase");
          console.log("Selected tab set to manageDatabase");
          
      } catch (error) {
          //console.error('Error creating folder:', error);
      }
  };

  const handleProposalProcessContinueWork = async (productName) => {
      // Check if the product is an SBIR workflow
      const isSBIRWorkflow = proposalProcesses[productName].some(file => file.name.endsWith('.json'));

      if (!isSBIRWorkflow) {
          // Redirect to /dashboard-sbir
          window.location.href = '/dashboard';

          // Delay execution to ensure the redirection takes place
          setTimeout(() => continueSBIRWork(productName), 1000);
      } else {
          // Continue with the rest of the logic if it's not an SBIR workflow
          continueSBIRWork(productName);
      }
  };

  const continueSBIRWork = async (productName) => {
    clearState();
    setProposalProcessName(productName); // Assuming this sets the context for further operations
    setNextButtonActive(true);


    try {

      // Fetch the file information for the given productName with the 'rfp' tag
      const formData = new FormData();
      //////console.log("uuu id", userID);

      // Include any additional data as needed
      formData.append('tags', 'rfp,draft,outline,matrix,proposal-structure,sbir-analysis-json,proposal-structure-pre_application,proposal-structure-full_application');
      formData.append('userID', userID);
      formData.append('proposalName', productName);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      //console.log("groupId", groupId);

      const fileInfoResponse = await fetch(`${BASE_URL}/govex/fetch-file-info`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${keycloakToken}`,
        },
        body: formData,
      });

      if (fileInfoResponse.status === 200) {
          const files = await fileInfoResponse.json();  // 'files' is expected to be an array of file information objects
          //////console.log("Files info:", files);

          // Iterate over each file
          files.forEach(file => {
              // Check if the tag is 'rfp'
              //////console.log("Inspecting file:", file);

              // Check if the file has a tag that matches the pattern 'draft_volume_\d+'
              const draftVolumeTag = file.tags.find(tag => /^[a-z_]+_draft_volume_(I|II|III|IV|V|VI|VII)$/.test(tag));

              //console.log("\n=== Checking File Tags for Draft Volume ===");
              //console.log(`File Tags: ${file.tags}`);

              const draftVolumeOutlineTag = file.tags.find(tag => /^draft_volume_(\w+)_outline$/.test(tag));

              if (file.tags && file.tags.includes('rfp')) {
                  // Fetch the RFP file and set it as selectedFile
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const filename = file.filename || 'defaultFilename.pdf';
                          //////console.log("RFP Filename - ", filename);
                          const rfpFile = new File([blob], filename, { type: 'application/pdf' });
                          setSelectedFile(rfpFile);  // Assuming 'setSelectedFile' updates the state with the fetched RFP file
                          setSelectedTab("manageDatabase");
                      })
                      .catch(error => {
                          //console.error('Fetch error for RFP file:', error.message);
                          setSelectedTab("manageDatabase");
                      });
              } else if (draftVolumeOutlineTag) {
                  //////console.log(`Found draft volume outline tag (${draftVolumeOutlineTag}) in file:`, file.filename);
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const formData = new FormData();
                          formData.append("file", blob, file.filename);

                          return fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                              method: 'POST',
                              body: formData,
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Conversion API response was not ok: ${response.statusText}`);
                          }
                          return response.json(); // Assuming the API returns the Markdown content in JSON format
                      })
                      .then(data => {
                          const markdownContent = data.markdown;

                          // Extract the volume identifier from the draftVolumeOutlineTag
                          const volumeIdentifier = draftVolumeOutlineTag.match(/^draft_volume_(\w+)_outline$/)[1];
                          //////console.log(`Processing outline for volume ${volumeIdentifier}`);

                          // Assuming you have a method to update a specific volume's outline content
                          // This method should update the state holding the outlines, keyed by volume identifier
                          updateDraftVolumeOutline(volumeIdentifier, markdownContent);
                      })
                      .catch(error => {
                          //console.error('Error processing draft volume outline:', error.message);
                      });
              }

              else if (file.tags && file.tags.includes('proposal-structure')) {
                  //////console.log("Starting file fetch:", file.downloadUrl);
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          ////console.log("File fetch successful");
                          return response.text(); // Change here to get text instead of blob, as Markdown is plain text
                      })
                      .then(markdownContent => {
                          //////console.log("Received Markdown content:", markdownContent);

                          // Since you already have the Markdown content, you can directly proceed to use it
                          ////console.log("Finalizing proposal structure");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  // Include authorization headers as needed, e.g.:
                                  'Authorization': `Bearer ${keycloakToken}`,
                                  'X-Group-ID': groupId
                              },
                              body: JSON.stringify({
                                  finalizedProposalStructure: markdownContent // Use the Markdown content directly
                              }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          ////console.log("Proposal structure finalization response received");
                          return response.json();
                      })
                      .then(data => {
                          //////console.log("Finalized proposal structure received:", data.finalizedProposalStructure);
                          setProposalVolumes(data.finalizedProposalStructure);  // Update your state with the structured volumes
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          //console.error('Error in the process:', error);
                      });
              }


              else if (file.tags && file.tags.includes('matrix')) {
                fetch(file.downloadUrl)
                  .then(response => response.blob())
                  .then(blob => {
                    // Convert the blob to a markdown string using the refactored function
                    return processExcelBlobToMarkdown(blob);
                  })
                  .then(markdownString => {
                    // Set the markdown string in your component's state
                    setComplianceMatrixStreaming(true);
                    setComplianceMatrix(markdownString);
                    setComplianceMatrixStreamed(markdownString);
                    setComplianceMatrixCreated(true);
                  })
                  .catch(error => console.error('Error fetching matrix file:', error));
              }
              else if (draftVolumeTag) {
                  (async () => {
                      //console.log(`\n=== Processing Draft Volume Tag ===`);
                      //console.log(`Draft Volume Tag: ${draftVolumeTag}`);

                      const volumeTag = draftVolumeTag;
                      //console.log(`Using Full Volume Tag: ${volumeTag}`);

                      try {
                          //console.log(`Fetching file from URL: ${file.downloadUrl}`);
                          const fileResponse = await fetch(file.downloadUrl);
                          //console.log(`Received response for file fetch, status: ${fileResponse.status}`);
                          if (!fileResponse.ok) {
                              throw new Error(`Network response was not ok: ${fileResponse.statusText}`);
                          }
                          const blob = await fileResponse.blob();
                          //console.log(`Blob fetched for volume ${volumeTag}`);
                          //console.log(`Blob Size: ${blob.size} bytes`);

                          const formData = new FormData();
                          formData.append("file", blob, file.filename);
                          //console.log(`FormData prepared for volume ${volumeTag} with filename: ${file.filename}`);

                          //console.log(`Calling conversion service at: ${BASE_URL}/govex/convert-docx-to-markdown`);
                          const conversionResponse = await fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                              method: 'POST',
                              body: formData,
                          });
                          //console.log(`Received response for conversion service, status: ${conversionResponse.status}`);
                          if (!conversionResponse.ok) {
                              throw new Error(`Conversion API response was not ok for volume ${volumeTag}: ${conversionResponse.statusText}`);
                          }
                          const data = await conversionResponse.json();
                          const markdownContent = data.markdown;
                          //console.log(`Conversion successful for volume ${volumeTag}`);
                          //console.log(`Markdown content length: ${markdownContent.length}`);
                          //console.log(`Markdown content preview: ${markdownContent.slice(0, 100)}...`);

                          //console.log(`Updating draft volume for volume tag: ${volumeTag}`);
                          updateDraftVolume(volumeTag, markdownContent);
                          //console.log("Draft volumes updated: ", draftVolumes);
                      } catch (error) {
                          console.error(`Error processing draft volume ${volumeTag}:`, error);
                      }
                  })();
              }



              else if (file.tags && file.tags.includes('sbir-analysis-json')) {
                fetch(file.downloadUrl)
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(`Network response was not ok: ${response.statusText}`);
                    }
                    return response.json(); // Parse JSON content directly
                  })
                  .then(jsonContent => {
                    //console.log("Received SBIR JSON content:", jsonContent);
                    setMatrix(jsonContent); // Set the JSON content to sbirMatrix
                    setComplianceMatrixCreated(true);
                    setNextButtonActive(true);
                    setAnalysisSBIR(true);
                    setComplianceMatrixStreaming(true);


                    const subtopics = jsonContent.subtopics;
                    setComplianceMatrix(subtopics);
                    // Optionally process subtopics and save to DB or export to Excel
                    //saveExcelFileToDBImport(subtopics);
                    //handleExportToExcelWithParam(subtopics);
                  })
                  .catch(error => {
                    console.error('Fetch error for SBIR JSON file:', error.message);
                  });
              }

              else if (file.tags.includes('proposal-structure-pre_application')) {
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          //console.log("Response from fetch:", response);
                          return response.text();
                      })
                      .then(markdownContent => {
                          //console.log("Fetched markdown content:", markdownContent); 
                          //console.log("IN PRE APP!");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${keycloakToken}`
                              },
                              body: JSON.stringify({ finalizedProposalStructure: markdownContent, stage: "pre_application" }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          return response.json();
                      })
                      .then(data => {
                          // Process each section to remove the #MAIN marker using the formatToMarkdownList function
                          //console.log("Received data from backend:", data);
                          const processedStructure = data.finalizedProposalStructure.map(volume => ({
                              ...volume,
                              sections: volume.sections.map(section => formatToMarkdownList(section).join('\n')),
                          }));

                          setPreApplicationProposalStructure(processedStructure);
                          //console.log(processedStructure);
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          console.error(`Error fetching proposal structure (pre_application):`, error);
                      });
              } else if (file.tags.includes('proposal-structure-full_application')) {
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.text();
                      })
                      .then(markdownContent => {
                          //console.log("IN FULL APP!");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${keycloakToken}`
                              },
                              body: JSON.stringify({ finalizedProposalStructure: markdownContent, stage: "full_application" }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          return response.json();
                      })
                      .then(data => {
                          // Process each section to remove the #MAIN marker using the formatToMarkdownList function
                          const processedStructure = data.finalizedProposalStructure.map(volume => ({
                              ...volume,
                              sections: volume.sections.map(section => formatToMarkdownList(section).join('\n')),
                          }));

                          setFullApplicationProposalStructure(processedStructure);
                          //console.log(processedStructure);
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          console.error(`Error fetching proposal structure (full_application):`, error);
                      });
              }





          });
          setSelectedTab("manageDatabase");
      } else {
          console.error('Error fetching file information:', fileInfoResponse.statusText);
          // Handle HTTP errors, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching file information:', error);
    }
  };

  const handleProposalProcessContinueWorkStatus = async (productName, status) => {
    // Check if the product is an SBIR workflow
    const isSBIRWorkflow = proposalProcesses[productName].some(file => file.name.endsWith('.json'));

    if (!isSBIRWorkflow) {
        // Redirect to /dashboard-sbir
        window.location.href = '/dashboard';
    } else {
        // Continue with the rest of the logic if it's not an SBIR workflow
        continueSBIRWorkStatus(productName, status);
    }
};

  const continueSBIRWorkStatus = async (productName, status) => {
    setProposalProcessName(productName); // Assuming this sets the context for further operations
    setNextButtonActive(true);


    try {

      // Fetch the file information for the given productName with the 'rfp' tag
      const formData = new FormData();
      //////console.log("uuu id", userID);

      // Include any additional data as needed
      formData.append('tags', 'rfp,draft,outline,matrix,proposal-structure,sbir-analysis-json,proposal-structure-pre_application,proposal-structure-full_application,reference-files');
      formData.append('userID', userID);
      formData.append('proposalName', productName);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      //console.log("groupId", groupId);

      const fileInfoResponse = await fetch(`${BASE_URL}/govex/fetch-file-info`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${keycloakToken}`,
        },
        body: formData,
      });

      if (fileInfoResponse.status === 200) {
          const files = await fileInfoResponse.json();  // 'files' is expected to be an array of file information objects
          console.log("Files info:", files);

          // Iterate over each file
          files.forEach(file => {
              // Check if the tag is 'rfp'
              //console.log("Inspecting file:", file);

              // Check if the file has a tag that matches the pattern 'draft_volume_\d+'
              const draftVolumeTag = file.tags.find(tag => /^[a-z_]+_draft_volume_(I|II|III|IV|V|VI|VII)$/.test(tag));

              //console.log("\n=== Checking File Tags for Draft Volume ===");
              //console.log(`File Tags: ${file.tags}`);

              const draftVolumeOutlineTag = file.tags.find(tag => /^draft_volume_(\w+)_outline$/.test(tag));

              if (file.tags && file.tags.includes('rfp')) {
                  // Fetch the RFP file and set it as selectedFile
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const filename = file.filename || 'defaultFilename.pdf';
                          //////console.log("RFP Filename - ", filename);
                          const rfpFile = new File([blob], filename, { type: 'application/pdf' });
                          setSelectedFile(rfpFile);  // Assuming 'setSelectedFile' updates the state with the fetched RFP file
                      })
                      .catch(error => {
                          console.error('Fetch error for RFP file:', error.message);
                      });
              } else if (draftVolumeOutlineTag) {
                  //////console.log(`Found draft volume outline tag (${draftVolumeOutlineTag}) in file:`, file.filename);
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.blob();
                      })
                      .then(blob => {
                          const formData = new FormData();
                          formData.append("file", blob, file.filename);

                          return fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                              method: 'POST',
                              body: formData,
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Conversion API response was not ok: ${response.statusText}`);
                          }
                          return response.json(); // Assuming the API returns the Markdown content in JSON format
                      })
                      .then(data => {
                          const markdownContent = data.markdown;

                          // Extract the volume identifier from the draftVolumeOutlineTag
                          const volumeIdentifier = draftVolumeOutlineTag.match(/^draft_volume_(\w+)_outline$/)[1];
                          //////console.log(`Processing outline for volume ${volumeIdentifier}`);

                          // Assuming you have a method to update a specific volume's outline content
                          // This method should update the state holding the outlines, keyed by volume identifier
                          updateDraftVolumeOutline(volumeIdentifier, markdownContent);
                      })
                      .catch(error => {
                          //console.error('Error processing draft volume outline:', error.message);
                      });
              }

              //else if (file.tags && file.tags.includes('proposal-structure')) {
              //    //////console.log("Starting file fetch:", file.downloadUrl);
              //    fetch(file.downloadUrl)
              //        .then(response => {
              //            if (!response.ok) {
              //                throw new Error(`Network response was not ok: ${response.statusText}`);
              //            }
              //            ////console.log("File fetch successful");
              //            return response.text(); // Change here to get text instead of blob, as Markdown is plain text
              //        })
              //        .then(markdownContent => {
              //            //////console.log("Received Markdown content:", markdownContent);
//
              //            // Since you already have the Markdown content, you can directly proceed to use it
              //            ////console.log("Finalizing proposal structure");
              //            return fetch(`${BASE_URL}/govex/finalize-proposal-structure`, {
              //                method: 'POST',
              //                headers: {
              //                    'Content-Type': 'application/json',
              //                    // Include authorization headers as needed, e.g.:
              //                    'Authorization': `Bearer ${keycloakToken}`,
              //                    'X-Group-ID': groupId
              //                },
              //                body: JSON.stringify({
              //                    finalizedProposalStructure: markdownContent // Use the Markdown content directly
              //                }),
              //            });
              //        })
              //        .then(response => {
              //            if (!response.ok) {
              //                throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
              //            }
              //            ////console.log("Proposal structure finalization response received");
              //            return response.json();
              //        })
              //        .then(data => {
              //            //////console.log("Finalized proposal structure received:", data.finalizedProposalStructure);
              //            setProposalVolumes(data.finalizedProposalStructure);  // Update your state with the structured volumes
              //            setProposalStructureGenerated(true);
              //            setNextButtonActive(true);
              //            setProposalStructureStreaming(true);
              //        })
              //        .catch(error => {
              //            //console.error('Error in the process:', error);
              //        });
              //}


              else if (file.tags && file.tags.includes('matrix')) {
                fetch(file.downloadUrl)
                  .then(response => response.blob())
                  .then(blob => {
                    // Convert the blob to a markdown string using the refactored function
                    return processExcelBlobToMarkdown(blob);
                  })
                  .then(markdownString => {
                    // Set the markdown string in your component's state
                    setComplianceMatrixStreaming(true);
                    setComplianceMatrix(markdownString);
                    setComplianceMatrixStreamed(markdownString);
                    setComplianceMatrixCreated(true);
                  })
                  .catch(error => console.error('Error fetching matrix file:', error));
              }
              else if (draftVolumeTag) {
                  (async () => {
                      //console.log(`\n=== Processing Draft Volume Tag ===`);
                      //console.log(`Draft Volume Tag: ${draftVolumeTag}`);

                      const volumeTag = draftVolumeTag;
                      //console.log(`Using Full Volume Tag: ${volumeTag}`);

                      try {
                          //console.log(`Fetching file from URL: ${file.downloadUrl}`);
                          const fileResponse = await fetch(file.downloadUrl);
                          //console.log(`Received response for file fetch, status: ${fileResponse.status}`);
                          if (!fileResponse.ok) {
                              throw new Error(`Network response was not ok: ${fileResponse.statusText}`);
                          }
                          const blob = await fileResponse.blob();
                          //console.log(`Blob fetched for volume ${volumeTag}`);
                          //console.log(`Blob Size: ${blob.size} bytes`);

                          const formData = new FormData();
                          formData.append("file", blob, file.filename);
                          //console.log(`FormData prepared for volume ${volumeTag} with filename: ${file.filename}`);

                          //console.log(`Calling conversion service at: ${BASE_URL}/govex/convert-docx-to-markdown`);
                          const conversionResponse = await fetch(`${BASE_URL}/govex/convert-docx-to-markdown`, {
                              method: 'POST',
                              body: formData,
                          });
                          //console.log(`Received response for conversion service, status: ${conversionResponse.status}`);
                          if (!conversionResponse.ok) {
                              throw new Error(`Conversion API response was not ok for volume ${volumeTag}: ${conversionResponse.statusText}`);
                          }
                          const data = await conversionResponse.json();
                          const markdownContent = data.markdown;
                          //console.log(`Conversion successful for volume ${volumeTag}`);
                          //console.log(`Markdown content length: ${markdownContent.length}`);
                          //console.log(`Markdown content preview: ${markdownContent.slice(0, 100)}...`);

                          //console.log(`Updating draft volume for volume tag: ${volumeTag}`);
                          updateDraftVolume(volumeTag, markdownContent);
                          //console.log("Draft volumes updated: ", draftVolumes);
                      } catch (error) {
                          console.error(`Error processing draft volume ${volumeTag}:`, error);
                      }
                  })();
              }



              else if (file.tags && file.tags.includes('sbir-analysis-json')) {
                fetch(file.downloadUrl)
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(`Network response was not ok: ${response.statusText}`);
                    }
                    return response.json(); // Parse JSON content directly
                  })
                  .then(jsonContent => {
                    //console.log("Received SBIR JSON content:", jsonContent);
                    setMatrix(jsonContent); // Set the JSON content to sbirMatrix
                    setComplianceMatrixCreated(true);
                    setNextButtonActive(true);
                    setAnalysisSBIR(true);
                    setComplianceMatrixStreaming(true);


                    const subtopics = jsonContent.subtopics;
                    setComplianceMatrix(subtopics);
                    // Optionally process subtopics and save to DB or export to Excel
                    //saveExcelFileToDBImport(subtopics);
                    //handleExportToExcelWithParam(subtopics);
                  })
                  .catch(error => {
                    console.error('Fetch error for SBIR JSON file:', error.message);
                  });
              }

              else if (file.tags.includes('proposal-structure-pre_application')) {
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          //console.log("Response from fetch:", response);
                          return response.text();
                      })
                      .then(markdownContent => {
                          //console.log("Fetched markdown content:", markdownContent); 
                          //console.log("IN PRE APP status!");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${keycloakToken}`
                              },
                              body: JSON.stringify({ finalizedProposalStructure: markdownContent, stage: "pre_application" }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          return response.json();
                      })
                      .then(data => {
                          // Process each section to remove the #MAIN marker using the formatToMarkdownList function
                          //console.log("Received data from backend:", data);
                          const processedStructure = data.finalizedProposalStructure.map(volume => ({
                              ...volume,
                              sections: volume.sections.map(section => formatToMarkdownList(section).join('\n')),
                          }));

                          setPreApplicationProposalStructure(processedStructure);
                          //console.log(processedStructure);
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          console.error(`Error fetching proposal structure (pre_application):`, error);
                      });
              } else if (file.tags.includes('proposal-structure-full_application')) {
                  fetch(file.downloadUrl)
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Network response was not ok: ${response.statusText}`);
                          }
                          return response.text();
                      })
                      .then(markdownContent => {
                          //console.log("IN FULL APP status!");
                          return fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
                              method: 'POST',
                              headers: {
                                  'Content-Type': 'application/json',
                                  'Authorization': `Bearer ${keycloakToken}`
                              },
                              body: JSON.stringify({ finalizedProposalStructure: markdownContent, stage: "full_application" }),
                          });
                      })
                      .then(response => {
                          if (!response.ok) {
                              throw new Error(`Error finalizing proposal structure: ${response.statusText}`);
                          }
                          return response.json();
                      })
                      .then(data => {
                          // Process each section to remove the #MAIN marker using the formatToMarkdownList function
                          const processedStructure = data.finalizedProposalStructure.map(volume => ({
                              ...volume,
                              sections: volume.sections.map(section => formatToMarkdownList(section).join('\n')),
                          }));

                          setFullApplicationProposalStructure(processedStructure);
                          //console.log(processedStructure);
                          setProposalStructureGenerated(true);
                          setNextButtonActive(true);
                          setProposalStructureStreaming(true);
                      })
                      .catch(error => {
                          console.error(`Error fetching proposal structure (full_application):`, error);
                      });
              }
              else if (file.tags && file.tags.includes('reference-files')) {
                //console.log(`Fetching reference file from URL: ${file.downloadUrl}`); // Log the URL being fetched
                
                fetch(file.downloadUrl)
                  .then(response => {
                    if (!response.ok) {
                      throw new Error(`Network response was not ok: ${response.statusText}`);
                    }
                    //console.log("Reference file fetched successfully."); // Log successful fetch
                    return response.text(); // Assuming the reference file is in Markdown format
                  })
                  .then(markdownContent => {
                    //console.log("Received Markdown content:", markdownContent); // Log the raw Markdown content received
              
                    // Parse the Markdown content to extract file names
                    const referenceFileNames = markdownContent.split('\n').map(line => line.replace(/^- /, '').trim()).filter(Boolean);
                    //console.log("Extracted Reference File Names:", referenceFileNames);
              
                    // Ensure referenceFileNames is an array
                    if (!Array.isArray(referenceFileNames) || referenceFileNames.length === 0) {
                      console.warn("Reference file names are not available or not in the correct format.");
                      return; // Exit early if reference file names are not valid
                    }

                    // Log the contents of referenceFileNames and products for debugging
                    //console.log("Reference File Names:", referenceFileNames);
                    //console.log("Products Structure:", products);

                    // Check for matches against files in the database
                    const matchedFiles = referenceFileNames.filter(refFileName => {
                      // Log the current reference file name being checked
                      //console.log(`Checking for reference file: ${refFileName}`);

                      const matchFound = Object.entries(products).some(([productName, files]) => {
                        // Check if files is an array before proceeding
                        if (Array.isArray(files)) {
                          const found = files.some(dbFile => dbFile.name === refFileName);
                          if (found) {
                            //console.log(`Matched Reference File: ${refFileName} in Product: ${productName}`); // Log matched file names
                          } else {
                            //console.log(`No match found for: ${refFileName} in Product: ${productName}`); // Log if no match is found
                          }
                          return found; // Return whether a match was found
                        } else {
                          console.warn(`Product ${productName} does not have a valid 'files' array.`); // Warn if files is not an array
                          return false; // If files is not an array, return false
                        }
                      });
                    
                      return matchFound; // Return whether a match was found
                    });

                    // Log the matched files
                    //console.log("Matched Files:", matchedFiles);



              
                    //console.log("All Matched Reference Files:", matchedFiles); // Log all matched files
                    
                    // Set selected reference files based on matches
                    const selectedRefFiles = {};
                    matchedFiles.forEach(fileName => {
                      selectedRefFiles[fileName] = true; // Mark as selected
                    });
                    //console.log("Final Selected Reference Files:", selectedRefFiles); // Log final selected files
                    setSelectedRefFiles(selectedRefFiles); // Update state with matched files
                  })
                  .catch(error => {
                    console.error('Error fetching reference file:', error); // Log any errors during fetching
                  });
              }
              





          });
          status = status.toLowerCase();
          setTimeout(() => {
            if (status === 'done') {
              setSelectedTab('viewAnalyzedSBIR');
            } else if (status === 'in progress') {
              setSelectedTab('viewAnalyzedSBIR');
            } else if (status === 'not started') {
              setSelectedTab('viewAnalyzedSBIR');
            }
          }, 700); // 2000 milliseconds = 2 seconds

      } else {
          console.error('Error fetching file information:', fileInfoResponse.statusText);
          // Handle HTTP errors, e.g., show an error message to the user
      }
    } catch (error) {
      console.error('Error fetching file information:', error);
    }
  };

  




  const handleProductNameChange = (e) => {
    setProductName(e.target.value);
  };

  const handleProductFilesChange = (acceptedFiles) => {
    const newFiles = acceptedFiles.map(file =>
      Object.assign(file, { preview: URL.createObjectURL(file) })
    );
    setProductFiles(currentFiles => [...currentFiles, ...newFiles]);
  };


  const removeFile = (fileName) => {
    setProductFiles(currentFiles =>
      currentFiles.filter(file => file.name !== fileName)
    );
  };

  const filesList = productFiles.map(file => (
    <li key={file.name}>
      {file.name} - {file.size} bytes
      <button onClick={() => removeFile(file.name)}>Remove</button>
    </li>
  ));




  useEffect(() => {
    // Clean up the data URIs
    return () => {
      productFiles.forEach(file => URL.revokeObjectURL(file.preview));
    };
  }, [productFiles]);
  useEffect(() => {
      // Delay execution within useEffect
      const timer = setTimeout(() => {
          //console.log(preferredName);
          //console.log(userEmail);
          document.tidioIdentify = {
              email: userEmail, // Visitor's email
              name: preferredName, // Visitor's name
          };

          // Create script element for Tidio chat
          const script = document.createElement('script');
          script.src = "//code.tidio.co/dp06620e1bdrj8bk1rycsqkepzijhq9x.js";
          script.async = true;

          // Append script to the body
          document.body.appendChild(script);

          // Remove script on component unmount
          return () => {
              document.body.removeChild(script);
          };
      }, 3000); // 3000 milliseconds = 3 seconds delay

      // Cleanup function to clear the timeout if the component unmounts before the timeout is completed
      return () => {
          clearTimeout(timer);
      };
  }, [preferredName, userEmail]); // Depend on preferredName and userEmail to trigger this effect



  const handleAddProductClick = () => {
    setIsAddingProduct(true);
  };
  const handleUploadProductToDB = async () => {
    handleMoveToStep7();
    //console.log("handleUploadProductToDB");
    setIsUploadingProductToDB(true);
    const formData = new FormData();
    formData.append('productName', productName);
    formData.append('groupID', groupId);
    formData.append('companyName', companyName);
    productFiles.forEach(file => {
      formData.append('productFiles', file);
    });

    try {
      // Assuming 'keycloak' is your Keycloak instance
      const token = await kc.token; // Retrieve the Keycloak token
      //////console.log("token: " + token);

      const response = await axios.post(`${BASE_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      ////console.log(response.data);
      setUploadSuccess(true); // Set success message state
      setIsAddingProduct(false); // Hide the upload form
      setLastUpload(Date.now()); // Update the timestamp or simply increment if using a counter
      setIsUploadingProductToDB(false);
    } catch (error) {
      //console.error('Error uploading files:', error);
      setUploadSuccess(false); // Reset on error
    }
  };

  const handleAddAnotherProduct = () => {
    setProductName('');
    setProductFiles([]);
    setUploadSuccess(false);
    setIsAddingProduct(true);
  };

  const handleRemoveFile = async (productName, fileName) => {
    try {
      await axios.post(`${BASE_URL}/remove-file`, { productName, fileName, companyName, userID, groupId });

      // Remove the file from the state
      setProducts(prevProducts => {
        const updatedProducts = { ...prevProducts };
        updatedProducts[productName] = updatedProducts[productName].filter(file => file !== fileName);
        return updatedProducts;
      });

      setLastRemove(Date.now());

    } catch (error) {
      //console.error('Error removing file:', error);
    }
  };
  const handleRemoveProcessProposalFile = async (productName, fileName) => {
    try {
      await axios.post(`${BASE_URL}/govex/remove-file-process-proposal`, { productName, fileName, companyName, userID, groupId });

      // Remove the file from the state
      setProposalProcesses(prevProducts => {
        const updatedProcesses = { ...prevProducts };
        updatedProcesses[productName] = updatedProcesses[productName].filter(file => file !== fileName);
        return updatedProcesses;
      });

      setLastRemove(Date.now());

    } catch (error) {
      //console.error('Error removing file:', error);
    }
  };


  const handleUploadFileToDB = async (documentFile, volumeTag) => {
      //console.log(`Starting file upload to DB with tag: ${volumeTag}`);

      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFiles', documentFile);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      const token = kc.token;

      //console.log(`Volume Tag: ${volumeTag}`);
      //console.log(`Proposal Process Name: ${proposalProcessName}`);
      //console.log(`Document File:`, documentFile);

      //try {
      //    //console.log(`Uploading file to initial endpoint ${BASE_URL}/upload...`);
      //    const response = await axios.post(`${BASE_URL}/upload`, formData, {
      //        headers: {
      //            Authorization: `Bearer ${token}`,
      //        },
      //    });
//
      //    //console.log(`Initial file upload successful. Response:`, response.data);
      //} catch (error) {
      //    console.error('Error uploading file to initial endpoint:', error);
      //}

      const formData2 = new FormData();
      formData2.append('productName', proposalProcessName);
      formData2.append('productFiles', documentFile);
      formData2.append('tag', volumeTag);
      formData2.append('groupID', groupId);
      formData2.append('companyName', companyName);
      //console.log(`Preparing to upload file with new tag to endpoint ${BASE_URL}/govex/upload...`);
      //console.log(`FormData details:`, formData2);

      try {
          const response = await axios.post(`${BASE_URL}/govex/upload`, formData2, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //console.log(`File upload with new tag successful. Response:`, response.data);
      } catch (error) {
          console.error('Error uploading file with new tag:', error);
      }
  };





  const handleResponseFromGenerateFullOutline = (outlineMarkdown, newNumProposals, documentFileFromGFO, volumeTag, outlineHeaders) => {
    ////console.log(documentFileFromGFO);
    setOutlineHeadersGenerated(true);
    setFirstDraftStreaming(false);
    ////console.log("\n\n\n\n\n");
    //console.log("volumetag here: ", volumeTag);
    handleUploadFileToDB(documentFileFromGFO, volumeTag);
    setOutlineFirstDraftMarkdown(outlineMarkdown);
    setFirstDraftGenerated(true);
    setSelectedTab('viewFirstDraft');
    setShowAlert(false)
    setNumProposals(newNumProposals);
    setOutlineContent(outlineHeaders);

    const volumeNumber = volumeTag.split('_').pop(); // Extract the volume number part from the tag
    updateDraftVolume(volumeNumber, outlineMarkdown);

    setMatrix((prevMatrix) => {
      const updatedMatrix = { ...prevMatrix };
      if (proposalStructureStage === 'pre_application') {
        updatedMatrix.requirements.pre_application.status = 'done';
        //setPreApplicationProposalStructure(updatedMatrix);  // Update with finalized structure
      } else if (proposalStructureStage === 'full_application') {
        updatedMatrix.requirements.full_application.status = 'done';
        //setFullApplicationProposalStructure(updatedMatrix);  // Update with finalized structure
      }
      saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
      return updatedMatrix;
    });


    ////console.log("completed!");
  };

  const handleResponseFromSaveDraft = async (newDraftContent, volumeTag) => {

    ////console.log("\n\n\n\n\n");
    ////console.log("volumetag here: ", volumeTag);


    ////console.log("completed!");


  }

  const handleResponseFromGenerateComplianceMatrix = (jsonResponse, complianceMatrixAccuracyFromResponse, complianceMatrixSummaryFromResponse) => {
      // Extract subtopics from JSON response and handle them as needed
      handleMoveToStep13();
      //console.log("Received JSON response:", jsonResponse);
      setMatrix(jsonResponse);
      const subtopics = jsonResponse.subtopics;
      setComplianceMatrix(subtopics);
      setComplianceMatrixCreated(true);
      setAnalysisSBIR(true);
      setNextButtonActive(true);
      setCurrentAnalysisStep(12);
      setTimeout(() => {
        setIsScanningModal(false);
      }, 2000);
      setTimeout(() => {
        setSelectedTab('viewAnalyzedSBIR');
      }, 2000);
      
      // Optionally process subtopics and save to DB or export to Excel
      //saveExcelFileToDBImport(subtopics);
      //handleExportToExcelWithParam(subtopics);
      setComplianceMatrixSummary(complianceMatrixSummaryFromResponse);

      //console.log("Saving JSON to workflow");
      saveJsonToWorkflow(jsonResponse);
      saveReferenceFilesToS3(proposalProcessName, selectedRefFiles);
  };

  const saveJsonToWorkflow = async (jsonResponse) => {
    try {

      //console.log("JSON response to save:", JSON.stringify(jsonResponse, null, 2)); // Log the JSON response in a readable format
      //console.log("Received JSON response in savejsontoworkflow:", jsonResponse);
      //console.log("Creating blob from JSON response");
      const blob = new Blob([JSON.stringify(jsonResponse, null, 2)], { type: 'application/json' });
      const filename = `${proposalProcessName}-analysis-matrix.json`;


      //console.log("Creating form data for file upload");
      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFile', blob, filename);
      formData.append('tag', 'sbir-analysis-json');
      formData.append('userID', userID);
      formData.append('proposalName', proposalProcessName);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      try {
        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${keycloakToken}`,
          },
          body: formData,
        });

        if (!saveResponse.ok) {
          throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log("JSON file saved with 'sbir-analysis-json' tag.");
      } catch (error) {
        console.error('Error uploading files:', error);
        return; // Exit if file upload fails
      }
    } catch (error) {
      console.error('Error in processing JSON file:', error);
    }
  };

  const saveReferenceFilesToS3 = async (productName, selectedRefFiles) => {
    // Check if selectedRefFiles is an array
    //console.log("selectedRefFiles: ", selectedRefFiles);
    // Prepare the file names to save, extracting keys from the selectedRefFiles object
    const filteredRefFiles = Object.keys(selectedRefFiles).filter(fileName => selectedRefFiles[fileName]); // Keep only selected files

    if (!Array.isArray(filteredRefFiles)) {
      console.error('selectedRefFiles is not an array:', filteredRefFiles);
      return; // Exit the function if it's not an array
    }
  
    // Create Markdown content from the file names
    const markdownContent = filteredRefFiles.map(fileName => `- ${fileName}`).join('\n');
    const blob = new Blob([markdownContent], { type: 'text/markdown' });
    const file = new File([blob], `${productName}_reference_files.md`, { type: 'text/markdown' });
  
    const formData = new FormData();
    formData.append('userID', userID);
    formData.append('groupID', groupId);
    //console.log("groupID: ", groupId);
    formData.append('productName', productName);
    formData.append('proposalName', proposalProcessName);
    formData.append('companyName', companyName);
    formData.append('productFile', file);
    formData.append('tag', 'reference-files'); // Example tag
  
    try {
      const response = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${keycloakToken}`,
        },
        body: formData,
      });
  
      if (response.ok) {
        //console.log('Reference files saved successfully to S3.');
      } else {
        console.error('Error saving reference files:', response.statusText);
      }
    } catch (error) {
      console.error('Error during file upload:', error);
    }
  };
  
  

  useEffect(() => {
    //console.log("Updated proposalVolumes:", proposalVolumes);
  }, [proposalVolumes]); // Dependency array, effect runs when proposal


  useEffect(() => {
    saveReferenceFilesToS3(proposalProcessName, selectedRefFiles);
  }, [selectedRefFiles]); // This tells React to call this effect only when selectedRefFiles changes.

  const handleExportToExcelWithParam = (complianceMatrixFromResponse) => {
      const markdownTable = complianceMatrixFromResponse; // Your markdown table string

      const lines = markdownTable.trim().split('\n');
      const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());

      // Skip the markdown table's header and separator lines for data rows
      const dataRows = lines.slice(2).map(row => {
          const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
          return headers.reduce((obj, header, i) => {
              obj[header] = cells[i];
              return obj;
          }, {});
      });

      // Initialize an empty worksheet
      const ws = XLSX.utils.json_to_sheet([]);

      // Adding the title and ensuring it spans the correct range
      XLSX.utils.sheet_add_json(ws, [{"Compliance Matrix": "A"}], {origin: "A1"});
      ws["!merges"] = [{s: {r: 0, c: 0}, e: {r: 0, c: headers.length - 1}}]; // Ensure merge spans correct columns

      // Manually setting headers in the next row (Excel row 2)
      const headerRow = XLSX.utils.encode_row(1); // Excel rows are 1-indexed
      headers.forEach((header, i) => {
          const cellRef = XLSX.utils.encode_cell({r: 1, c: i});
          ws[cellRef] = {t: 's', v: header};
      });

      // Adding data rows, starting below the headers
      XLSX.utils.sheet_add_json(ws, dataRows, {origin: "A2"});

      // Set column widths as needed
      ws['!cols'] = [{wch: 10}, {wch: 100}, {wch: 50}, {wch: 20}, {wch: 12}];
      // After adding data to the worksheet (ws)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

      const excelFileName = proposalProcessName + "-ComplianceMatrix.xlsx";
      XLSX.writeFile(wb, excelFileName);
  };
  const updateApplicationStatus = (stage, status) => {
      setMatrix((prevMatrix) => {
        const updatedMatrix = { ...prevMatrix };
        if (stage === 'pre_application') {
          updatedMatrix.requirements.pre_application.status = status;
        } else if (stage === 'full_application') {
          updatedMatrix.requirements.full_application.status = status;
        }
        return updatedMatrix;
      });
    };

  const removeFilesWithSpecificTags = async (stage) => {
      try {
          const formData = new FormData();
          formData.append('tags', `${stage}_draft_volume`);
          formData.append('userID', userID);
          formData.append('proposalName', proposalProcessName);
          formData.append('groupID', groupId);
          formData.append('companyName', companyName);
          //console.log(`Fetching files with tags starting with: ${stage}_draft_volume`);

          // Fetch files that match the tags
          const fetchResponse = await fetch(`${BASE_URL}/govex/fetch-file-info`, {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${kc.token}`,
              },
              body: formData,
          });

          if (!fetchResponse.ok) {
              throw new Error(`Failed to fetch files with tags starting with: ${stage}_draft_volume`);
          }

          const files = await fetchResponse.json();

          if (files.length === 0) {
              //console.log('No files found with the specified tags.');
              return;
          }

          //console.log(`Found ${files.length} files to remove.`);

          // Iterate over each file, fetch it, and call the remove endpoint
          for (const file of files) {
              // Filter files to strictly match `stage_draft_volume_*` or `stage_draft_volume_*_outline`
              const hasCorrectTag = file.tags.some(tag =>
                  new RegExp(`^${stage}_draft_volume_\\w+$`).test(tag) ||
                  new RegExp(`^${stage}_draft_volume_\\w+_outline$`).test(tag)
              );

              if (!hasCorrectTag) {
                  //console.log(`Skipping file with filename: ${file.filename}, as it does not match the exact tag pattern.`);
                  continue;
              }

              //console.log(`Removing file: ${file.filename}`);

              // Fetch the file to ensure it exists
              const fileResponse = await fetch(file.downloadUrl);

              if (!fileResponse.ok) {
                  console.error(`Failed to fetch the file for removal: ${file.filename}`);
                  continue;
              }

              //console.log(`File fetched successfully: ${file.filename}`);

              // Proceed to remove the file from S3
              const removeResponse = await fetch(`${BASE_URL}/govex/remove-file-process-proposal`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                      Authorization: `Bearer ${kc.token}`,
                  },
                  body: JSON.stringify({
                      companyName: companyName,
                      productName: proposalProcessName,
                      fileName: file.filename,
                      userID: userID,
                      groupId: groupId
                  }),
              });

              if (!removeResponse.ok) {
                  console.error(`Failed to remove file: ${file.filename}`);
              } else {
                  //console.log(`File removed successfully: ${file.filename}`);
              }
          }

      } catch (error) {
          console.error('Error removing files with tags:', error);
      }
  };


    const handleResponseFromGenerateNEWProposalStructure = async (finalizedProposalStructureArray, stage) => {
        //console.log("Received finalized proposal structure:", finalizedProposalStructureArray);
        

        // Format each section with formatToMarkdownList
        const formattedProposalStructureArray = finalizedProposalStructureArray.map(volume => {
            const formattedSections = volume.sections.map(section => formatToMarkdownList(section));
            return {
                ...volume,
                sections: formattedSections.flat(), // Flatten in case formatToMarkdownList returns arrays
            };
        });

        //console.log("Formatted proposal structure array:", formattedProposalStructureArray);

        await removeFilesWithSpecificTags(stage);
        setDraftVolumes({});
        setProposalVolumes(formattedProposalStructureArray);
        //console.log("Current proposalVolumes:", formattedProposalStructureArray);
        setProposalStructureStage(stage);
        setProposalStructureGenerated(true);
        setNextButtonActive(true);

        const markdownStringProposalStructure = convertToMarkdown(formattedProposalStructureArray);
        //console.log("Markdown string for proposal structure:", markdownStringProposalStructure);
        downloadAndSaveMarkdownAsDocxSBIR(markdownStringProposalStructure, stage);

        // Update the status of the appropriate stage to "in progress"
        setMatrix((prevMatrix) => {
            const updatedMatrix = { ...prevMatrix };
            if (stage === 'pre_application') {
                updatedMatrix.requirements.pre_application.status = 'in progress';
                setPreApplicationProposalStructure(formattedProposalStructureArray);
            } else if (stage === 'full_application') {
                updatedMatrix.requirements.full_application.status = 'in progress';
                setFullApplicationProposalStructure(formattedProposalStructureArray);
            }
            saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
            return updatedMatrix;
        });

        setCurrentStructureStep(12);
        

        setTimeout(() => {
          setIsGeneratingStructure(false);
        }, 2000);

        setTimeout(() => {
          setSelectedTab('viewProposalStructure');
        }, 2000);
    };



  const handleResponseFromGenerateProposalStructure = (finalizedProposalStructureArray, stage) => {
      //console.log("Received finalized proposal structure:", finalizedProposalStructureArray);
      handleMoveToStep22();

      // Ensure each section of each volume is properly formatted
      const formattedProposalStructureArray = finalizedProposalStructureArray.map(volume => {
          const formattedSections = volume.sections.map(section => {
              const formattedSection = formatToMarkdownList(section);
              return formattedSection;
          });

          return {
              ...volume,
              sections: formattedSections.flat(), // Ensure we keep sections properly formatted
          };
      });

      //console.log("Formatted proposal structure array:", formattedProposalStructureArray);

      // Update the proposal volumes state with the formatted structure
      setProposalVolumes(formattedProposalStructureArray);
      //console.log("Current proposalVolumes:", formattedProposalStructureArray);

      // Set the stage and other states
      setProposalStructureStage(stage);
      setProposalStructureGenerated(true);
      setNextButtonActive(true);

      // Convert to markdown string for saving or further processing
      const markdownStringProposalStructure = convertToMarkdown(formattedProposalStructureArray);
      //console.log("Markdown string for proposal structure:", markdownStringProposalStructure);

      downloadAndSaveMarkdownAsDocxSBIR(markdownStringProposalStructure, stage);

      


      setMatrix((prevMatrix) => {
        // Create a shallow copy of the previous matrix to ensure immutability
        const updatedMatrix = { ...prevMatrix };
    
        // Ensure the `requirements` object exists
        if (!updatedMatrix.requirements) {
            updatedMatrix.requirements = {};
        }
    
        // Ensure `full_application` exists within `requirements`
        if (!updatedMatrix.requirements.full_application) {
            updatedMatrix.requirements.full_application = {};
        }
    
        // Ensure `status` exists within `full_application`
        if (!updatedMatrix.requirements.full_application.status) {
            updatedMatrix.requirements.full_application.status = 'not started'; // Default value
        }
    
        if (stage === 'pre_application') {
            if (!updatedMatrix.requirements.pre_application) {
                updatedMatrix.requirements.pre_application = { status: 'not started' }; // Default value
            }
            if (updatedMatrix.requirements.pre_application.status !== 'done') {
                updatedMatrix.requirements.pre_application.status = 'in progress';
            }
            setPreApplicationProposalStructure(formattedProposalStructureArray);
        } else if (stage === 'full_application') {
            if (updatedMatrix.requirements.full_application.status !== 'done') {
                updatedMatrix.requirements.full_application.status = 'in progress';
            }
            setFullApplicationProposalStructure(formattedProposalStructureArray);
        }
    
        // Save the updated matrix to the database
        saveMatrixToDatabase(updatedMatrix);
    
        // Return the updated matrix
        return updatedMatrix;
    });
    
      
      setCurrentStructureStep(12);
      

      setTimeout(() => {
        setIsGeneratingStructure(false);
      }, 2000);

      setTimeout(() => {
        setSelectedTab('viewProposalStructure');
      }, 2000);
  };


  const updateProposalStructure = (finalizedProposalStructureArray, stage) => {
        //console.log(finalizedProposalStructureArray);
        setProposalVolumes(finalizedProposalStructureArray);
        //console.log("Current proposalVolumes:", proposalVolumes);
        setProposalStructureStage(stage);
        //console.log(stage);
        setProposalStructureGenerated(true);
        setNextButtonActive(true);
        const markdownStringProposalStructure = convertToMarkdown(finalizedProposalStructureArray);
        //console.log(markdownStringProposalStructure);
        downloadAndSaveMarkdownAsDocxSBIR(markdownStringProposalStructure, stage);

        // Update the status of the appropriate stage to "in progress" if not already "done"
        setMatrix((prevMatrix) => {
          const updatedMatrix = { ...prevMatrix };
          if (stage === 'pre_application') {
            if (updatedMatrix.requirements.pre_application.status !== 'done') {
              updatedMatrix.requirements.pre_application.status = 'in progress';
            }
            setPreApplicationProposalStructure(finalizedProposalStructureArray);
          } else if (stage === 'full_application') {
            if (updatedMatrix.requirements.full_application.status !== 'done') {
              updatedMatrix.requirements.full_application.status = 'in progress';
            }
            setFullApplicationProposalStructure(finalizedProposalStructureArray);
          }
          saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
          return updatedMatrix;
        });

        setSelectedTab('viewProposalStructure'); // Update UI to display the proposal structure
    };






    const handleResponseFromEditOutline = async (newOutlineContent, volumeTag, stage) => {
      console.log("Received new Outline content:", newOutlineContent);
      //console.log("Type of newOutlineContent:", typeof newOutlineContent);
      //console.log("Current proposalVolumes:", proposalVolumes);
      //console.log("Current volumeTag being updated:", volumeTag);
  
      setOutlineContent(newOutlineContent);
  
      // Adjust the volumeTag by removing '_outline' for the lookup
      const adjustedVolumeTag = volumeTag.replace('_outline', '');
      //console.log("Adjusted volumeTag for lookup:", adjustedVolumeTag);
  
      // Find the volume by the adjusted tag
      const volumeIndex = proposalVolumes.findIndex(volume => volume.tag === adjustedVolumeTag);
      if (volumeIndex !== -1) {
          //console.log(`Found volume at index ${volumeIndex}, proceeding to update...`);
  
          // Log the current state of the volume before making changes
          //console.log(`Volume ${volumeIndex} before new content:`, JSON.stringify(proposalVolumes[volumeIndex], null, 2));
  
          // Update the AiOutlinePromptMessage for the specific volume if necessary
          const updatedVolume = {
              ...proposalVolumes[volumeIndex],
              sections: newOutlineContent, // Set the new outline content
          };
  
          //console.log("Updated AiOutlinePromptMessage:", updatedVolume.AiOutlinePromptMessage);
          setAiOutlinePromptMessage(updatedVolume.AiOutlinePromptMessage);
  
          // Combine the volume title with the new outline content for backend processing
          const combinedContent = `# ${updatedVolume.title}\n\n${newOutlineContent}`;
          //console.log("Combined content to send to backend:", combinedContent);
  
          // Send the combined content and updated AiOutlinePromptMessage directly to the backend
          try {
              //console.log("IN handleResponseFromEditOutline!");
              const finalizeResponse = await fetch(`${BASE_URL}/govex/finalize-proposal-structure2`, {
                  method: 'POST',
                  headers: {
                      'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                      finalizedProposalStructure: combinedContent, // Send the combined content
                      stage,
                      startingIndex: volumeIndex + 1,
                      AiOutlinePromptMessage: updatedVolume.AiOutlinePromptMessage  // Pass the section prompts from the updated volume
                  }),
              });
  
              if (!finalizeResponse.ok) {
                  throw new Error(`Finalize response was not ok: ${finalizeResponse.statusText}`);
              }
  
              const finalizeData = await finalizeResponse.json();
              //console.log("Response from backend:", finalizeData);
  
              // Update only the specific volume that was edited with the response from the backend
              const backendUpdatedVolume = finalizeData.finalizedProposalStructure[0]; // Assuming backend returns a single volume
  
              //console.log("Processing sections with formatToMarkdownList...");
  
              // Process sections and format them to Markdown
              backendUpdatedVolume.sections = backendUpdatedVolume.sections.map((section, sectionIndex) => {
                  //console.log(`Processing section ${sectionIndex}: ${section}`);
                  const formattedSection = formatToMarkdownList(section, backendUpdatedVolume.AiOutlinePromptMessage);
                  //console.log(`Formatted section ${sectionIndex}: ${formattedSection.join('\n')}`);
                  return formattedSection.join('\n');
              });
  
              // Replace the updated volume in the proposalVolumes array
              const updatedProposalVolumes = [...proposalVolumes];
              updatedProposalVolumes[volumeIndex] = backendUpdatedVolume;
  
              //console.log("Updating proposalVolumes with the updated volume...");
              setProposalVolumes(updatedProposalVolumes);
  
              //console.log("Volume after formatting:", JSON.stringify(backendUpdatedVolume, null, 2));
  
              // Convert the proposal structure content for download, removing any #MAIN markers
              let finalPropStruct = convertToMarkdown(updatedProposalVolumes);
  
              // Remove the #MAIN markers from the final structure
              finalPropStruct = finalPropStruct.replace(/#MAIN /g, '');
  
              //console.log("Final proposal structure for download:", finalPropStruct);
  
              downloadAndSaveMarkdownAsDocxSBIR(finalPropStruct, stage);
  
          } catch (error) {
              console.error("Error finalizing proposal structure:", error);
          }
      } else {
          console.error("Could not find the volume to update. Check the volumeTag and proposalVolumes state.");
      }
  };
  

  function formatToMarkdownList(text, aiOutlinePrompts = []) {
    // Split the text by new lines to handle each line separately
    const lines = text.split('\n');

    // Initialize an array to store the sections
    const sections = [];

    // Temporary storage for the current section
    let currentSection = '';
    let sectionIndex = 0; // Keep track of section index for AI prompts

    //console.log("Starting to format the markdown list...");

    // Format each line
    lines.forEach((line, index) => {
        const trimmedLine = line.trim();
        const isMainSection = trimmedLine.startsWith("#MAIN");

        //console.log(`Processing line ${index + 1}: "${line}" (trimmed: "${trimmedLine}")`);

        if (isMainSection) {
            // Remove the #MAIN marker before adding to the section
            const mainSectionText = trimmedLine.replace("#MAIN", "").trim();
            //console.log(`Detected main section: "${mainSectionText}"`);

            // If there's a current section being built, push it to sections
            if (currentSection.length > 0) {
                //console.log(`Adding section: "${currentSection}"`);

                // Append AI prompt if it exists for the current section index
                if (sectionIndex < aiOutlinePrompts.length && aiOutlinePrompts[sectionIndex]) {
                    currentSection += `\n\n**AI Prompt for this section:** ${aiOutlinePrompts[sectionIndex]}\n`;
                    //console.log(`Added AI prompt for section ${sectionIndex + 1}: ${aiOutlinePrompts[sectionIndex]}`);
                }

                sections.push(currentSection);
            }

            // Start a new section
            currentSection = `${mainSectionText}`;
            sectionIndex++; // Increment section index for AI prompts
        }
        // Check if the line is a subsection (indicated by consistent indentation)
        else if (line.startsWith("    ") || line.startsWith("\t")) {
            //console.log(`Detected subsection: "${trimmedLine}"`);
            currentSection += `\n    ${trimmedLine}`;
        } else {
            //console.log(`Unrecognized line format or potential issue: "${line}"`);
        }
    });

    // Push the last section if it exists
    if (currentSection.length > 0) {
        //console.log(`Adding final section: "${currentSection}"`);

        // Append AI prompt for the last section if applicable
        if (sectionIndex < aiOutlinePrompts.length && aiOutlinePrompts[sectionIndex]) {
            currentSection += `\n\n**AI Prompt for this section:** ${aiOutlinePrompts[sectionIndex]}\n`;
            //console.log(`Added AI prompt for final section ${sectionIndex + 1}: ${aiOutlinePrompts[sectionIndex]}`);
        }

        sections.push(currentSection);
    }

    //console.log("Final sections array:", sections);
    return sections; // Return the array of sections
}

  






  function formatEditedOutline(text) {
      // Split the text by new lines to handle each line separately
      const lines = text.split('\n');

      // Initialize an array to store the sections
      const sections = [];

      // Temporary storage for the current section
      let currentSection = '';

      //console.log("Starting to format the markdown list...");

      // Format each line
      lines.forEach((line, index) => {
          const trimmedLine = line.trim();
          const isMainSection = /^\d+\.\s/.test(trimmedLine); // Detect main sections by checking for a number followed by a period
          const isSubsection = /^\s*\d+\.\s/.test(trimmedLine); // Detect subsections by checking for indentation and a number followed by a period

          //console.log(`Processing line ${index + 1}: "${line}" (trimmed: "${trimmedLine}")`);

          if (isMainSection) {
              // If there's a current section being built, push it to sections
              if (currentSection.length > 0) {
                  //console.log(`Adding section: "${currentSection}"`);
                  sections.push(currentSection);
              }
              // Start a new section with the main section header
              currentSection = trimmedLine;
          }
          // Check if the line is a subsection
          else if (isSubsection) {
              //console.log(`Detected subsection: "${trimmedLine}"`);
              currentSection += `\n    ${trimmedLine}`; // Add indentation and append to the current section
          } else {
              // This case should handle any unrecognized format or empty lines
              //console.log(`Unrecognized line format or potential issue: "${line}"`);
          }

          // If this is the last line, push the current section to sections
          if (index === lines.length - 1 && currentSection.length > 0) {
              //console.log(`Adding final section: "${currentSection}"`);
              sections.push(currentSection);
          }
      });

      //console.log("Final sections array:", sections);
      return sections; // Return the array of sections
  }






  const convertToMarkdownPropStruct = (finalizedProposalStructureArray) => {
      let markdownString = "";

      finalizedProposalStructureArray.forEach(volume => {
          markdownString += `# ${volume.title}\n\n`;

          volume.sections.forEach(section => {
              markdownString += `${section}\n`;
          });

          markdownString += '\n';
      });

      return markdownString;
  };







  const convertToMarkdown = (finalizedProposalStructureArray) => {
    let markdownString = "";
  
    finalizedProposalStructureArray.forEach(volume => {
      // Add volume title as a heading
      markdownString += `# ${volume.title}\n\n`;
  
      // Add each section within the volume
      volume.sections.forEach((section, index) => {
        // Add the section to the markdown
        markdownString += `${section}\n`;
  
        // Add the AI Prompt Message for this section if it exists
        if (volume.AiOutlinePromptMessage && volume.AiOutlinePromptMessage[index]) {
          const aiPrompt = volume.AiOutlinePromptMessage[index];
          markdownString += `\n**AI Prompt for Section ${index + 1}:** ${aiPrompt}\n\n`;
          //console.log("aiPrompt!!!!!!!!!!!!!!!!!!!!!!!: ", markdownString);
        }
      });
  
      // Add a newline for spacing between volumes
      markdownString += '\n';
    });
  
    return markdownString;
  };
  const downloadAndSaveMarkdownAsDocx = async (markdownString) => {
    try {
      const blob = new Blob([markdownString], { type: 'text/markdown' });
      const filename = `${proposalProcessName}-proposal-structure.md`;

      // Create a URL for the file
      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFile', blob, filename);
      formData.append('tag', 'proposal-structure');
      formData.append('userID', userID);
      formData.append('proposalName', proposalProcessName)
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      try {
            const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${keycloakToken}`,
                },
                body: formData,
            });

            if (!saveResponse.ok) {
                throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
            }

            ////console.log("Markdown file saved with 'proposal-structure' tag, continuing.");
        } catch (error) {
            //console.error('Error uploading files:', error);
            return; // Exit if file upload fails
        }

        // Directly download the markdown file without conversion
        //const downloadUrl = window.URL.createObjectURL(blob);
        //const link = document.createElement('a');
        //link.href = downloadUrl;
        //link.setAttribute('download', filename); // Set the downloaded file name
        //document.body.appendChild(link);
        //link.click();
        //link.remove(); // Clean up after downloading
      } catch (error) {
          //console.error('Error in processing markdown file:', error);
      }
  };

  const downloadAndSaveMarkdownAsDocxSBIR = async (markdownString, stage) => {
    try {
      const blob = new Blob([markdownString], { type: 'text/markdown' });
      const filename = `${proposalProcessName}-${stage}-proposal-structure.md`;

      // Create a URL for the file
      const formData = new FormData();
      formData.append('productName', proposalProcessName);
      formData.append('productFile', blob, filename);
      formData.append('tag', `proposal-structure-${stage}`);
      formData.append('userID', userID);
      formData.append('proposalName', proposalProcessName);
      formData.append('groupID', groupId);
      formData.append('companyName', companyName);
      try {
        const saveResponse = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${keycloakToken}`,
          },
          body: formData,
        });

        if (!saveResponse.ok) {
          throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        //console.log(`Markdown file saved with 'proposal-structure-${stage}' tag.`);
      } catch (error) {
        console.error('Error uploading files:', error);
        return; // Exit if file upload fails
      }
    } catch (error) {
      console.error('Error in processing markdown file:', error);
    }
  };









  const removeSelectedFile = () => {
    setSelectedFile(null); // Clear the selected file
  };

  if (isPageLoading) {
    return <div className="loading">Loading...</div>; // Or any other loading indicator
  }

  if (!isAuthenticated) {
    // Potentially redundant, as the user should be redirected by Keycloak if not authenticated
    return <div className="loading">Redirecting to login...</div>;
  }

  const currentStepIndex = steps.findIndex(step => step.key === selectedTab);

  const goToNextStep = () => {
    handleMoveToStep9();
    if (currentStepIndex < steps.length - 1) {
      setSelectedTab(steps[currentStepIndex + 1].key);
    }
  };
  const goToNextStepOutlineToDraft = () => {
    setSelectedTab("viewFirstDraft");
  };

  const goToPreviousStep = () => {
    if (currentStepIndex > 0) {
      setSelectedTab(steps[currentStepIndex - 1].key);
    }
  };
  const saveExcelFileToDB = async () => {
    ////console.log("in savetodexcel: ", complianceMatrix);
    const markdownTable = complianceMatrix; // Assuming complianceMatrix contains your markdown table string
    const lines = markdownTable.trim().split('\n');
    const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());
    const dataRows = lines.slice(2);

    // Parse rows into objects
    const dataObjects = dataRows.map(row => {
      const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
      return cells.reduce((obj, cell, index) => {
        obj[headers[index]] = cell;
        return obj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(dataObjects);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

    // Instead of writing to file, write to binary string and convert to Blob
    const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
    let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF; //convert to octet

    // Create Blob from ArrayBuffer
    const blob = new Blob([buf], {type:'application/octet-stream'});


    const excelFileName = `${proposalProcessName}-ComplianceMatrix.xlsx`;
    const file = new File([blob], excelFileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

    // Store the File object in the state
    setExcelFileSelected(file);
    const formData = new FormData();
    formData.append('productFile', file);
    formData.append('productName', proposalProcessName);
    ////console.log("selected file - ", file);

    // Include any additional data as needed
    formData.append('tag', 'matrix');
    formData.append('userID', userID);
    ////console.log("keycloak token: ", keycloakToken);
    ////console.log("proposal process name: ", proposalProcessName);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', file);
    formData.append('groupID', groupId);


    try {

        const saveResponse =  await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method:'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        ////console.log("File saved with 'matrix' tag, continuing.");

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
    setComplianceMatrixStreaming(true);
  };
  const saveExcelFileToDBImport = async (markdownStringImport) => {
    ////console.log("in savetodexcel: ", markdownStringImport);
    const markdownTable = markdownStringImport; // Assuming complianceMatrix contains your markdown table string
    const lines = markdownTable.trim().split('\n');
    const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());
    const dataRows = lines.slice(2);

    // Parse rows into objects
    const dataObjects = dataRows.map(row => {
      const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
      return cells.reduce((obj, cell, index) => {
        obj[headers[index]] = cell;
        return obj;
      }, {});
    });

    const ws = XLSX.utils.json_to_sheet(dataObjects);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

    // Instead of writing to file, write to binary string and convert to Blob
    const wbout = XLSX.write(wb, {bookType:'xlsx', type:'binary'});
    let buf = new ArrayBuffer(wbout.length); //convert s to arrayBuffer
    let view = new Uint8Array(buf);  //create uint8array as viewer
    for (let i=0; i<wbout.length; i++) view[i] = wbout.charCodeAt(i) & 0xFF; //convert to octet

    // Create Blob from ArrayBuffer
    const blob = new Blob([buf], {type:'application/octet-stream'});


    const excelFileName = `${proposalProcessName}-ComplianceMatrix.xlsx`;
    const file = new File([blob], excelFileName, {type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});

    // Store the File object in the state
    setExcelFileSelected(file);
    const formData = new FormData();
    formData.append('productFile', file);
    formData.append('productName', proposalProcessName);
    ////console.log("selected file - ", file);

    // Include any additional data as needed
    formData.append('tag', 'matrix');
    formData.append('userID', userID);
    ////console.log("keycloak token: ", keycloakToken);
    ////console.log("proposal process name: ", proposalProcessName);
    formData.append('proposalName', proposalProcessName)
    formData.append('file', file);
    formData.append('groupID', groupId);
    formData.append('companyName', companyName);

    try {

        const saveResponse =  await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
            method:'POST',
            headers: {
                Authorization: `Bearer ${keycloakToken}`,
            },
            body: formData,
        });

        if (!saveResponse.ok) {
            throw new Error(`Error saving file with tag: ${saveResponse.statusText}`);
        }

        ////console.log("File saved with 'matrix' tag, continuing.");

    } catch (error) {
      //console.error('Error uploading files:', error);
    }

    setIsProcessing(false);
    setComplianceMatrixStreaming(true);
  };


  const handleExportToExcel = () => {
      const markdownTable = complianceMatrix;
      const lines = markdownTable.trim().split('\n');
      const headers = lines[0].split('|').slice(1, -1).map(header => header.trim());

      // Skip the markdown table's header and separator lines for data rows
      const dataRows = lines.slice(2).map(row => {
          const cells = row.split('|').slice(1, -1).map(cell => cell.trim());
          return headers.reduce((obj, header, i) => {
              obj[header] = cells[i];
              return obj;
          }, {});
      });

      // Initialize an empty worksheet
      const ws = XLSX.utils.json_to_sheet([]);

      // Adding the title and ensuring it spans the correct range
      XLSX.utils.sheet_add_json(ws, [{"Compliance Matrix": "A"}], {origin: "A1"});
      ws["!merges"] = [{s: {r: 0, c: 0}, e: {r: 0, c: headers.length - 1}}]; // Ensure merge spans correct columns

      // Manually setting headers in the next row (Excel row 2)
      const headerRow = XLSX.utils.encode_row(1); // Excel rows are 1-indexed
      headers.forEach((header, i) => {
          const cellRef = XLSX.utils.encode_cell({r: 1, c: i});
          ws[cellRef] = {t: 's', v: header};
      });

      // Adding data rows, starting below the headers
      XLSX.utils.sheet_add_json(ws, dataRows, {origin: "A2"});

      // Set column widths as needed
      ws['!cols'] = [{wch: 10}, {wch: 100}, {wch: 50}, {wch: 20}, {wch: 12}];
      // After adding data to the worksheet (ws)

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "ComplianceMatrix");

      const excelFileName = proposalProcessName + "-ComplianceMatrix.xlsx";
      XLSX.writeFile(wb, excelFileName);
  };


  const processExcelBlobToMarkdown = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (evt) => {
        try {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: 'binary' });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

          let markdownString = `| RFP Reference | Requirement Detail | Compliance Method | Requirement Category | Status/Action Needed |\n`;
          markdownString += `|---------------|--------------------|-------------------|----------------------|----------------------|\n`;

          // Assuming data[0] contains headers and the rest are data rows
          data.slice(1).forEach(row => {
            markdownString += `| ${row.join(" | ")} |\n`;
          });

          resolve(markdownString);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (error) => reject(error);

      reader.readAsBinaryString(blob);
      //saveExcelFileToDB();
    });
  };
  const handleExcelFileChange = (e) => {
    const file = e.target.files[0]; // Get the file
    const reader = new FileReader();

    reader.onload = (evt) => {
      // evt.target.result contains file content
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // Convert array of arrays
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
      ////console.log(data); // data is now in a readable array format

      // Process the data...
      processExcelData(data);
    };

    reader.readAsBinaryString(file);
  };


  const processExcelData = (data) => {
    // Skip the header row and transform the rest into objects
    const items = data.slice(2).map(row => {
      return {
        rfpReference: row[0],
        requirementDetail: row[1],
        complianceMethod: row[2],
        requirementCategory: row[3],
        statusActionNeeded: row[4]
      };
    });

    let markdownString = `| RFP Reference | Requirement Detail | Compliance Method | Requirement Category | Status/Action Needed |\n`;
    markdownString += `|---------------|--------------------|-------------------|----------------------|----------------------|\n`;

    items.forEach(item => {
      markdownString += `| ${item.rfpReference} | ${item.requirementDetail} | ${item.complianceMethod} | ${item.requirementCategory} | ${item.statusActionNeeded} |\n`;
    });

    // Here, you could update your component's state with this markdown string,
    // or perform further actions with it
    ////console.log(markdownString);

    setComplianceMatrix(markdownString);
    setComplianceMatrixStreamed(markdownString);
    setComplianceMatrixCreated(true);
    saveExcelFileToDBImport(markdownString);
  };
  function toRoman(num) {
    const lookup = {M:1000,CM:900,D:500,CD:400,C:100,XC:90,L:50,XL:40,X:10,IX:9,V:5,IV:4,I:1};
    let roman = '';
    for (let i in lookup ) {
      while ( num >= lookup[i] ) {
        roman += i;
        num -= lookup[i];
      }
    }
    return roman;
  }
  const handleViewEditDraftClick = (volumeIndex, volumeTag) => {
      // Log the received volume index and tag
      //console.log(`View & Edit Draft clicked for volume index: ${volumeIndex}, volume tag: ${volumeTag}`);

      // Use the full volume tag to get the selected volume content
      const selectedVolumeContent = draftVolumes[volumeTag];
      //console.log(`Selected volume content for ${volumeTag}:`, selectedVolumeContent);

      setOutlineHeadersGenerated(true); // Assuming this flag is necessary for your application logic

      if (selectedVolumeContent) {
        setOutlineFirstDraftMarkdown(selectedVolumeContent);
        setFirstDraftGenerated(true);
        const selectedVolumeTitle = proposalVolumes[volumeIndex].title;
        setOutlineTitle(selectedVolumeTitle);
        const selectedVolumeOutlineContent = proposalVolumes[volumeIndex].sections.join('\n\n');
        setOutlineContent(selectedVolumeOutlineContent); // Update the outline content with the selected volume's content
        setStreamedContent(selectedVolumeContent);
        setTagForDraft(volumeTag);
        setSelectedTab("viewFirstDraft");
      } else {
        //console.log(`No updated content found for volume index: ${volumeIndex}, volume tag: ${volumeTag}`);
      }
  };

  const updateFileTagInDB = async (oldVolumeTag, newVolumeTag, volumeTitle) => {
      const token = kc.token;

      try {
          // Step 1: Copy the file with the new tag
          const copyResponse = await axios.post(`${BASE_URL}/govex/copy-file-process-proposal`, {
              oldVolumeTag,
              newVolumeTag,
              volumeTitle,
              companyName,
              userID
          }, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //console.log(`File copied successfully with new tag: ${newVolumeTag}. Response:`, copyResponse.data);

          // Step 2: Delete the old file after copying
          const removeResponse = await axios.post(`${BASE_URL}/govex/remove-file-process-proposal`, {
              productName: proposalProcessName,
              fileName: `${volumeTitle}.docx`,
              companyName,
              userID,
              groupId
          }, {
              headers: {
                  Authorization: `Bearer ${token}`,
              },
          });

          //console.log(`Old file removed successfully with old tag: ${oldVolumeTag}. Response:`, removeResponse.data);

          // Update the local state with the new volume tag
          updateDraftVolume(newVolumeTag, draftVolumes[oldVolumeTag]);

      } catch (error) {
          console.error('Error updating file tag in DB:', error);
      }
  };

  const handleUploadFileToDBTagChange = async (documentFile, volumeTag) => {
        //console.log(`Starting file upload to DB with tag: ${volumeTag}`);

        const formData = new FormData();
        formData.append('productName', proposalProcessName);
        formData.append('productFiles', documentFile);
        const token = kc.token;

        //console.log(`Volume Tag: ${volumeTag}`);
        //console.log(`Proposal Process Name: ${proposalProcessName}`);
        //console.log(`Document File:`, documentFile);

        const formData2 = new FormData();
        formData2.append('productName', proposalProcessName);
        formData2.append('productFiles', documentFile);
        formData2.append('tag', volumeTag);
        formData2.append('groupID', groupId);
        formData2.append('companyName', companyName);
        //console.log(`Preparing to upload file with new tag to endpoint ${BASE_URL}/govex/upload...`);
        //console.log(`FormData details:`, formData2);

        try {
            const response = await axios.post(`${BASE_URL}/govex/upload`, formData2, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            //console.log(`File upload with new tag successful. Response:`, response.data);
        } catch (error) {
            console.error('Error uploading file with new tag:', error);
        }
    };

    const handleRemoveVolume = (volumeIndex, proposalStructureStage) => {
      //console.log("handleRemoveVolume - ", volumeIndex, proposalStructureStage);
      removeVolume(volumeIndex, proposalStructureStage);
      setShowModalIndex(null);
    };



  const removeVolume = (indexToRemove, stage) => {
      //console.log(`Removing volume at index: ${indexToRemove} for stage: ${proposalStructureStage}`);
      //console.log("volume index to remove: ", showModalIndex);

      setProposalVolumes((currentVolumes) => {
          //console.log('Current volumes before removal:', currentVolumes);

          // Capture existing drafts before modifying volumes
          const existingDrafts = { ...draftVolumes };
          //console.log('Captured existing drafts:', existingDrafts);

          // Remove the volume at the specified index
          let updatedVolumes = currentVolumes.filter((_, index) => index !== indexToRemove);
          //console.log('Volumes after removal but before renumbering:', updatedVolumes);

          // Create a map of old tags to new tags and ensure all drafts and outlines are correctly updated
          const tagMapping = {};
          updatedVolumes = updatedVolumes.map((volume, index) => {
              const newVolumeTag = `${stage.toLowerCase()}_draft_volume_${toRoman(index + 1)}`;
              const previousTag = volume.tag;
              tagMapping[previousTag] = newVolumeTag;
              //console.log(`Mapping old tag ${previousTag} to new tag ${newVolumeTag}`);
              return { ...volume, tag: newVolumeTag };
          });

          //console.log('Final tag mapping:', tagMapping);
          //console.log('Volumes after removal and renumbering:', updatedVolumes);

          // Update drafts and outlines based on the new tag mapping
          const updateDraftsAndOutlines = async () => {
              const updatedDrafts = {};

              for (const oldTag in existingDrafts) {
                  const newTag = tagMapping[oldTag] || oldTag;  // Default to oldTag if no mapping found
                  //console.log(`Updating draft and outline from old tag ${oldTag} to new tag ${newTag}`);

                  try {
                      // Fetch the existing draft and outline files associated with the old tag
                      const files = await fetchExistingFile(oldTag);

                      if (files.draft) {
                          //console.log(`Fetched draft file for old tag ${oldTag}, preparing to re-upload with new tag ${newTag}`);
                          await handleUploadFileToDBTagChange(files.draft, newTag);
                          //console.log(`Draft file re-uploaded with new tag: ${newTag}`);
                      }

                      if (files.outline) {
                          // Adjust the outline tag to match the new draft tag
                          const newOutlineTag = `${newTag}_outline`;
                          //console.log(`Fetched outline file for old tag ${oldTag}_outline, preparing to re-upload with new tag ${newOutlineTag}`);
                          await handleUploadFileToDBTagChange(files.outline, newOutlineTag);
                          //console.log(`Outline file re-uploaded with new tag: ${newOutlineTag}`);
                      }

                      // Update the draftVolumes state with the new tag
                      updatedDrafts[newTag] = existingDrafts[oldTag];
                  } catch (error) {
                      console.error(`Error re-uploading draft or outline with new tag: ${newTag}`, error);
                  }
              }

              // Update the draftVolumes state to trigger a re-render
              setDraftVolumes(updatedDrafts);
              //console.log('Draft volumes state updated with new tags:', updatedDrafts);
          };

          // Start updating drafts and outlines after volumes are renumbered
          updateDraftsAndOutlines();

          // Update the appropriate proposal structure variable based on the stage
          if (stage === 'pre_application') {
              setPreApplicationProposalStructure(updatedVolumes);
              //console.log("Updated pre-application proposal structure after removal:", updatedVolumes);
          } else if (proposalStructureStage === 'full_application') {
              setFullApplicationProposalStructure(updatedVolumes);
              //console.log("Updated full-application proposal structure after removal:", updatedVolumes);
          }

          updateProposalStructure(updatedVolumes, proposalStructureStage);
          return updatedVolumes;
      });
  };







  const fetchExistingFile = async (volumeTag) => {
      try {
          const formData = new FormData();
          formData.append('tags', volumeTag);
          formData.append('userID', userID);
          formData.append('proposalName', proposalProcessName);
          formData.append('groupID', groupId);
          formData.append('companyName', companyName);
          //console.log(`Fetching file info for volume tag: ${volumeTag}`);

          const response = await fetch(`${BASE_URL}/govex/fetch-file-info`, {
              method: 'POST',
              headers: {
                  Authorization: `Bearer ${kc.token}`,
              },
              body: formData,
          });

          if (!response.ok) {
              throw new Error(`Failed to fetch file info for tag: ${volumeTag}`);
          }

          const files = await response.json();

          if (files.length === 0) {
              throw new Error(`No files found for tag: ${volumeTag}`);
          }

          // Handle fetching both draft files and outline files
          const draftFiles = files.filter(file => {
              const hasOutlineTag = file.tags.some(tag => tag.includes('outline'));
              const isOutlineFileName = file.filename.toLowerCase().includes('outline');
              const exactMatchTag = file.tags.includes(volumeTag);

              // If it's a draft volume tag, include it; exclude outlines
              return exactMatchTag && !hasOutlineTag && !isOutlineFileName;
          });

          const outlineFiles = files.filter(file => {
              const isExactOutlineTag = file.tags.includes(`${volumeTag}_outline`);
              const isOutlineFileName = file.filename.toLowerCase().includes('outline');

              // If it's an outline tag or filename, include it
              return isExactOutlineTag || isOutlineFileName;
          });

          if (draftFiles.length === 0 && outlineFiles.length === 0) {
              throw new Error(`No draft or outline files found for tag: ${volumeTag}`);
          }

          if (draftFiles.length > 1) {
              console.warn(`Multiple draft files found for tag: ${volumeTag}. Using the first exact match.`);
          }

          const draftFile = draftFiles.length > 0 ? draftFiles[0] : null;
          const outlineFile = outlineFiles.length > 0 ? outlineFiles[0] : null;

          const fetchFile = async (file) => {
              const fileResponse = await fetch(file.downloadUrl);

              if (!fileResponse.ok) {
                  throw new Error(`Failed to download the file for tag: ${file.tags.join(', ')}`);
              }

              const blob = await fileResponse.blob();
              const fileName = file.filename || `${volumeTag}.docx`;
              return new File([blob], fileName, { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
          };

          const docFiles = {};

          if (draftFile) {
              //console.log(`Exact draft file info retrieved for volume tag: ${volumeTag}`);
              docFiles.draft = await fetchFile(draftFile);
              //console.log(`Successfully fetched and created File object for draft tag: ${volumeTag}`);
          }

          if (outlineFile) {
              //console.log(`Exact outline file info retrieved for volume tag: ${volumeTag}_outline`);
              docFiles.outline = await fetchFile(outlineFile);
              //console.log(`Successfully fetched and created File object for outline tag: ${volumeTag}_outline`);
          }

          return docFiles;
      } catch (error) {
          console.error('Error fetching existing file:', error);
          throw error;
      }
  };













  const handleReferenceFileChange = (fileName, isChecked) => {
    //console.log("fileName: ", fileName);
    setSelectedRefFiles(prevSelectedRefFiles => {
        const updatedSelectedRefFiles = { ...prevSelectedRefFiles };
        if (isChecked) {
            if (Object.keys(updatedSelectedRefFiles).length < 10) {
                updatedSelectedRefFiles[fileName] = true;
            }
        } else {
            delete updatedSelectedRefFiles[fileName];
        }
        return updatedSelectedRefFiles;
    });
};

const handleSelectAllForProduct = (isChecked, productName, files) => {
    setSelectedRefFiles(prevSelectedRefFiles => {
        const updatedSelectedRefFiles = { ...prevSelectedRefFiles };
        files.forEach(file => {
            if (isChecked) {
                if (Object.keys(updatedSelectedRefFiles).length < 10) {
                    updatedSelectedRefFiles[file.name] = true;
                }
            } else {
                delete updatedSelectedRefFiles[file.name];
            }
        });
        return updatedSelectedRefFiles;
    });
};


// ... existing code ...

  const saveTitleChange = (event, volumeIndex, stage) => {
      const originalTitle = proposalVolumes[volumeIndex].title; // Capture the original title for comparison
      const newTitle = event.target.innerText.trim();
      //console.log(`Original title: ${originalTitle}`); // Log the original title
      //console.log(`New title: ${newTitle}`); // Log the new, edited title

      if (originalTitle !== newTitle) { // Only update if there's a change
          const updatedVolumes = proposalVolumes.map((volume, idx) =>
              idx === volumeIndex ? { ...volume, title: newTitle } : volume
          );
          setProposalVolumes(updatedVolumes);
          //console.log(`Title updated to: ${newTitle}`); // Confirm the update action

          // Update the appropriate proposal structure variable based on the stage
          if (stage === 'pre_application') {
              setPreApplicationProposalStructure(updatedVolumes);
          } else if (stage === 'full_application') {
              setFullApplicationProposalStructure(updatedVolumes);
          }

          // Call your function to regenerate/update the proposal structure
          //console.log(stage);
          updateProposalStructure(updatedVolumes, stage);
      } else {
          //console.log("Title unchanged."); // Indicate no change was made
      }
  };

  function preprocessMarkdown(section) {
      // Split the section into lines and process each line
      return section
          .split('\n')
          .map(line => {
              if (/^\d+\.\d+\s/.test(line.trim())) {
                  // If it's a subsection, ensure it's on a new line with indentation
                  return '\n' + '    ' + line.trim();
              }
              return line.trim();
          })
          .join('\n');
  }



  const addNewVolume = (stage) => {
      //console.log(`Adding new volume for stage: ${stage}`);

      const newVolumeIndex = proposalVolumes.length + 1; // Determine the index for the new volume
      const newVolumeTag = `${stage.toLowerCase()}_draft_volume_${toRoman(newVolumeIndex)}`; // Create the tag using the Roman numeral

      const exampleSectionText = `#MAIN 1. **Introduction and Background**
          1. Subsection 1
          2. Subsection 2
      #MAIN 2. **New Section**
          1. Another Subsection
          2. Yet Another Subsection`;





      // Format the section text into an array of Markdown lists
      const formattedSections = formatToMarkdownList(exampleSectionText);

      const newVolume = {
          title: `Volume Title`, // Include the index in the title with Roman numeral
          sections: formattedSections, // Include the array of formatted sections in the new volume
          tag: newVolumeTag,
      };

      const updatedVolumes = [...proposalVolumes, newVolume];
      setProposalVolumes(updatedVolumes); // Update the state with the new list of volumes

      // Update the appropriate proposal structure variable based on the stage
      if (stage === 'pre_application') {
          setPreApplicationProposalStructure(updatedVolumes);
          //console.log("Updated pre-application proposal structure:", updatedVolumes);
      } else if (stage === 'full_application') {
          setFullApplicationProposalStructure(updatedVolumes);
          //console.log("Updated full-application proposal structure:", updatedVolumes);
      }

      // Call the function to handle the updated structure, ensuring the new volume and its section are processed
      updateProposalStructure(updatedVolumes, stage);
  };


  function formatToMarkdownList(text) {
      // Split the text by new lines to handle each line separately
      const lines = text.split('\n');

      // Initialize an array to store the sections
      const sections = [];

      // Temporary storage for the current section
      let currentSection = '';

      ////console.log("Starting to format the markdown list...");

      // Format each line
      lines.forEach((line, index) => {
          const trimmedLine = line.trim();
          const isMainSection = trimmedLine.startsWith("#MAIN");

          ////console.log(`Processing line ${index + 1}: "${line}" (trimmed: "${trimmedLine}")`);

          if (isMainSection) {
              // Remove the #MAIN marker before adding to the section
              const mainSectionText = trimmedLine.replace("#MAIN", "").trim();
              ////console.log(`Detected main section: "${mainSectionText}"`);
              // If there's a current section being built, push it to sections
              if (currentSection.length > 0) {
                  ////console.log(`Adding section: "${currentSection}"`);
                  sections.push(currentSection);
              }
              // Start a new section
              currentSection = `${mainSectionText}`;
          }
          // Check if the line is a subsection (indicated by consistent indentation)
          else if (line.startsWith("    ") || line.startsWith("\t")) {
              ////console.log(`Detected subsection: "${trimmedLine}"`);
              currentSection += `\n    ${trimmedLine}`;
          } else {
              ////console.log(`Unrecognized line format or potential issue: "${line}"`);
          }
      });

      // Push the last section if it exists
      if (currentSection.length > 0) {
          ////console.log(`Adding final section: "${currentSection}"`);
          sections.push(currentSection);
      }

      ////console.log("Final sections array:", sections);
      return sections; // Return the array of sections
  }
  const fetchFileContents = async (file) => {
    try {
      const fileUrl = file.downloadUrl;
      //console.log("Fetching file from URL:", fileUrl);
  
      const response = await fetch(fileUrl);
      //console.log("Response status:", response.status);
  
      if (!response.ok) {
        console.error('Failed to fetch file contents:', response.statusText);
        return null;
      }
  
      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") === -1) {
        console.warn("Expected JSON but received:", contentType);
  
        // Attempt to manually parse the response as JSON if the content looks like JSON
        const text = await response.text();
        try {
          const data = JSON.parse(text); // Manually parse the text as JSON
          return data;
        } catch (e) {
          console.error("Failed to parse text as JSON:", e);
          return null;
        }
      }
  
      // Normal case: handle JSON response
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching file:', error);
      return null;
    }
  };

  const handleDeleteAllFiles = async (productName) => {
    const confirmed = window.confirm("Are you sure you want to delete all files for this workflow?");
    if (!confirmed) return;
  
    try {
      const response = await fetch(`${BASE_URL}/govex/remove-all-files-process-proposal`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${keycloakToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          companyName: companyName,
          productName: productName,
          userID: userID,
          groupId: groupId,
        }),
      });
  
      if (response.ok) {
        //console.log('All files deleted successfully.');
        setLastRemove(Date.now());
        // Optionally, refresh the workflow or remove the deleted files from the state
      } else {
        console.error('Error deleting files:', response.statusText);
      }
    } catch (error) {
      console.error('Error during delete request:', error);
    }
  };
  
  
  
  
  
  

























  const toggleTopicSelection = (index) => {
    setMatrix((prevMatrix) => {
      const updatedMatrix = { ...prevMatrix };
      updatedMatrix.topics[index].selected = !updatedMatrix.topics[index].selected;
      saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
      return updatedMatrix;
    });
  };

  const toggleSubtopicSelection = (topicIndex, subtopicIndex) => {
    setMatrix((prevMatrix) => {
      const updatedMatrix = { ...prevMatrix };
      updatedMatrix.topics[topicIndex].subtopics[subtopicIndex].selected = !updatedMatrix.topics[topicIndex].subtopics[subtopicIndex].selected;
      saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
      return updatedMatrix;
    });
  };

  const toggleSubmissionRequirement = (stage, index) => {
      setMatrix((prevMatrix) => {
          const updatedMatrix = { ...prevMatrix };
          const currentRequirement = updatedMatrix.requirements[stage].submission_requirements[index];
          currentRequirement.completed = !currentRequirement.completed;  // Toggle the completed status
          saveMatrixToDatabase(updatedMatrix);  // Save the updated matrix to the database
          return updatedMatrix;
      });
  };



    const toggleSection = (section) => {
      setShowDetails((prevShowDetails) => ({
        ...prevShowDetails,
        [section]: !prevShowDetails[section]
      }));
    };

    const renderStatus = (status) => {
      //console.log("status - ", status);
      
      // Define valid statuses and their corresponding colors
      const statusColors = {
        'Not started': '#d3d3d3', // Light Gray
        'in progress': '#ffcc00', // yellow
        'done': '#28a745' // Green
      };
    
      // Check if the status is valid; if not, default to 'Not started'
      const validStatus = statusColors.hasOwnProperty(status) ? status : 'Not started';
    
      return (
        <span
          style={{
            backgroundColor: statusColors[validStatus], // Use the valid status
            color: '#fff',
            borderRadius: '4px',
            padding: '2px 8px',
            marginLeft: '10px'
          }}
        >
          {validStatus.toUpperCase()}
        </span>
      );
    };
    


    const saveMatrixToDatabase = async (updatedMatrix) => {
      try {
        const blob = new Blob([JSON.stringify(updatedMatrix, null, 2)], { type: 'application/json' });
        const filename = `${proposalProcessName}-analysis-matrix.json`;

        const formData = new FormData();
        formData.append('productName', proposalProcessName);
        formData.append('productFile', blob, filename);
        formData.append('tag', 'sbir-analysis-json');
        formData.append('userID', userID);
        formData.append('proposalName', proposalProcessName);
        formData.append('groupID', groupId);
        formData.append('companyName', companyName);
        const response = await fetch(`${BASE_URL}/govex/save-file-with-tag`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${keycloakToken}`,
          },
          body: formData,
        });

        if (!response.ok) {
          throw new Error(`Error saving matrix: ${response.statusText}`);
        }

        //console.log("Matrix saved to database");
      } catch (error) {
        console.error('Error saving matrix to database:', error);
      }
    };

    const handleTopicChange = (e, topicIndex, field) => {
      const value = e.target.value;
      setMatrix((prevMatrix) => {
        const newTopics = [...prevMatrix.topics];
        newTopics[topicIndex][field] = value;
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };

    const handleSubtopicChange = (e, topicIndex, subtopicIndex, field) => {
      const value = e.target.value;
      setMatrix((prevMatrix) => {
        const newTopics = [...prevMatrix.topics];
        newTopics[topicIndex].subtopics[subtopicIndex][field] = value;
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };


    const addTopic = () => {
      setMatrix((prevMatrix) => {
        const newTopics = [
          ...prevMatrix.topics,
          {
            topic_area: 'Open Topic - Topic Area Title - Click here to edit.',
            description: 'Enter specific details about a topic area or open topic. This will help our AI focus your proposal on this area - Click here to edit.',
            location: '',
            selected: false,
            subtopics: []
          }
        ];
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };
    

    const addSubtopic = (topicIndex) => {
      setMatrix((prevMatrix) => {
        const newTopics = [...prevMatrix.topics];
        newTopics[topicIndex].subtopics.push({
          subtopic_area: 'New Subtopic Area',
          description: 'Description for new subtopic area.',
          location: '',
          selected: false
        });
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };

    const removeTopic = (topicIndex) => {
      setMatrix((prevMatrix) => {
        const newTopics = prevMatrix.topics.filter((_, index) => index !== topicIndex);
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };

    const removeSubtopic = (topicIndex, subtopicIndex) => {
      setMatrix((prevMatrix) => {
        const newTopics = [...prevMatrix.topics];
        newTopics[topicIndex].subtopics = newTopics[topicIndex].subtopics.filter((_, index) => index !== subtopicIndex);
        const updatedMatrix = { ...prevMatrix, topics: newTopics };
        saveMatrixToDatabase(updatedMatrix);
        return updatedMatrix;
      });
    };

    const renderSubtopics = (subtopics, topicIndex) => {
      if (!subtopics || subtopics.length === 0) return null;

      return subtopics.map((subtopic, subtopicIndex) => (
        <div className="subtopic" key={subtopicIndex}>
          <div className="subtopic-details">
            <h4>
              <input
                type="checkbox"
                checked={subtopic.selected === true || subtopic.selected === 'true'}
                onChange={() => toggleSubtopicSelection(topicIndex, subtopicIndex)}
              />
              <input
                type="text"
                className="subtopic-title-input"
                value={subtopic.subtopic_area || '[Subtopic Title]'}
                onChange={(e) => handleSubtopicChange(e, topicIndex, subtopicIndex, 'subtopic_area')}
              />
            </h4>
            <div>
              <h5 className="collapsible" onClick={() => toggleSection(`subtopicDetails-${topicIndex}-${subtopicIndex}`)}>
                <span>Details</span>
                <i className={`fas fa-chevron-down collapsible-icon ${showDetails[`subtopicDetails-${topicIndex}-${subtopicIndex}`] ? 'rotate' : ''}`}></i>
              </h5>
              <div className={`collapsible-content ${showDetails[`subtopicDetails-${topicIndex}-${subtopicIndex}`] ? 'show' : ''}`}>
                {subtopic.expected_deliverables && <p><strong>Expected Deliverables:</strong> {subtopic.expected_deliverables}</p>}
                {subtopic.location && <p><strong>Location:</strong> {subtopic.location}</p>}
              </div>
            </div>
          </div>
          <button className="subtopic-volume-remove-btn" onClick={() => removeSubtopic(topicIndex, subtopicIndex)}><i className="fas fa-times-circle"></i></button>
        </div>
      ));
    };

    const renderTopics = (topics) => {
      if (!topics || topics.length === 0) return null;

      return (
        <div className="topics-group">
          <h3>Extracted Topics/Subtopics<span className="tooltip-icon-topics">?
            <div className="tooltip-content-topics">
            <p>
              When you select a topic or subtopic, the AI will automatically search through the solicitation and extract all relevant information related to that specific topic or subtopic. The AI will analyze the document to find any content that can help you craft a more detailed proposal focusing on the selected topic/subtopic.
            </p>
            <p>
              If you're proposing a new or open topic and don't want the AI to search the solicitation for related content, simply add the words <strong>"Open Topic - "</strong> to the title of your new topic or subtopic. This will prevent the AI from searching through the document and allow you to focus on your unique proposal ideas.
            </p>

            </div>
          </span></h3>
          
          <div className="topics-list">
            {topics.map((topic, index) => (
              <div className="topic" key={index}>
                <div className="topic-details">
                  <h3>
                    <input
                      type="checkbox"
                      checked={topic.selected === true || topic.selected === 'true'}
                      onChange={() => toggleTopicSelection(index)}
                    />
                    <input
                      type="text"
                      className="topic-title-input"
                      value={topic.topic_area || '[Topic Area]'}
                      onChange={(e) => handleTopicChange(e, index, 'topic_area')}
                    />
                  </h3>
                  <textarea
                    className="topic-description-textarea"
                    value={topic.description || ''}
                    onChange={(e) => handleTopicChange(e, index, 'description')}
                  />
                  <div>
                    <h5 className="collapsible" onClick={() => toggleSection(`topicDetails-${index}`)}>
                      <span>Details</span>
                      <i className={`fas fa-chevron-down collapsible-icon ${showDetails[`topicDetails-${index}`] ? 'rotate' : ''}`}></i>
                    </h5>
                    <div className={`collapsible-content ${showDetails[`topicDetails-${index}`] ? 'show' : ''}`}>
                      {topic.objectives && <p><strong>Objectives:</strong> {topic.objectives}</p>}
                      {topic.expected_deliverables && <p><strong>Expected Deliverables:</strong> {topic.expected_deliverables}</p>}
                      {topic.location && <p><strong>Location:</strong> {topic.location}</p>}
                    </div>
                    {topic.subtopics && (
                      <div className="subtopicsContainer">
                        {renderSubtopics(topic.subtopics, index)}
                        <button onClick={() => addSubtopic(index)}>Add Subtopic</button>
                      </div>
                    )}
                  </div>
                </div>
                <button className="topic-volume-remove-btn" onClick={() => removeTopic(index)}><i className="fas fa-times-circle"></i></button>
              </div>
            ))}
          </div>
        </div>
      );
    };






    const renderPreApplicationComponents = (components = []) => {
      if (!components || components.length === 0) return null;

      return components.map((component, index) => (
        <div className="component" key={index}>
          {component.step && <h4>{component.step}</h4>}
          {component.details && <p>{component.details}</p>}
          {component.subcomponents && (
            <div className="subcomponents">
              {component.subcomponents.map((subcomponent, subIndex) => (
                <div className="subcomponent" key={subIndex}>
                  {subcomponent.title && <h5>{subcomponent.title}</h5>}
                  {subcomponent.details && <p>{subcomponent.details}</p>}
                  {subcomponent.requirements && (
                    <ul>
                      {subcomponent.requirements.map((requirement, reqIndex) => (
                        <li key={reqIndex}>
                          {requirement.name && <strong>{requirement.name}:</strong>} {requirement.description}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>
      ));
    };

    const renderSolicitationDetails = (details = {}) => {
      return (
        <div className="solicitationDetails">
          <h2>Solicitation Details</h2>
          {details.description && <p><strong>Description:</strong> {details.description}</p>}
          {details.point_of_contacts && details.point_of_contacts.length > 0 && (
              <div>
                  <h3 className="collapsible" onClick={() => toggleSection('pointOfContact')}>
                      <span>Point of Contact</span>
                      <i className={`fas fa-chevron-down collapsible-icon ${showDetails.pointOfContacts ? 'rotate' : ''}`}></i>
                  </h3>
                  <div className={`collapsible-content ${showDetails.pointOfContact ? 'show' : ''}`}>
                      {details.point_of_contacts.map((contact, index) => (
                          <div key={index} className="poc-details">
                              {contact.name && <p><strong>Name:</strong> {contact.name}</p>}
                              {contact.email && <p><strong>Email:</strong> {contact.email}</p>}
                              {contact.phone && <p><strong>Phone:</strong> {contact.phone}</p>}
                              {contact.title && <p><strong>Title:</strong> {contact.title}</p>}
                              {contact.agency && <p><strong>Agency:</strong> {contact.agency}</p>}
                          </div>
                      ))}
                  </div>
              </div>
          )}

          {details.due_dates && (
            <div>
              <h3 className="collapsible" onClick={() => toggleSection('dueDates')}>
                <span>Due Dates</span>
                <i className={`fas fa-chevron-down collapsible-icon ${showDetails.dueDates ? 'rotate' : ''}`}></i>
              </h3>
              <div className={`collapsible-content ${showDetails.dueDates ? 'show' : ''}`}>
                {details.due_dates.pre_application_deadline && (
                  <p><strong>Pre-Application Deadline:</strong> {details.due_dates.pre_application_deadline}</p>
                )}
                {details.due_dates.full_application_deadline && (
                  <p><strong>Full Application Deadline:</strong> {details.due_dates.full_application_deadline}</p>
                )}
                {details.due_dates.is_two_stage !== undefined && (
                  <p><strong>Two-Stage Process:</strong> {String(details.due_dates.is_two_stage).toLowerCase() === 'true' ? 'Yes' : 'No'}</p>

                )}
              </div>
            </div>
          )}
          {details.funding && (
            <div>
              <h3 className="collapsible" onClick={() => toggleSection('funding')}>
                <span>Funding</span>
                <i className={`fas fa-chevron-down collapsible-icon ${showDetails.funding ? 'rotate' : ''}`}></i>
              </h3>
              <div className={`collapsible-content ${showDetails.funding ? 'show' : ''}`}>
                {details.funding.total_funding_available && (
                  <p><strong>Total Funding Available:</strong> {details.funding.total_funding_available}</p>
                )}
                {details.funding.pre_application_award_amount && (
                  <p><strong>Pre-Application Award Amount:</strong> {details.funding.pre_application_award_amount}</p>
                )}
                {details.funding.full_application_award_amount && (
                  <p><strong>Full Application Award Amount:</strong> {details.funding.full_application_award_amount}</p>
                )}
                {details.funding.min_award && (
                  <p><strong>Min Award:</strong> {details.funding.min_award}</p>
                )}
                {details.funding.max_award && (
                  <p><strong>Max Award:</strong> {details.funding.max_award}</p>
                )}
              </div>
            </div>
          )}
          {details.eligibility_requirements && (
            <div>
              <h3 className="collapsible" onClick={() => toggleSection('eligibilityRequirements')}>
                <span>Eligibility Requirements</span>
                <i className={`fas fa-chevron-down collapsible-icon ${showDetails.eligibilityRequirements ? 'rotate' : ''}`}></i>
              </h3>
              <div className={`collapsible-content ${showDetails.eligibilityRequirements ? 'show' : ''}`}>
                <ul>
                  {details.eligibility_requirements.map((requirement, index) => (
                    <li key={index}>{requirement}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {details.performance_period && (
            <div>
              <h3 className="collapsible" onClick={() => toggleSection('performancePeriod')}>
                <span>Performance Period</span>
                <i className={`fas fa-chevron-down collapsible-icon ${showDetails.performancePeriod ? 'rotate' : ''}`}></i>
              </h3>
              <div className={`collapsible-content ${showDetails.performancePeriod ? 'show' : ''}`}>
                <p>{details.performance_period.performance_period}</p>
              </div>
            </div>
          )}
        </div>
      );
    };

    const renderRequirements = (requirements = {}, isTwoStage = true) => {
        //console.log("Render Requirements called");
        //console.log("Requirements object:", requirements);
        //console.log("isTwoStage value:", isTwoStage);

        if (!requirements.full_application && !requirements.pre_application || !requirements) {
          requirements.full_application = {
              overview: "Our AI was unable to find details regarding the full application, but you can proceed with proposal draft here.",
              detailed_requirements: [
                  "No description extracted"
              ],
              submission_requirements: [
                  { requirement: "Project Proposal", completed: false }
              ],
              status: "Not started"
          };
        }

        return (
            <div className="requirements">
                <h3>Requirements</h3>
                {requirements.pre_application && (
                    <div>
                        <h4 className="collapsible" onClick={() => toggleSection('preApplication')}>
                            <span>Pre-Application</span>
                            <i className={`fas fa-chevron-down collapsible-icon ${showDetails.preApplication ? 'rotate' : ''}`}></i>
                            {renderStatus(requirements.pre_application.status)}
                        </h4>
                        <div className={`collapsible-content ${showDetails.preApplication ? 'show' : ''}`}>
                            {requirements.pre_application.overview && <p>{requirements.pre_application.overview}</p>}
                            {requirements.pre_application.detailed_requirements && (
                                <ul>
                                    {requirements.pre_application.detailed_requirements.map((req, index) => (
                                        <li key={index}>{req}</li>
                                    ))}
                                </ul>
                            )}
                            {requirements.pre_application.submission_requirements && (
                                <div className="submission-requirements">
                                    <h5>Submission Requirements Checklist</h5>
                                    <ul className="subRequirementUL">
                                        {requirements.pre_application.submission_requirements.map((req, index) => (
                                            <li key={index} className={`requirement-item ${req.completed ? 'completed' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    checked={req.completed}
                                                    onChange={() => toggleSubmissionRequirement('pre_application', index)}
                                                />
                                                {req.requirement}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            {requirements.pre_application.components && renderPreApplicationComponents(requirements.pre_application.components)}
                              <div className="complianceMatrixBtnDivSBIR">
                                {!preApplicationProposalStructure ? (
                                  <GenerateProposalStructureSBIR
                                    stage="pre_application"
                                    onProposalStructureCreated={handleResponseFromGenerateProposalStructure}
                                    apiKey={apiKey}
                                    setSelectedTab={setSelectedTab}
                                    setProposalStructureStreamed={setProposalStructureStreamed}
                                    complianceMatrixCreated={complianceMatrixCreated}
                                    setProposalStructureStreaming={setProposalStructureStreaming}
                                    selectedFile={selectedFile}
                                    topics={getSelectedTopics()} // Pass the selected topics and subtopics
                                    setIsGeneratingStructure={setIsGeneratingStructure}
                                    setCurrentStructureStep={setCurrentStructureStep}
                                    handleMoveToStep20={handleMoveToStep20}
                                    handleMoveToStep21={handleMoveToStep21}
                                    id="PAstep19"

                                  />
                                ) : (
                                  getSelectedTopics().length > 0 ? (
                                    <button onClick={() => {
                                      //console.log("preApplicationProposalStructure:", preApplicationProposalStructure);
                                      setProposalVolumes(preApplicationProposalStructure);
                                      setProposalStructureStage('pre_application');
                                      setSelectedTab('viewProposalStructure');
                                    }}>
                                      View Proposal Structure
                                    </button>
                                  ) : (
                                    <button disabled style={{ backgroundColor: (getSelectedTopics.length === 0 ? '#f8d7da' : ''), color: (getSelectedTopics.length === 0 ? '#721c24' : ''), cursor: (getSelectedTopics.length === 0 ? 'not-allowed' : '') }}>
                                      Please select a topic before continuing
                                    </button>
                                  )
                                )}
                              </div>

                        </div>
                    </div>
                )}
                {requirements.full_application && requirements.full_application.overview && (
                    <div>
                        <h4 className="collapsible" onClick={() => {
                          toggleSection('fullApplication');
                          setTimeout(() => {
                            //console.log("Before handleMoveToStep19");
                            handleMoveToStep19();
                            //console.log("After handleMoveToStep19");

                          }, 1000); // Delay of 2000ms (2 seconds)
                        }}>
                            <span>Full Application</span>
                            <i id="step18" className={`fas fa-chevron-down collapsible-icon ${showDetails.fullApplication ? 'rotate' : ''}`}></i>
                            {renderStatus(requirements.full_application.status)}
                        </h4>
                        <div className={`collapsible-content ${showDetails.fullApplication ? 'show' : ''}`}>
                            {requirements.full_application.overview && <p>{requirements.full_application.overview}</p>}
                            {requirements.full_application.detailed_requirements && (
                                <ul>
                                    {requirements.full_application.detailed_requirements.map((req, index) => (
                                        <li key={index}>{req}</li>
                                    ))}
                                </ul>
                            )}
                            {requirements.full_application.submission_requirements && (
                                <div className="submission-requirements">
                                    <h5>Submission Requirements Checklist</h5>
                                    <ul className="subRequirementUL">
                                        {requirements.full_application.submission_requirements.map((req, index) => (
                                            <li key={index} className={`requirement-item ${req.completed ? 'completed' : ''}`}>
                                                <input
                                                    type="checkbox"
                                                    checked={req.completed}
                                                    onChange={() => toggleSubmissionRequirement('full_application', index)}
                                                />
                                                {req.requirement}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )}
                            <div className="complianceMatrixBtnDivSBIR">
                              {!fullApplicationProposalStructure ? (
                                <GenerateProposalStructureSBIR
                                  stage="full_application"
                                  onProposalStructureCreated={handleResponseFromGenerateProposalStructure}
                                  apiKey={apiKey}
                                  setSelectedTab={setSelectedTab}
                                  setProposalStructureStreamed={setProposalStructureStreamed}
                                  complianceMatrixCreated={complianceMatrixCreated}
                                  setProposalStructureStreaming={setProposalStructureStreaming}
                                  selectedFile={selectedFile}
                                  topics={getSelectedTopics()} // Pass the selected topics and subtopics
                                  setIsGeneratingStructure={setIsGeneratingStructure}
                                  setCurrentStructureStep={setCurrentStructureStep}
                                  handleMoveToStep20={handleMoveToStep20}
                                  handleMoveToStep21={handleMoveToStep21}
                                  id="FAstep19"
                                />
                              ) : (
                                getSelectedTopics().length > 0 ? (
                                  <button onClick={() => {
                                    setSelectedTab('viewProposalStructure');
                                    //console.log("fullApplicationProposalStructure:", fullApplicationProposalStructure);
                                    setProposalVolumes(fullApplicationProposalStructure);
                                    setProposalStructureStage('full_application');
                                  }}>
                                    View Proposal Structure
                                  </button>
                                ) : (
                                  <button disabled style={{ backgroundColor: (getSelectedTopics.length === 0 ? '#f8d7da' : ''), color: (getSelectedTopics.length === 0 ? '#721c24' : ''), cursor: (getSelectedTopics.length === 0 ? 'not-allowed' : '') }}>
                                    Please select a topic before continuing
                                  </button>
                                )
                              )}
                            </div>

                        </div>
                    </div>
                )}
            </div>
        );
    };









    const getSelectedTopics = () => {
      let selectedTopicsAndSubtopics = [];

      matrix.topics.forEach(topic => {
        // Check if subtopics exist and filter selected subtopics
        const selectedSubtopics = (topic.subtopics || [])
          .filter(subtopic => subtopic.selected)
          .map(subtopic => ({
            subtopic_area: subtopic.subtopic_area,
            description: subtopic.description,
          }));

        // Add selected subtopics to the results
        selectedTopicsAndSubtopics = selectedTopicsAndSubtopics.concat(selectedSubtopics);

        // If the topic is selected, add the topic itself
        if (topic.selected) {
          selectedTopicsAndSubtopics.push({
            topic_area: topic.topic_area,
            description: topic.description,
          });
        }
      });

      return selectedTopicsAndSubtopics;
    };




























  return (
    <div className="dashboard">
        <Sidebar
          isSidebarCollapsed={isSidebarCollapsed}
          setSelectedTab={setSelectedTab}
          toggleSidebar={toggleSidebar}
          handleLogout={handleLogout}
          userInfo={userInfo}
          currentTab={selectedTab}
          steps={steps}
          groups={groups} // Pass the list of groups
          selectedGroup={selectedGroup} // Pass the currently selected group
          setSelectedGroup={handleGroupChange} // Pass function to change selected group
          handleStartTour={handleStartTour}
        />
        <div ref={scrollContainerRef} className={`dashboard-main-content ${isSidebarCollapsed ? 'sidebar-collapsed' : ''} ${isAISidebarOpen ? 'AIsidebar-open' : ''}`}>
        {tourActive && (
        <Joyride
          steps={tourSteps}
          run={tourActive} // Control tour activation
          continuous
          showCloseButton={true}
          disableBeacon={true}
          stepIndex={stepIndex}
          spotlightClicks={true}
          scrollContainer={`.dashboard-main-content`} // Specify the container for Joyride
          disableOverlayClose={true}
          styles={{
            options: {
              zIndex: 10000, // Ensure it stays on top
              overlayColor: "rgba(0, 0, 0, 0.7)", // Darker semi-transparent overlay for better focus
              primaryColor: "#007bff", // Primary color for the next button and highlights
              textColor: "#333", // Text color inside tooltips
              width: undefined, // Auto-width for the tooltips
              arrowColor: "#fff", // Match tooltip background for a seamless arrow look
              spotlightClicks: true, // Allow interaction with non-step elements
              disableBeacon: true,
            },
            overlay: {
              mixBlendMode: "multiply", // Overlay blends better with the background
            },
            tooltip: {
              backgroundColor: "white", // White background for tooltips
              borderRadius: "10px", // Rounded corners for a modern look
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // Subtle shadow for tooltip depth
              padding: "20px", // Add padding for better content spacing
              textAlign: "center", // Center-align the content
            },
            tooltipTitle: {
              fontSize: "18px", // Slightly larger font size for titles
              fontWeight: "bold", // Make titles stand out
              color: "#007bff", // Match primary color for a cohesive design
            },
            tooltipContent: {
              fontSize: "14px", // Standard font size for content
              lineHeight: "1.6", // Add spacing for readability
              color: "#555", // Slightly muted text color for better readability
            },
            tooltipButton: {
              backgroundColor: "#007bff", // Button background color
              color: "#fff", // Button text color
              borderRadius: "5px", // Rounded corners for buttons
              padding: "10px 15px", // Button padding
              fontSize: "14px", // Button font size
            },
            tooltipSkip: {
              backgroundColor: "#f8d7da", // Light red background for emphasis
              color: "#721c24", // Darker red text for contrast
              borderRadius: "5px", // Rounded corners for buttons
              padding: "10px 15px", // Padding for the skip button
              fontSize: "14px", // Font size for consistency
              border: "1px solid #f5c6cb", // Subtle border for definition
              cursor: "pointer", // Change cursor to pointer for better UX
              marginTop: "10px", // Add spacing from other elements
            },
            tooltipClose: {
              color: "#555", // Close button color
              fontSize: "16px", // Adjust close button size
              padding: "5px", // Padding for the close button
            },
            spotlight: {
              borderRadius: "10px", // Round spotlight for focused elements
              border: "2px solid rgba(255, 255, 255, 0.8)", // Add a subtle border to the spotlight
              backgroundColor: "white",
            },
          }}
          callback={(data) => {
            //console.log("Joyride callback data:", data);
            //console.log("data.index:", data.index);
          
            if (
              (data.lifecycle === "complete" && data.action === "close") || // User clicked "Close"
              data.action === "skip" || // User clicked "Skip"
              data.status === "finished" ||
              data.index === 27 // Tour finished naturally
            ) {
              handleTourClose(); // Stop the tour
            } else if (data.lifecycle === "complete" && data.action === "next" && ((data.index !== 2) && (data.index !== 5) && (data.index !== 7) && (data.index !== 8) && (data.index !== 11) && (data.index !== 17) && (data.index !== 18) && (data.index !== 19) && (data.index !== 20) && (data.index !== 22))) {
              // Move to the next step
              //console.log("Moving to next step. Current index:", data.index);
              setStepIndex((prevIndex) => prevIndex + 1);
            } else if (data.lifecycle === "complete" && data.action === "prev") {
              // Move to the previous step
              //console.log("Moving to previous step. Current index:", data.index);
              setStepIndex((prevIndex) => Math.max(prevIndex - 1, 0)); // Ensure index doesn't go below 0
            }
            else if (data.index === 26 && data.action === "update") {
              setTimeout(() => {
                handleTourClose(); // Close the tour after 1 second
              }, 7000); // 1000ms = 1 second
            }
          }}
          
        />
        )}


            
            <h1 style={{
              color: '#333', // Dark gray for a professional look
              fontWeight: '400', // Slightly bolder text
              textAlign: 'center',
              borderBottom: '2px solid #ddd', // Light gray underline
            }} id="step4">
              {proposalProcessName ? (
                <>
                  Current Workflow: <span style={{ color: '#007BFF', marginBottom: '10px' }}>{proposalProcessName}</span>
                </>
              ) : ''}
            </h1>



            <p className="app-version" style={{ position: 'fixed', bottom: '0', right: '0', margin: '10px 4px 0 10px', fontSize: '10px', opacity: '0.7' }}>Version 4.4.7</p>



            <StepIndicator steps={steps} currentStep={selectedTab} setSelectedTab={setSelectedTab} nextButtonActive={nextButtonActive} selectedTab={selectedTab}/>



            {selectedTab === 'nameProposal' && (
              <>
                <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                  {currentStepIndex > 0 && (
                    <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button>
                  )}

                  {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                    <button onClick={goToNextStep} disabled={!nextButtonActive}>Next</button>
                  )}
                </div>

                <div className="dashboard-console">
                  <div className="manageDBTab">
                    <h1>Create a new workflow to start a fresh proposal process</h1>
                    <form className="proposal-process-form" onSubmit={handleProposalProcessSubmit}>
                      <input
                        type="text"
                        placeholder="Proposal Process Name (Ex: Name - Date - Opportunity)"
                        value={proposalProcessNameInText}
                        onChange={handleProposalProcessNameChange}
                        className="inputProductName"
                        id="step1"
                      />
                      <button type="submit" className="submitProposalName" disabled={!proposalProcessNameInText.trim()} id="step3" onClick={() => handleMoveToStep4()}>
                        Create New Proposal Process Workflow
                      </button>
                    </form>
                  </div>

                  <div className="btn-container-start-process">
                    {/*
                    <button className="btn btn-sbir" onClick={() => handleNavigation('RFP')}>
                      Need a Compliance Matrix?
                    </button>
                    */}

                    <button className="btn-tour" onClick={() => handleStartTour()}>
                      Guided Tour 🚀 
                    </button>
                  </div>

                  <div style={{ marginTop: '20px', textAlign: 'center' }}>
                    <button
                      className="btn btn-help"
                      onClick={() => setSelectedTab('viewHowItWorks')}
                      style={{ padding: '10px 20px', backgroundColor: '#ffc107', border: 'none', borderRadius: '5px', color: '#fff', cursor: 'pointer', fontSize: '16px' }}
                    >
                      Need Help? View How It Works
                    </button>
                  </div>

                  <Modal
                    isOpen={isModalOpen}
                    onRequestClose={handleModalClose}
                    contentLabel="Select Proposal Type"
                    className="modal"
                    overlayClassName="overlay"
                  >
                    <h2>Select Proposal Type</h2>
                    <p>Please select whether you will be working on an SBIR or RFP proposal.</p>
                    <div className="modal-buttons">
                      <button className="btn btn-sbir" onClick={() => handleSelection('SBIR')}>SBIR</button>
                      <button className="btn btn-rfp" onClick={() => handleSelection('RFP')}>RFP</button>
                    </div>
                  </Modal>

                </div>
              </>
            )}
            {selectedTab === 'nameProposal' && (
              <div className="allProducts">
                <h2>Or select an existing proposal process flow to pick up where you left off</h2>
                <div className="product-list">
                {Object.entries(proposalProcesses)
                  .filter(([productName, files]) => 
                    productName && 
                    productName !== '/' && 
                    files.some(file => file.name && file.name.endsWith('.json')) // Check the 'name' property of each file for '.json'
                  )
                  .map(([productName, files], index) => {
                    // Access both solicitationDetails and requirements separately
                    const solicitationDetails = workflowDetails[productName]?.solicitation_details;
                    const requirements = workflowDetails[productName]?.solicitation_requirements;

                  
                    return (
                      <div key={index} className={`product1`}>
                        {/* Trash Icon for Deleting the Workflow */}
                        <h3>{productName}</h3>
                        <button 
                          className="delete-workflow-btn" 
                          onClick={() => handleDeleteAllFiles(productName)} 
                          title="Delete Workflow"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                    
                        {solicitationDetails ? (
                          <div className="workflow-details">
                            {/* Description Section */}
                            {solicitationDetails.description && (
                              <p className="solicitation-description">
                                <strong>
                                  <i className="fas fa-info-circle"></i> Description:
                                </strong> 
                                {solicitationDetails.description}
                              </p>
                            )}
                            {/* Funding Available */}
                            {solicitationDetails.funding?.total_funding_available && (
                              <div className="funding-item">
                                <i className="fas fa-dollar-sign funding-icon"></i>
                                <span>{solicitationDetails.funding.total_funding_available}</span>
                              </div>
                            )}
                        
                            {/* Pre-Application Section */}
                            {requirements?.pre_application && (
                              <div className="deadline-section">
                                <div onClick={() => handleProposalProcessContinueWorkStatus(productName, requirements.pre_application.status)} className="deadline-header-container" id="step6">
                                  <h4 className="deadline-header">Pre-Application</h4> {/* Pre-App Header */}
                                  <button 
                                      onClick={() => handleProposalProcessContinueWorkStatus(productName, requirements.pre_application.status)}
                                      className="continue-work-btn-new"
                                  >
                                    <i className="fas fa-arrow-right"></i> {/* Change this to any icon you prefer */}
                                  </button>
                                </div>
                                <div className="deadline-item">
                                  <div className="deadline-details">
                                    <div className="compact-item">
                                      <i className="fas fa-file-alt deadline-icon"></i>
                                      {solicitationDetails.due_dates?.pre_application_deadline ? (
                                        <span>{solicitationDetails.due_dates.pre_application_deadline}</span>
                                      ) : (
                                        <span>No deadline provided</span> // Message when no deadline is available
                                      )}
                                    </div>
                                    {/* Pre-Application Status */}
                                    {requirements?.pre_application?.status && (
                                      <div className={`status-sticker ${requirements.pre_application.status.toLowerCase()}`}>
                                        <span>{requirements.pre_application.status.toUpperCase()}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        
                            {/* Full-Application Section */}
                            {requirements?.full_application && (
                              // Full-Application Section
                              <div className="deadline-section">
                                <div onClick={() => handleProposalProcessContinueWorkStatus(productName, requirements.full_application.status)} className="deadline-header-container">
                                  <h4 className="deadline-header">Full-Application</h4> {/* Full-App Header */}
                                  <button 
                                      onClick={() => handleProposalProcessContinueWorkStatus(productName, requirements.full_application.status)}
                                      className="continue-work-btn-new"
                                  >
                                    <i className="fas fa-arrow-right"></i> {/* Change this to any icon you prefer */}
                                  </button>
                                </div>

                                
                                
                                <div className="deadline-item">
                                  <div className="deadline-details">
                                    <div className="compact-item">
                                      <i className="fas fa-calendar-day deadline-icon"></i>
                                      {/* Display the deadline if available, otherwise display a message */}
                                      {solicitationDetails.due_dates?.full_application_deadline ? (
                                        <span>{solicitationDetails.due_dates.full_application_deadline}</span>
                                      ) : (
                                        <span>No deadline provided</span> // Message when no deadline is available
                                      )}
                                    </div>
                                    {/* Full-Application Status */}
                                    {requirements?.full_application?.status && (
                                      <div className={`status-sticker ${requirements.full_application.status.toLowerCase()}`}>
                                        <span>{requirements.full_application.status.toUpperCase()}</span>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        
                            
                          </div>
                        ) : (
                          null
                        )}












                        {/* File Dropdown Section */}
                        <div className="file-dropdown-container">
                          <button 
                            onClick={() => toggleFileList(productName)} 
                            className="file-dropdown-toggle-btn"
                          >
                            {fileListVisibility[productName] ? "Hide Files" : "File Associated"}
                            <i className={`fas ${fileListVisibility[productName] ? 'fa-chevron-up' : 'fa-chevron-down'}`}></i>
                          </button>
                          {/* Conditionally display the file list for the specific product with smooth transition */}
                          <ul className={`file-list ${fileListVisibility[productName] ? 'show' : ''}`}>
                            {files
                              .filter(file => file.name.endsWith('.docx') || file.name.endsWith('.pdf')) // Filter files here
                              .map((file, fileIndex) => (
                                <li key={fileIndex}>
                                  <div className="file-name-products" onClick={() => handleDownloadFromAllProductsDiv(file)}>
                                    <i className="fas fa-download file-download-icon"></i>
                                    {file.name}
                                  </div>
                                  <button 
                                    className="file-remove-btn" 
                                    onClick={() => handleRemoveProcessProposalFile(productName, file.name)}
                                  >
                                    <i className="fas fa-times-circle"></i>
                                  </button>
                                </li>
                              ))}
                          </ul>
                        </div>


                      </div>
                    );
                  })}
                </div>
              </div>
            )}

          {selectedTab === 'grantsOpportunities' && (
            <div className="grants-opportunities">
              <h2 className="grants-opportunities-header">
                Find Your Next Opportunity
              </h2>
          
              <div className="grants-opportunities-bar">
                <div className="profile-selector-unique">
                  <label htmlFor="profileSelect">Select Profile:</label>
                  <select
                    id="profileSelect"
                    value={selectedProfile?.suffix || ""}
                    onChange={(e) => {
                      const selectedSuffix = e.target.value;
                      const selected = profiles.find((profile) => profile.suffix === selectedSuffix);
                      setSelectedProfile(selected);
                      setProfileSuffix(selected?.suffix || "");
                    }}
                  >
                    {profiles.map((profile) => (
                      <option key={profile.suffix} value={profile.suffix}>
                        {profile.profileName || `Profile ${profile.suffix}`}
                      </option>
                    ))}
                  </select>
                </div>
                  
                <div className="agency-filter-container-unique">
                  <div className="agency-filter-label-unique-container">
                    <label htmlFor="source-select" className="agency-filter-label-unique">
                      Filter by Source:
                    </label>
                    <div className="selected-agencies-unique">
                      {selectedSources.length > 0 ? (
                        selectedSources.map((source, index) => (
                          <div className="selected-agency-unique" key={index}>
                            <span>{source}</span>
                            <span
                              className="remove-agency-unique"
                              onClick={() => handleRemoveSource(source)}
                            >
                              ✖
                            </span>
                          </div>
                        ))
                      ) : (
                        <span className="placeholder-text-unique">No sources selected</span>
                      )}
                    </div>
                  </div>
                    
                  <div className="agency-select-wrapper-unique" ref={dropdownRef}>
                    <div className="dropdown-wrapper-unique">
                      <div className="dropdown-unique" onClick={toggleDropdown}>
                        <div className="dropdown-label-unique">Select Sources</div>
                        <div className="dropdown-icon-unique"><i className="fas fa-angle-down"></i></div>
                      </div>
                    
                      {isDropdownOpen && (
                        <div className="agency-options-unique">
                          {sources.map((source, index) => (
                            <div
                              key={index}
                              className={`agency-option-unique ${
                                selectedSources.includes(source) ? "selected-unique" : ""
                              }`}
                              onClick={() => handleSourceChange(source)}
                            >
                              {source}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                    
                <button onClick={handleAlertButtonClick} className="set-alert-button">
                  🔔 Set Email Notification
                </button>
                    
                {isAlertModalOpen && (
                  <div className="modal-overlay">
                    <div className="alert-modal">
                      <h3 className="alert-modal-header-opportunities">Manage Your Alerts</h3>
                      
                      {/* Display Selected Profile Name */}
                      <div className="selected-profile-alerts">
                        <h4 className="alert-modal-subheader-opportunities">
                          Selected Profile: <span className="profile-name-highlight">{selectedProfile?.profileName || "Unnamed Profile"}</span>
                        </h4>
                      </div>
                      
                      {/* Display Selected Sources */}
                      <div>
                        <h4 className="alert-modal-subheader-opportunities">Selected Sources:</h4>
                        <ul className="alert-modal-list-opportunities">
                          {selectedSources.length > 0 ? (
                            selectedSources.map((source, index) => (
                              <li key={index} className="alert-modal-list-item-opportunities">
                                {source}{" "}
                                <button onClick={() => handleRemoveSource(source)}>Remove</button>
                              </li>
                            ))
                          ) : (
                            <p className="placeholder-text-unique">All Sources</p>
                          )}
                        </ul>
                      </div>
                        
                      <div className="alert-modal-footer">
                        <button onClick={handleCloseModal} className="alert-modal-close-button-opportunities">Close</button>
                        <button onClick={handleSaveAlert} className="alert-modal-save-button-opportunities">Save this Alert</button>
                      </div>
                    </div>
                  </div>
                )}
          
              
                <button
                  className="grants-fetch-button"
                  onClick={() => {
                    fetchGrantsOpportunities();
                  }}
                  disabled={!selectedProfile || isFetchingGrantOpps}
                >
                  {isFetchingGrantOpps ? "Fetching..." : "Fetch New Grants Opportunities"}
                </button>
              </div>

              {/* Show loading modal while fetching */}
              {isFetchingGrantOpps && (
                <div className="loading-modal">
                  <div className="loading-spinner"></div>
                  <p>Fetching grants opportunities...</p>
                </div>
              )}

              {/* Display fetched opportunities */}
              {grantsOpportunities && grantsOpportunities.length > 0 ? (
                <div>
                  <div className="grants-grid">
                    {grantsOpportunities
                      .slice(
                        (grantOpportunitiesCurrentPage - 1) * grantOpportunitiesPageSize,
                        grantOpportunitiesCurrentPage * grantOpportunitiesPageSize
                      )
                      .map((opportunity, index) => {
                        const description = opportunity.Description || "No description available.";
                        const isExpandedDescription = expandedSectionsDescription[index];
                      
                        return (
                          <div className="grants-card" key={index}>
                            <h3 className="grants-title">{opportunity.OpportunityTitle}</h3>
                            <div className="grants-details-container">
                              <div className="grants-column">
                                <p>
                                  <span className="grants-attribute-header">Source:</span> {opportunity.Source}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Opportunity ID:</span> {opportunity.OpportunityID}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Posted Date:</span> {formatDate(opportunity.PostDate)}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Closing Date:</span> {formatDate(opportunity.CloseDate)}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Contact Email:</span> {opportunity.GrantorContactEmail}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Similarity Score:</span>
                                  {opportunity.SimilarityScore ? (
                                    <div className="circular-progress-container">
                                      <svg className="circular-progress" viewBox="0 0 36 36">
                                        <path
                                          className="circle-bg"
                                          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                        />
                                        <path
                                          className="circle-progress"
                                          d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                                          style={{
                                            strokeDasharray: `${Math.min(opportunity.SimilarityScore * 200, 100)}, 100`,
                                          }}
                                        />
                                      </svg>
                                      <div className="score-text">
                                        {Math.min(Math.round(opportunity.SimilarityScore * 200), 100)}%
                                      </div>
                                    </div>
                                  ) : (
                                    "N/A"
                                  )}
                                </p>
                              </div>
                                
                              <div className="grants-column">
                                <p>
                                  <span className="grants-attribute-header">Agency:</span> {opportunity.AgencyName}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Category:</span> {opportunity.OpportunityCategory}
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Award Ceiling:</span>
                                  <span style={{ color: "green" }}>{formatCurrency(opportunity.AwardCeiling)}</span>
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Award Floor:</span>
                                  <span style={{ color: "green" }}>{formatCurrency(opportunity.AwardFloor)}</span>
                                </p>
                                <p>
                                  <span className="grants-attribute-header">Expected Number of Awards:</span> {opportunity.ExpectedNumberOfAwards}
                                </p>
                              </div>
                            </div>
                                
                            {/* Description section */}
                            <div className="grants-description">
                              <p style={{ overflowWrap: "normal" }}>
                                <span className="grants-attribute-header-1">Description:</span>
                                <div
                                  style={{
                                    maxHeight: isExpandedDescription ? "1000px" : "3em",
                                    overflow: "hidden",
                                    transition: "max-height 0.5s ease",
                                  }}
                                >
                                  {description}
                                </div>
                                <div
                                  onClick={() => handleToggleSectionDescription(index)}
                                  style={{
                                    cursor: "pointer",
                                    color: isExpandedDescription ? "#d9534f" : "#0275d8",
                                    fontSize: "10px",
                                    fontWeight: "500",
                                    textAlign: "right",
                                    marginTop: "0.5em",
                                    transition: "max-height 0.5s ease",
                                    marginLeft: "10px",
                                  }}
                                >
                                  {isExpandedDescription ? "Click to Collapse" : "Click to Expand"}
                                </div>
                              </p>
                            </div>
                                
                            {/* Buttons for external link and adding to pipeline */}
                            <div className="grants-url-button-container">
                              {opportunity.AdditionalInformationURL ? (
                                <a
                                  className="grants-url-view-opportunity-button"
                                  href={opportunity.AdditionalInformationURL}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  View Opportunity
                                </a>
                              ) : (
                                <button className="grants-url-view-opportunity-button-disabled" disabled>
                                  No Link Available
                                </button>
                              )}
                              <button
                                className="grants-add-to-pipeline-button"
                                onClick={() => {
                                  handleAddToPipeline(opportunity);
                                }}
                              >
                                Add to Pipeline
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                    
                  {/* Pagination Controls */}
                  <div className="pagination-controls">
                    <button
                      onClick={() => setGrantOpportunitiesCurrentPage((prev) => Math.max(prev - 1, 1))}
                      disabled={grantOpportunitiesCurrentPage === 1}
                    >
                      Previous
                    </button>
                    <span>
                      Page {grantOpportunitiesCurrentPage} of {Math.ceil(grantsOpportunities.length / grantOpportunitiesPageSize)}
                    </span>
                    <button
                      onClick={() => setGrantOpportunitiesCurrentPage((prev) => prev + 1)}
                      disabled={grantOpportunitiesCurrentPage === Math.ceil(grantsOpportunities.length / grantOpportunitiesPageSize)}
                    >
                      Next
                    </button>
                  </div>
                </div>
              ) : (
                !isFetchingGrantOpps && <p className="grants-no-opportunities">No Grants opportunities available.</p>
              )}
            </div>
          )}










            {selectedTab === 'uploadGrantsToDB' && (
                <div className="upload-grants-to-db">
                    <h2>Upload Grants XML to Database</h2>
                    <form onSubmit={handleGrantsXMLUpload}>
                        <label htmlFor="grantsXMLFileInput">Upload XML File:</label>
                        <input
                            type="file"
                            id="grantsXMLFileInput"
                            accept=".xml"
                            onChange={onGrantsXMLFileChange}
                            required
                        />
                        <button type="submit" disabled={isGrantsUploadLoading}>
                            {isGrantsUploadLoading ? "Processing..." : "Upload File"}
                        </button>
                    </form>
                </div>
            )}

            {selectedTab === 'uploadGrantsToDB' && (
              <div className="upload-grants-to-db-unique">
                <h2>Upload SAM.gov Opportunities CSV</h2>
                <form onSubmit={handleSAMGovCSVUpload} className="upload-form-unique">
                  <label htmlFor="samGovCSVFileInput" className="upload-label-unique">
                    Select CSV File:
                  </label>
                  <input
                    type="file"
                    id="samGovCSVFileInput"
                    accept=".csv"
                    onChange={onSAMGovCSVFileChange}
                    className="upload-input-unique"
                    required
                  />
                  <button type="submit" className="upload-button-unique" disabled={isSAMGovUploadLoading}>
                    {isSAMGovUploadLoading ? "Processing..." : "Upload CSV"}
                  </button>
                </form>
              </div>
            )}









            {selectedTab === 'samOpportunities' && (
                <div className="sam-opportunities">
                    <h2>SAM Opportunities</h2>
                    <button onClick={fetchMatchedOpportunities}>Fetch Matched Opportunities</button>
                    {Array.isArray(matchedOpportunities) && matchedOpportunities.length > 0 ? (
                        <ul>
                            {matchedOpportunities.map((opportunity, index) => (
                                <li key={index}>
                                    <h3>{opportunity.title}</h3>
                                    <p>Solicitation Number: {opportunity.solicitationNumber}</p>
                                    <p>Posted Date: {opportunity.postedDate}</p>
                                    <p>Response Deadline: {opportunity.responseDeadLine}</p>
                                    <p>Organization: {opportunity.fullParentPathName}</p>
                                    <p>NAICS Code: {opportunity.naicsCode}</p>
                                    <p>Classification Code: {opportunity.classificationCode}</p>
                                    <p>Type: {opportunity.type}</p>
                                    <p>Base Type: {opportunity.baseType}</p>
                                    <p>Archive Date: {opportunity.archiveDate}</p>
                                    <p>Type of Set-Aside: {opportunity.typeOfSetAsideDescription || "N/A"}</p>
                                    <p>Organization Type: {opportunity.organizationType || "N/A"}</p>
                                    <p>Office Address: 
                                        {opportunity.officeAddress && `${opportunity.officeAddress.city}, ${opportunity.officeAddress.state}, ${opportunity.officeAddress.zipcode}`}
                                    </p>
                                    <p>Place of Performance: 
                                        {opportunity.placeOfPerformance && opportunity.placeOfPerformance.city?.name
                                            ? `${opportunity.placeOfPerformance.city.name}, ${opportunity.placeOfPerformance.state?.name || ''}`
                                            : "N/A"}
                                    </p>
                                    <p>Active: {opportunity.active}</p>
                                    <p>
                                        Point of Contact:
                                        {opportunity.pointOfContact.length > 0
                                            ? opportunity.pointOfContact.map((poc, i) => (
                                                  <span key={i}>
                                                      {poc.fullName}, Email: {poc.email}, Phone: {poc.phone || "N/A"}<br />
                                                  </span>
                                              ))
                                            : "N/A"}
                                    </p>
                                    <p>
                                        Description Link:{" "}
                                        <a href={opportunity.descriptionLink} target="_blank" rel="noopener noreferrer">
                                            View Description
                                        </a>
                                    </p>
                                    <p>
                                        Additional Info Link:{" "}
                                        {opportunity.additionalInfoLink ? (
                                            <a href={opportunity.additionalInfoLink} target="_blank" rel="noopener noreferrer">
                                                View Additional Info
                                            </a>
                                        ) : (
                                            "N/A"
                                        )}
                                    </p>
                                    <p>
                                        Resource Links:
                                        {opportunity.resourceLinks.length > 0
                                            ? opportunity.resourceLinks.map((link, i) => (
                                                  <a key={i} href={link} target="_blank" rel="noopener noreferrer">
                                                      Download File {i + 1}
                                                  </a>
                                              ))
                                            : "N/A"}
                                    </p>
                                    <a href={opportunity.uiLink} target="_blank" rel="noopener noreferrer">
                                        View Opportunity
                                    </a>
                                    <button onClick={() => handleAddToPipeline(opportunity)}>Add to Pipeline</button>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No matched opportunities available.</p>
                    )}
                </div>
            )}

            {selectedTab === 'pipeline' && (
                <div className="pipeline-opportunities">
                    <h2 className="pipeline-header">Pipeline Opportunities</h2>
                    <button className="pipeline-refresh-button" onClick={fetchPipelineOpportunities}>
                        Refresh Pipeline
                    </button>
                    {pipelineOpportunities && pipelineOpportunities.length > 0 ? (
                        <div className="pipeline-grid">
                            {pipelineOpportunities.map((opportunity, index) => {
                                const description = opportunity.Description || "No description available.";
                                const isExpandedDescription = expandedSectionsDescription[index];
                            
                                return (
                                    <div className="pipeline-card" key={index}>
                                        <h3 className="pipeline-title">{opportunity.title || opportunity.OpportunityTitle}</h3>
                                        <div className="pipeline-details-container">
                                            <div className="pipeline-column">
                                                <p>
                                                    <span className="pipeline-attribute-header">Source:</span> {opportunity.source || "Unknown"}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Opportunity ID:</span> {opportunity.noticeId || "N/A"}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Posted Date:</span> {formatDate(opportunity.postedDate || opportunity.PostDate)}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Closing Date:</span> {formatDate(opportunity.responseDeadLine || opportunity.CloseDate)}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Contact Email:</span> {opportunity.GrantorContactEmail || "N/A"}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Similarity Score:</span>
                                                    {opportunity.SimilarityScore ? (
                                                        <div className="circular-progress-container">
                                                            <svg className="circular-progress" viewBox="0 0 36 36">
                                                                <path
                                                                    className="circle-bg"
                                                                    d="M18 2.0845
                                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                                                />
                                                                <path
                                                                    className="circle-progress"
                                                                    d="M18 2.0845
                                                                      a 15.9155 15.9155 0 0 1 0 31.831
                                                                      a 15.9155 15.9155 0 0 1 0 -31.831"
                                                                    style={{
                                                                        strokeDasharray: `${Math.min(opportunity.SimilarityScore * 200, 100)}, 100`,
                                                                    }}
                                                                />
                                                            </svg>
                                                            <div className="score-text">
                                                                {Math.min(Math.round(opportunity.SimilarityScore * 200), 100)}%
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        "N/A"
                                                    )}
                                                </p>
                                            </div>
                                            <div className="pipeline-column">
                                                <p>
                                                    <span className="pipeline-attribute-header">Agency:</span> {opportunity.AgencyName || "N/A"}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Category:</span> {opportunity.OpportunityCategory || "N/A"}
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Award Ceiling:</span>{" "}
                                                    <span style={{ color: "green" }}>{formatCurrency(opportunity.AwardCeiling || 0)}</span>
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Award Floor:</span>{" "}
                                                    <span style={{ color: "green" }}>{formatCurrency(opportunity.AwardFloor || 0)}</span>
                                                </p>
                                                <p>
                                                    <span className="pipeline-attribute-header">Expected Number of Awards:</span> {opportunity.ExpectedNumberOfAwards || "N/A"}
                                                </p>
                                            </div>
                                        </div>
                                        <div className="pipeline-description">
                                            <p style={{ overflowWrap: "normal" }}>
                                                <span className="pipeline-attribute-header-1">Description:</span>
                                                <div
                                                    style={{
                                                        maxHeight: isExpandedDescription ? "1000px" : "3em",
                                                        overflow: "hidden",
                                                        transition: "max-height 0.5s ease",
                                                    }}
                                                >
                                                    {description}
                                                </div>
                                                <div
                                                    onClick={() => handleToggleSectionDescription(index)}
                                                    style={{
                                                        cursor: "pointer",
                                                        color: isExpandedDescription ? "#d9534f" : "#0275d8",
                                                        fontSize: "10px",
                                                        fontWeight: "500",
                                                        textAlign: "right",
                                                        marginTop: "0.5em",
                                                        transition: "max-height 0.5s ease",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {isExpandedDescription ? "Click to Collapse" : "Click to Expand"}
                                                </div>
                                            </p>
                                        </div>
                                        <div className="pipeline-url-button-container">
                                            {opportunity.additionalInfoLink ? (
                                                <a
                                                    className="pipeline-url-view-opportunity-button"
                                                    href={opportunity.additionalInfoLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    View Opportunity
                                                </a>
                                            ) : (
                                                <button className="pipeline-url-view-opportunity-button-disabled" disabled>
                                                    No Link Available
                                                </button>
                                            )}
                                            <button
                                                className="pipeline-remove-from-pipeline-button"
                                                onClick={() => handleRemoveFromPipeline(opportunity.noticeId)}
                                            >
                                                Remove from Pipeline
                                            </button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p className="pipeline-no-opportunities">No opportunities in the pipeline.</p>
                    )}
                </div>
            )}






            {selectedTab === 'companyProfile' && (
              <div className="company-profile-container">
                <h2 className="company-profile-header">Company Profile</h2>
            
                <div className="profile-selection-container">
                  <label htmlFor="profile-select" className="profile-label">
                    Select Profile:
                  </label>
                  <div className="profile-select-wrapper">
                    <select
                      id="profile-select"
                      value={selectedProfile?.suffix || ""}
                      onChange={(e) => {
                        console.log("Profile selection triggered for suffix:", e.target.value);
                        const selectedSuffix = e.target.value;
                        const selected = profiles.find((profile) => profile.suffix === selectedSuffix);

                        if (selected) {
                          console.log("Setting Selected Profile:", selected);
                          handleProfileSelection(selected.suffix);
                        }
                        else {
                          console.log("No profile found for suffix:", selectedSuffix);
                          handleProfileSelection("new");
                        }
                      }}
                      className="profile-select"
                    >
                      <option value="new">Create New Profile</option>
                      {profiles.map((profile, index) => (
                        <option key={index} value={profile.suffix}>
                          Profile - {profile.profileName || `Profile ${profile.suffix}`}
                        </option>
                      ))}
                    </select>

                      
                    <div className="profile-buttons">
                      {selectedProfile !== "new" && selectedProfile && (
                        <button
                          className="delete-profile-button"
                          onClick={() => handleDeleteProfile(selectedProfile)}
                        >
                          Delete Profile
                        </button>
                      )}
                
                      <button
                        className="create-profile-button"
                        onClick={() => handleProfileSelection("new")}
                      >
                        Create New Profile
                      </button>
                    </div>
                  </div>
                </div>

                 

                {/* AI Profile Modal */}
                {isAiProfileModalOpen && (
                  <div className="ai-profile-modal-unique">
                    <div className="modal-content-unique-ai">
                      <h2>Create Profile Using AI</h2>
                      <p className="ai-profile-description">
                        Select the reference files that contain important details about your company, such as past projects, products, or capabilities. 
                        The AI will analyze the selected files to generate a detailed company profile, including key capabilities, past performance, target keywords, and more.
                        Once selected, click "Generate AI Profile" to begin the process. This may take a moment while the AI processes the information.
                      </p>

                
                      {/* Display list of reference files by category */}
                      <div className="ai-reference-files-container-unique">
                        {Object.entries(availableReferenceFilesAI).map(([categoryName, files], index) => (
                          <div key={index} className="ai-reference-category-unique">
                            <h3>{categoryName}</h3>
                            <ul className="ai-reference-file-list-unique">
                              {files.map((file, fileIndex) => (
                                <li key={fileIndex} className="ai-reference-file-item-unique">
                                  <label>
                                    <input
                                      type="checkbox"
                                      checked={selectedReferenceFilesAI.includes(file)}
                                      onChange={() => handleFileSelectionAI(file)}
                                    />
                                    {file.name}
                                  </label>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                      {/* Loading state indicator */}
                      {isGeneratingProfile && (
                        <div className="loading-indicator-ai">
                          <div className="spinner-ai"></div>
                          <p>Generating AI Profile... Please wait.</p>
                        </div>
                      )}
                      
                      {/* Modal actions */}
                      <div className="modal-actions-unique-ai">
                      <button onClick={handleCloseAiProfileModal} className="cancel-button-unique-ai" disabled={isGeneratingProfile}>
                        Cancel
                      </button>
                      <button onClick={handleGenerateAiProfile} className="submit-button-unique-ai" disabled={isGeneratingProfile}>
                        {isGeneratingProfile ? "Processing..." : "Generate AI Profile"}
                      </button>
                      </div>
                    </div>
                  </div>
                )}


                {/* Trigger for AI Profile creation */}
                <button onClick={handleOpenAiProfileModal} className="create-profile-ai-button">
                  🧠 Create Profile Using AI
                </button>


                {/* Trigger to open the modal */}
                <button onClick={handleOpenAlertModal} className="manage-alerts-button">
                  🔔 Manage Email Notifications For This Profile
                </button>

                {/* Alerts Modal */}
                {isAlertViewModalOpen && (
                  <div className="alert-modal-alerts">
                    <div className="modal-content-alerts">
                      <h1 className="your-alerts-header">Your Alerts</h1>
                      {/* Explanation for Email Alerts */}
                      <div className="alert-email-notification">
                        <p>
                          You will receive an email every Monday with the top 3 grant opportunities for the sources you have selected. These opportunities will be based on your profile and the selected sources.
                        </p>
                        <p>
                          <strong>How it works:</strong><br />
                          - Your profile will be analyzed to match opportunities that align with your interests and expertise.<br />
                          - Only the most relevant opportunities will be sent, ensuring you're always updated with the best possible options.<br />
                          - You can add or remove sources anytime, and your email will update with the latest selections.
                        </p>
                      </div>
                
                      <h4 className="saved-alerts-header">🔔 Saved Alerts</h4>
                
                      {/* Display Saved Alerts */}
                      <div className="alert-list">
                        {alerts && alerts.length > 0 ? (
                          alerts.map((alert, index) => {
                            // Find the profile name based on the profile suffix
                            const profile = profiles.find((p) => p.suffix === alert.profileSuffix);
                            const profileName = profile ? profile.profileName : `Profile ${alert.profileSuffix}`;
                          
                            return (
                              <div key={index} className="alert-item">
                                <div>
                                  <span className="profile-name-highlight">
                                    Profile: {profileName}
                                  </span>
                                </div>
                            
                                {/* Display Sources */}
                                {Array.isArray(alert.sources) && alert.sources[0] === '' ? (
                                  <div>
                                    <span>Sources: All sources</span>
                                  </div>
                                ) : Array.isArray(alert.sources) && alert.sources.length > 0 ? (
                                  <div>
                                    <span>Sources: {alert.sources.join(', ')}</span>
                                  </div>
                                ) : (
                                  <div><span>Sources: All sources</span></div>
                                )}

                                <button 
                                  onClick={() => handleRemoveAlert(alert.alertId, alert.profileSuffix)} 
                                  className="delete-alert-button-alerts"
                                >
                                  Delete
                                </button>
                              </div>
                            );
                          })
                        ) : (
                          <p>No alerts saved</p>
                        )}
                      </div>

                      
                      <h4 className="selected-sources-header">Selected Sources:</h4>
                      {/* Display Selected Sources */}
                      <div className="alert-list">
                        {selectedSources.length > 0 ? (
                          selectedSources.map((source, index) => (
                            <div key={index} className="alert-item">
                              <span>{source}</span>
                              <button onClick={() => handleRemoveSource(source)} className="delete-alert-button-alerts">
                                Remove
                              </button>
                            </div>
                          ))
                        ) : (
                          <p>No sources selected</p>
                        )}
                      </div>
                      
                      {/* Add New Alert Section */}
                      <div className="add-alert-container-alerts">
                        <div className="agency-select-wrapper-unique-alerts">
                          <div className="dropdown-wrapper-unique-alerts">
                            <div className="dropdown-unique-alerts" onClick={toggleDropdown}>
                              <div className="dropdown-label-unique-alerts">Select Sources</div>
                              <div className="dropdown-icon-unique-alerts">
                                <i className="fas fa-angle-down"></i>
                              </div>
                            </div>
                      
                            {isDropdownOpen && (
                              <div className="agency-options-unique-alerts">
                                {sources.map((source, index) => (
                                  <div
                                    key={index}
                                    className={`agency-option-unique-alerts ${selectedSources.includes(source) ? "selected-unique-alerts" : ""}`}
                                    onClick={() => handleSourceChange(source)}
                                  >
                                    {source}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                          
                      {/* Close Modal */}
                      <div className="add-alert-button-container-alerts">
                        <button onClick={handleCloseAlertModal} className="close-alert-modal">
                          Close
                        </button>
                        <button onClick={handleAddAlert} className="add-alert-button">
                          Add Alert
                        </button>
                      </div>
                    </div>
                  </div>
                )}


                    
                    
                <form onSubmit={handleCompanyProfileSubmit} className="company-profile-form">
                  <div className="form-group">
                    <label htmlFor="company-name" className="form-label">
                      Company Name:
                    </label>
                    <input
                      type="text"
                      id="company-name"
                      value={profileName}
                      onChange={(e) => setProfileName(e.target.value)}
                      className="form-input"
                      required
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="company-description" className="form-label">
                      Description:
                    </label>
                    <textarea
                      id="company-description"
                      value={companyDescription}
                      onChange={(e) => setCompanyDescription(e.target.value)}
                      className="form-textarea"
                      required
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="naics-codes" className="form-label">
                      NAICS Codes (Comma-separated):
                    </label>
                    <input
                      type="text"
                      id="naics-codes"
                      value={naicsCodes}
                      onChange={(e) => setNaicsCodes(e.target.value)}
                      className="form-input"
                      placeholder="Comma-separated NAICS codes"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="psc-codes" className="form-label">
                      PSC Codes (Comma-separated):
                    </label>
                    <input
                      type="text"
                      id="psc-codes"
                      value={pscCodes}
                      onChange={(e) => setPscCodes(e.target.value)}
                      className="form-input"
                      placeholder="Comma-separated PSC codes"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="capabilities" className="form-label">
                      Capabilities:
                    </label>
                    <textarea
                      id="capabilities"
                      value={capabilities}
                      onChange={(e) => setCapabilities(e.target.value)}
                      className="form-textarea"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="past-performance" className="form-label">
                      Past Performance:
                    </label>
                    <textarea
                      id="past-performance"
                      value={pastPerformance}
                      onChange={(e) => setPastPerformance(e.target.value)}
                      className="form-textarea"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="target-keywords" className="form-label">
                      Target Keywords (Comma-separated):
                    </label>
                    <input
                      type="text"
                      id="target-keywords"
                      value={targetKeywords}
                      onChange={(e) => setTargetKeywords(e.target.value)}
                      className="form-input"
                      placeholder="Comma-separated keywords"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="location" className="form-label">
                      Location/Geographic Focus:
                    </label>
                    <input
                      type="text"
                      id="location"
                      value={location}
                      onChange={(e) => setLocation(e.target.value)}
                      className="form-input"
                    />
                  </div>
                    
                  <div className="form-group">
                    <label htmlFor="funding-needs" className="form-label">
                      Funding Needs:
                    </label>
                    <textarea
                      id="funding-needs"
                      value={fundingNeeds}
                      onChange={(e) => setFundingNeeds(e.target.value)}
                      className="form-textarea"
                    />
                  </div>
                    
                  <div className="form-actions">
                  <button 
                    onClick={handleCompanyProfileSubmit} 
                    className="button-active"
                  >
                    Save Profile
                  </button>

                  </div>

                </form>
              </div>
            )}



            








            {selectedTab === 'manageDatabase' && (
              <>
                <div
                  className="steps-btn-div"
                  style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}
                >
                  {currentStepIndex > 0 && (
                    <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button>
                  )}

                  {currentStepIndex < steps.length - 1 && 
                    selectedTab !== "viewHowItWorks" && 
                    selectedTab !== "viewFirstDraft" && (
                      <div className="tooltip-wrapper-next" id="step8">
                        <button 
                          onClick={goToNextStep} 
                          disabled={!nextButtonActive || !proposalProcessName}
                        >
                          Next
                        </button>
                        {(!proposalProcessName || !nextButtonActive) && (
                          <div className="tooltip-next">
                            <ul style={{ paddingLeft: '0px' }}>
                              {!proposalProcessName && (
                                <p style={{ textAlign: 'center', marginLeft: '0px', paddingLeft: '5px', paddingRight: '5px' }}>To continue, go back to the first step and create a new workflow, or select an existing workflow.</p>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                    )
                  }

                </div>

                




                <div className="dashboard-console" id="step5">
                  <div className="manageDBTab2">
                    <h1>Upload new documents that are relevant to your proposal</h1>
                    <p style={{ fontStyle: "italic" }}>
                      These can include previous proposals, technical documents, or any other materials you want the AI to reference.
                    </p>
                    <p>Make sure to organize your documents by name.</p>

                    
                    {isAddingProduct && (
                      <div className="newProduct" id="step6">
                        <input
                          type="text"
                          placeholder="Category Name"
                          value={productName}
                          onChange={handleProductNameChange}
                          className="inputProductName"
                        />
                        <div {...getRootProps()} className="dropzone">
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <p>Drop the files here ...</p>
                          ) : (
                            <p>Drag and drop some files here, or click to select files</p>
                          )}
                        </div>
                        <ul>{filesList}</ul>
                        <button
                          onClick={handleUploadProductToDB}
                          disabled={isUploadingProductToDB || (!productFiles || productFiles.length === 0) || productName === ''}
                        >
                          {isUploadingProductToDB ? 'Uploading...' : 'Upload Files to Database'}
                        </button>

                      </div>
                    )}

                    {uploadSuccess && (
                      <div className="upload-success-message">
                        Files have been successfully uploaded.
                        <button onClick={handleAddAnotherProduct}>Add Another Product</button>
                      </div>
                    )}


                    {/* Add URL input for creating reference material file */}
                    <div className="create-reference-from-url">
                      <div className="beta-sticker">Beta</div> {/* Beta sticker */}
                      <input
                        type="text"
                        placeholder="Enter Company Website URL"
                        value={companyUrl}
                        onChange={(e) => setCompanyUrl(e.target.value)}
                        className="inputUrlField"
                        style={{ marginBottom: '5px', width: '100%', padding: '10px' }}
                        disabled={isLoading} // Disable input when loading
                      />
                      <button
                        onClick={handleGenerateReferenceFromUrl} // Call the async function directly here
                        disabled={!companyUrl || isLoading} // Disable button when no URL or loading
                        style={{
                          backgroundColor: isLoading ? '#ccc' : '#00b0dc', // Grey out button when loading
                          color: 'white',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          transition: 'background-color 0.3s',
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          alignSelf: 'center',
                        }}
                      >
                        {isLoading ? (
                          <div className="spinner"></div> // Show spinner when loading
                        ) : (
                          'Generate Reference Material from URL'
                        )}
                      </button>
                      
                      {isLoading && (
                        <p>Processing the URL, please wait...</p> // Optional message during loading
                      )}
                    </div>

                    {/* Form for creating a new reference file */}
                    <div className="create-reference-file">
                      <button
                        onClick={() => setShowReferenceForm(!showReferenceForm)}
                        style={{
                          backgroundColor: showReferenceForm ? '#e2505f' : '#00b0dc',
                          color: 'white',
                          padding: '10px 15px',
                          border: 'none',
                          borderRadius: '4px',
                          cursor: 'pointer',
                          fontSize: '16px',
                          transition: 'background-color 0.3s',
                          marginBottom: '10px',
                        }}
                      >
                        {showReferenceForm ? "Cancel" : "Create a Reference Material File by Form"}
                      </button>

                      {showReferenceForm && (
                        <form onSubmit={handleReferenceFileSubmit}>
                          <div className="form-group">
                            <label><span style={{ color: 'red' }}>*</span> What would you like to name this file?</label>
                            <textarea
                              placeholder="Enter the title here..."
                              value={referenceFileTitle}
                              onChange={(e) => setReferenceFileTitle(e.target.value)}
                              required
                            />
                          </div>
                      
                          <div className="form-group">
                            <label><span style={{ color: 'red' }}>*</span> Provide a brief overview of your company:</label>
                            <textarea
                              placeholder="Enter the company overview here..."
                              value={companyOverview}
                              onChange={(e) => setCompanyOverview(e.target.value)}
                              required
                            />
                          </div>
                      
                          <div className="form-group">
                            <label><span style={{ color: 'red' }}>*</span> Describe the background and history of your company:</label>
                            <textarea
                              placeholder="Enter the company background here..."
                              value={companyBackground}
                              onChange={(e) => setCompanyBackground(e.target.value)}
                              required
                            />
                          </div>
                      
                          <div className="form-group">
                            <label><span style={{ color: 'red' }}>*</span> What is the key product or service you offer?</label>
                            <textarea
                              placeholder="Enter product details here..."
                              value={productDetails}
                              onChange={(e) => setProductDetails(e.target.value)}
                              required
                            />
                          </div>

                          
                      
                          <div className="form-group">
                            <label><span style={{ color: 'red' }}>*</span> Please provide a brief description of your product/service:</label>
                            <textarea
                              placeholder="Enter the description here..."
                              value={referenceFileDescription}
                              onChange={(e) => setReferenceFileDescription(e.target.value)}
                              required
                            />
                          </div>
                      
                          <div className="form-group">
                            <label>What makes your product/service unique?</label>
                            <textarea
                              placeholder="Enter unique product information here..."
                              value={uniqueProduct}
                              onChange={(e) => setUniqueProduct(e.target.value)}
                            />
                          </div>
                      
                          <div className="form-group">
                            <label>List any relevant certifications, awards, or recognitions your company has received:</label>
                            <textarea
                              placeholder="Enter certifications, awards, etc. here..."
                              value={certifications}
                              onChange={(e) => setCertifications(e.target.value)}
                            />
                          </div>
                      
                          <div className="form-group">
                            <label>Provide any additional information that could be useful for drafting proposals:</label>
                            <textarea
                              placeholder="Enter additional information here..."
                              value={additionalInfo}
                              onChange={(e) => setAdditionalInfo(e.target.value)}
                            />
                          </div>
                      
                          <div className="form-actions">
                            <button type="submit">Save Reference File</button>
                            <button type="button" className="cancel-btn-form" onClick={() => setShowReferenceForm(false)}>Cancel</button>
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}










            





            {selectedTab === 'manageDatabase' && (
              <div className="allProducts" id="step7">
                <h2>Existing Products that the AI has access to</h2>
                <p>Remove any outdated or irrelevant documents from the database to keep the AI's reference material as pertinent as possible.</p>
                <div className="product-list">
                  {Object.entries(products).map(([productName, files], index) => (
                    <div key={index} className="product">
                      <h3>{productName}</h3>
                      <ul>
                        {files.map((file, fileIndex) => (
                          <li key={fileIndex}>
                            <div className="file-name-products" onClick={() => handleDownloadFromAllProductsDiv(file)}>{file.name}</div>
                            <button onClick={() => handleRemoveFile(productName, file.name)}>Remove</button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {selectedTab === 'generateProposal' && !proposalProcessName && (
              <div className="go-back-div">
                <h2>You must create a workflow or choose an existing workflow first.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('nameProposal')}>
                  Go to Select Workflow
                </button>
              </div>
            )}
            {selectedTab === 'viewAnalyzedSBIR' && (!complianceMatrixStreaming) && (
              <div className="go-back-div">
                <h2>You must upload an RFP/RFI/RFQ/SBIR and click create Compliance Matrix First.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('generateProposal')}>
                  Go to Upload Page
                </button>
              </div>
            )}
            {selectedTab === 'viewProposalStructure' && (!proposalStructureStreaming) && (
              <div className="go-back-div">
                <h2>You must go back and click the create proposal structure button.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewAnalyzedSBIR')}>
                  Go to Compliance Matrix Page
                </button>
              </div>
            )}
            {selectedTab === 'viewOutline' && !outlineContent && (
              <div className="go-back-div">
                <h2>You must go back and click a volume to edit.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewProposalStructure')}>
                  Go to proposal structure page
                </button>
              </div>
            )}
            {selectedTab === 'viewFirstDraft' && outlineFirstDraftMarkdown == '' && (
              <div className="go-back-div">
                <h2>You must go back and click Create Draft Based on This Outline or select an already generated draft.</h2>
                {/* Add a button that sets selectedTab to 'nameProposal' */}
                <button onClick={() => setSelectedTab('viewOutline')}>
                  Go to outline editor
                </button>
              </div>
            )}


            {selectedTab === 'generateProposal' && proposalProcessName && (
            <>
                <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                  {currentStepIndex > 0 && (
                    <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                  )}

                  {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab !== "viewHowItWorks" && selectedTab !== "viewFirstDraft" && (
                    <div className="tooltip-wrapper-next">
                      <button 
                        onClick={goToNextStep} 
                        disabled={
                          !Object.values(selectedRefFiles).some(isSelected => isSelected) || 
                          !selectedFile || 
                          !analysisSBIR
                        }
                      >
                        Next
                      </button>
                      {
                        (!Object.values(selectedRefFiles).some(isSelected => isSelected) || !selectedFile || !analysisSBIR) && (
                          <div className="tooltip-next">
                            <ul>
                              {!Object.values(selectedRefFiles).some(isSelected => isSelected) && (
                                <li>Please select at least one reference file.</li>
                              )}
                              {!selectedFile && (
                                <li>Please upload a solicitation file.</li>
                              )}
                              {!analysisSBIR && (
                                <li>You must analyze the solicitation first.</li>
                              )}
                            </ul>
                          </div>
                        )
                      }
                    </div>
                  )}

                
                </div>
                <div className="dashboard-console">
                  <div className="generateProposalTab">
                    <h1>Upload Solicitation</h1>

                    {/* Existing new proposal file upload */}
                    <div className="file-upload-container" id="step9">
                      <label htmlFor="newProposalFile" className="file-upload-label">
                        Upload New Grant Solicitation PDF
                      </label>
                      <input
                        id="newProposalFile"
                        type="file"
                        accept=".pdf, .docx, .doc"
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the actual input
                      />
                      {selectedFile && (
                        <div className="file-info">
                            <p>Selected file: {selectedFile.name} <button onClick={removeSelectedFile} className="remove-file-button">X</button></p>

                        </div>
                      )}
                    </div>

                    <div id="step10">
                        <h2>Select Reference Files</h2>
                        <p>This section allows you to select specific files from your database that you want the AI to reference when creating a draft for this solicitation. Choose from the list below to ensure the AI utilizes the most relevant information for drafting your proposal.</p>

                        <div className={`dropdown-container`}>
                            <div className="dropdown-check-list">
                                {Object.entries(products).map(([productName, files], index) => (
                                    <div key={index} className="product-group" id="step11">
                                        <label className="product-name-label">
                                            <input
                                                type="checkbox"
                                                onChange={e => handleSelectAllForProduct(e.target.checked, productName, files)}
                                                checked={files.every(file => selectedRefFiles[file.name])}
                                                disabled={!files.every(file => selectedRefFiles[file.name]) && Object.keys(selectedRefFiles).length >= 10}
                                            />
                                            {productName}
                                        </label>
                                        {files.map((file, fileIndex) => (
                                            <label key={fileIndex} className="file-label">
                                                <input
                                                    type="checkbox"
                                                    name={file.name}
                                                    checked={!!selectedRefFiles[file.name]}
                                                    onChange={e => handleReferenceFileChange(file.name, e.target.checked)}
                                                    disabled={!selectedRefFiles[file.name] && Object.keys(selectedRefFiles).length >= 10}
                                                /> {file.name}
                                            </label>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    {isScanningModal && (
                        <div className="scanning-modal-overlay">
                            <div className="scanning-modal">
                                <h2 className="scanning-modal-title">Analyzing Solicitation...</h2>
                                <p className="scanning-modal-text">
                                    The solicitation is being analyzed. This may take a few moments, so please be patient.
                                </p>
                                
                                <p className="analysis-progress-step">{`Step ${currentAnalysisStep + 1} of ${totalAnalysisSteps}`}</p>
                                <div className="analysis-progress-bar-container">
                                    <div className="analysis-progress-bar" style={{ width: `${analysisProgressPercentage}%` }}></div>
                                </div>
                                <p className="scanning-modal-text-2">
                                    {analysisProgressMessages[currentAnalysisStep]}
                                </p>
                                <div className="scanning-modal-spinner">
                                    <svg className="spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="spinner-bg" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                        <path className="spinner-fg" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    )}








                    <button onClick={handleFileUpload} disabled={!selectedFile || isProcessing} style={{display: "none"}}>
                      {isProcessing ? 'Extracting Requirements from file... Your page will update automatically once the AI begins generating.' : 'Generate Outline for Single Volume Proposal'}
                    </button>
                    <ScanSBIR
                      onMatrixCreated={handleResponseFromGenerateComplianceMatrix}
                      currentSBIR={selectedFile}
                      apiKey={apiKey}
                      setSelectedTab={setSelectedTab}
                      setComplianceMatrixStreamed={setComplianceMatrixStreamed}
                      selectedFile={selectedFile}
                      handleFileUploadMatrix={handleFileUploadMatrix}
                      setComplianceMatrixStreaming={setComplianceMatrixStreaming}
                      complianceMatrixStreaming={complianceMatrixStreaming}
                      selectedRefFiles={selectedRefFiles}
                      analysisSBIR={analysisSBIR}
                      setIsScanningModal={setIsScanningModal}
                    />

                  </div>
                  </div>
            </>
            )}

          {selectedTab === 'viewOutline' && outlineContent && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab !== "viewHowItWorks" && selectedTab !== "viewFirstDraft" && (
                <div className="tooltip-wrapper-next">
                  <button 
                    onClick={() => handleViewEditDraftClick(activeVolumeIndex, activeVolumeTag)} 
                    disabled={!firstDraftGenerated}>
                    Next
                  </button>
                  {!firstDraftGenerated && (
                    <div className="tooltip-next">
                      <ul>
                        <li>To move to the next step, save your outline and add optional prompts for each section.</li>
                        <li>Then, click the "Create a Draft" button at the bottom of this page.</li>
                      </ul>
                    </div>
                  )}
                </div>
              )}

            </div>
            <LexicalComposer initialConfig={initialConfig}>
              <div className="editor-shell" id="step24">
                <div className="flex-container">
                  {/* EditorSBIR Component */}
                  <div className="editor-sbir">
                    <EditorSBIR
                      ref={editorRef}
                      isAICollapsed={isAICollapsed}
                      setIsAICollapsed={setIsAICollapsed}
                      token={kc.token}
                      proposalProcessName={proposalProcessName}
                      tag={tagForDraft + "_outline"}
                      handleResponseFromEditOutline={handleResponseFromEditOutline}
                      stage={proposalStructureStage}
                      groupId={groupId}
                      companyName={companyName}
                    />
                  </div>

                  {/* OutlineAIPrompts Component */}
                  {outlineHeadersGenerated && <ConvertFirstDraftFromMarkdown outlineMarkdown={outlineContent} />}

                  {outlineHeadersGenerated && currentOutlineVolume && (
                    <div className="outline-ai-prompts">
                      <OutlineAIPrompts
                        handleSaveEditor={handleSaveEditor}
                        proposalVolumes={proposalVolumes}
                        tagForDraft={tagForDraft}
                        onSubmitPrompt={handleAIPromptSubmit}
                        outlineHeaders={outlineContent}
                      />
                    </div>
                  )}
                
                
                  {/* Additional Components */}
                  {outlineHeadersGenerated && (
                    <>
                      <div className="generate-first-draft small">
                        <GenerateFirstDraftWithLexicalSBIR
                          onFirstDraftCreated={handleResponseFromGenerateFullOutline}
                          apiKey={apiKey}
                          setSelectedTab={setSelectedTab}
                          setStreamedContent={setStreamedContent}
                          companyName={companyName}
                          outlineHeaders={outlineContent}
                          setOutlineContent={setOutlineContent}
                          outlineTitle={outlineTitle}
                          setFirstDraftSteaming={setFirstDraftStreaming}
                          userID={userID}
                          volumeTag={tagForDraft}
                          handleResponseFromEditOutline={handleResponseFromEditOutline}
                          rfpFile={selectedFile}
                          numProposals={numProposals}
                          selectedRefFiles={selectedRefFiles}
                          selectedTopics={getSelectedTopics()}
                          stage={proposalStructureStage}
                          token={kc.token}
                          setShowAlert={setShowAlert}
                          setIsGeneratingFirstDraftPopup={setIsGeneratingFirstDraftPopup}
                          aiOutlinePromptMessage={aiOutlinePromptMessage}
                          setShowErrorAlert={handleShowError}
                          groupId={groupId}
                          setCurrentFirstDraftStep={setCurrentFirstDraftStep}
                        />
                      </div>
                      {showErrorAlert && (
                        <div className="error-banner-draft">
                          There was an unexpected error. Please try again or contact admin for assistance.
                        </div>
                      )}
                    </>
                  )}
                </div>

                <FloatingToolbar
                  isAICollapsed={isAICollapsed}
                  setIsAICollapsed={setIsAICollapsed}
                  onTextSelection={handleTextSelection}
                />
              </div>
            </LexicalComposer>

          </>
          )}


          {selectedTab === 'viewFirstDraft' && firstDraftGenerated && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} disabled={firstDraftStreaming} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStep} disabled={!nextButtonActive}>Next</button>
              )}
            </div>
            <LexicalComposer initialConfig={draftConfig}>
                <div className="editor-shell">
                  <EditorSBIR
                    isAICollapsed={isAICollapsed}
                    setIsAICollapsed={setIsAICollapsed}
                    token={kc.token}
                    proposalProcessName={proposalProcessName}
                    tag={tagForDraft}
                    handleResponseFromEditOutline={handleResponseFromSaveDraft}
                    stage={proposalStructureStage}
                    groupId={groupId}
                    companyName={companyName}
                  />
                </div>
                {firstDraftGenerated && <ConvertFirstDraftFromMarkdown outlineMarkdown={outlineFirstDraftMarkdown} />}
                <FloatingToolbar
                  isAICollapsed={isAICollapsed}
                  setIsAICollapsed={setIsAICollapsed}
                  onTextSelection={handleTextSelection}
                />
            </LexicalComposer>
          </>
          )}
          {selectedTab === 'viewGenerationFirstDraft' && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} disabled={firstDraftStreaming} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              <h2>Generated Proposal</h2>

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab != "viewHowItWorks" && selectedTab != "viewFirstDraft" && (
                <button onClick={goToNextStep} disabled={!firstDraftGenerated || firstDraftStreaming}>Next</button>
              )}
            </div>
            
            {isGeneratingFirstDraftPopup && (
                <div className="first-draft-modal-overlay">
                    <div className="first-draft-modal">
                        <h2 className="first-draft-modal-title">Generating First Draft...</h2>
                        <p className="first-draft-progress-step">{`Step ${currentFirstDraftStep + 1} of ${totalFirstDraftSteps}`}</p>
                        <div className="first-draft-progress-bar-container">
                            <div className="first-draft-progress-bar" style={{ width: `${firstDraftProgressPercentage}%` }}></div>
                        </div>
                        <p className="first-draft-modal-text">
                            {firstDraftProgressMessages[currentFirstDraftStep]}
                        </p>
                        <div className="first-draft-modal-spinner">
                            <svg className="spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="spinner-bg" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                <path className="spinner-fg" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            )}

            <div className="streamed-content-container" ref={streamedContentRef}>
              <div className="streamed-content"><ReactMarkdown>{streamedContent}</ReactMarkdown></div>
            </div>
          </>
          )}
          {selectedTab === 'viewGenerationOutline' && (
            <div className="streamed-content-container" ref={streamedContentRef}>
              <h2>Generated Outline</h2>
              <div className="streamed-content"><ReactMarkdown>{outlineContent}</ReactMarkdown></div>
            </div>
          )}


        {(selectedTab === 'adminDashboard') && (
          <AdminDashboard keyCloakToken={kc.token} userID={userID} />
        )}
          






          {/*selectedTab === 'viewComplianceMatrix' && (complianceMatrixStreaming || complianceMatrixCreated) && (*/}
          {selectedTab === 'viewAnalyzedSBIR'&& (
          <>
              <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
                {currentStepIndex > 0 && (
                  <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
                )}

                <h2>Overview Of The Analyzed Solicitation</h2>

                {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab !== "viewHowItWorks" && selectedTab !== "viewFirstDraft" && (
                  <div className="tooltip-wrapper-next">
                    <button onClick={goToNextStep} disabled={true}>Next</button>
                    <div className="tooltip-next">
                      <ul>
                        <li>Click the "Generate Proposal Structure" or "View Proposal Structure" button under the requirements section to continue to the next step.</li>
                      </ul>
                    </div>
                  </div>
                )}

              </div>


              {isGeneratingStructure && (
                  <div className="structure-modal-overlay">
                      <div className="structure-modal"  id="step21">
                          <h2 className="structure-modal-title">Generating Proposal Structure...</h2>
                          <p className="structure-progress-step">{`Step ${currentStructureStep + 1} of ${totalStructureSteps}`}</p>
                          <div className="structure-progress-bar-container">
                              <div className="structure-progress-bar" style={{ width: `${structureProgressPercentage}%` }}></div>
                          </div>
                          <p className="structure-modal-text">
                              {proposalStructureMessages[currentStructureStep]}
                          </p>
                          <div className="scanning-modal-spinner">
                              <svg className="spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                  <circle className="spinner-bg" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                  <path className="spinner-fg" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                              </svg>
                          </div>
                      </div>
                  </div>
              )}







            {/* Banner to display when this tab is selected */}
            {selectedTab === 'viewAnalyzedSBIR' && !complianceMatrixCreated && complianceMatrixStreaming && (
                <div id="banner" style={{ backgroundColor: 'lightblue', textAlign: 'center', padding: '10px', marginBottom: '20px', display: 'block' }}>
                    Compliance matrix is being generated, please allow up to 5 minutes.
                </div>
            )}

            <div className="container" id="step13">
              <div className="top-section">
                <div className="left-column" id="step14">
                  {renderSolicitationDetails(matrix.solicitation_details)}
                </div>
                <div className="right-column" id="step15">
                  {renderRequirements(matrix.requirements, 
                    matrix?.solicitation_details?.due_dates?.is_two_stage !== undefined 
                      ? (matrix.solicitation_details.due_dates.is_two_stage === 'True' || 
                         matrix.solicitation_details.due_dates.is_two_stage === 'true' || 
                         matrix.solicitation_details.due_dates.is_two_stage === true)
                      : true  // Default to true if is_two_stage is missing
                  )}
                </div>


              </div>

              <div className="topics-section" id="step16">
                {renderTopics(matrix.topics)}
                <button style={{ width: 'auto', alignSelf: 'center'}} onClick={addTopic} id="step17">Add New Topic</button>
              </div>
            </div>



          </>
          )}

          {selectedTab === 'viewProposalStructure' && (proposalStructureStreaming || proposalStructureGenerated) && (
          <>
            <div className="steps-btn-div" style={{ display: 'flex', justifyContent: currentStepIndex > 0 ? 'space-between' : 'flex-end' }}>
              {currentStepIndex > 0 && (
                <button onClick={goToPreviousStep} style={{ marginRight: '10px' }}>Previous</button> // Optional margin for spacing
              )}

              <h2>Generated/Extracted Outlines</h2>

              {currentStepIndex < steps.length - 1 && (currentStepIndex > 0 || proposalProcessName) && selectedTab !== "viewHowItWorks" && selectedTab !== "viewFirstDraft" && (
                <div className="tooltip-wrapper-next">
                  <button onClick={goToNextStep} disabled={!outlineContent}>Next</button>
                  {!outlineContent && (
                    <div className="tooltip-next">
                      <ul>
                        <li>Select the "Edit Outline" button at the bottom of the volume you want to edit to move to the next step.</li>
                      </ul>
                    </div>
                  )}
                </div>
              )}

            </div>
            <div className="streamed-content-container-proposal-structure" ref={streamedContentRef} id="step22">
              <div className="streamed-content-proposal-structure" style={{ display: proposalStructureGenerated ? 'none' : 'block' }}>
                <ReactMarkdown>{proposalStructureStreamed}</ReactMarkdown>
              </div>
              <div className="proposal-volumes-div">

                {Array.isArray(proposalVolumes) && proposalVolumes.map((volume, volumeIndex) => {

                  const isEditable = editableVolumeIndex === volumeIndex;
                  const buttonText = isEditable ? "Save Sections" : "Edit Outline";
                  const volumeTag = volume.tag;
                  ////console.log(`Volume Tag: ${volumeTag}`); // Log the volume tag
                  const hasDraft = !!draftVolumes[volumeTag]; // Use the full tag for checking drafts
                  ////console.log(`Has Draft for ${volumeTag}: ${hasDraft}`); // Log whether the draft exists



                  return (
                    <div key={volumeIndex} className="proposal-volume" style={{ display: proposalStructureGenerated ? 'flex' : 'none' }}>
                      {/* Make the title editable with a unique ID if necessary */}
                      <div className="proposal-volume-header">
                        <h3
                          contentEditable
                          suppressContentEditableWarning={true}
                          id={`title-${volumeIndex}`}
                          onClick={(e) => e.currentTarget.focus()}
                          onFocus={(e) => console.log(`Before edit: ${e.target.innerText}`)}
                          onBlur={(e) => saveTitleChange(e, volumeIndex, proposalStructureStage)}
                          style={{
                            cursor: 'text',
                            backgroundColor: '#F4FAFF', // Light background to indicate editability
                            border: '1px dashed #D7ECFF', // Dashed border for emphasis
                            borderRadius: '5px',
                            padding: '5px 10px',
                            transition: 'background-color 0.3s ease, border-color 0.3s ease',
                            textAlign: 'center',
                            margin: '0 auto', // Center align within the container
                            maxWidth: '50%', // Prevent overflow if the title is too long
                            fontSize: '15px',
                          }}
                        >
                          {volume.title}
                        </h3>
                        
                        <button
                          className="proposal-volume-remove-btn"
                          onClick={() => setShowModalIndex(volumeIndex)}
                          aria-label="Remove Volume"
                          style={{
                            position: 'absolute',
                            right: '10px', // Fixed to the end of the div
                            top: '50%',
                            transform: 'translateY(-50%)', // Vertically center the button
                            background: 'none',
                            border: 'none',
                            color: '#d9534f',
                            cursor: 'pointer',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            margin: '5px'
                          }}
                        >
                          X {/* Replace with an icon if desired */}
                        </button>
                        {showModalIndex === volumeIndex && (
                            <ConfirmRemoveVolumeModal
                                onConfirm={() => handleRemoveVolume(volumeIndex, proposalStructureStage)}
                                onCancel={() => setShowModalIndex(null)}
                                volumeIndex={volumeIndex}
                                proposalStructureStage={proposalStructureStage}
                            />
                        )}
                      </div>
                      <ul style={{ listStyleType: "none", padding: "0", marginTop: "0px" }}>
                        {volume.sections.map((section, sectionIndex) => {
                          const { header, subheaders } = renderSectionContent(section);
                          const uniqueKey = `${volumeIndex}-${sectionIndex}`; // Unique key for each section
                        
                          // Ensure a ref exists for this unique key
                          if (!contentRefs.current[uniqueKey]) {
                            contentRefs.current[uniqueKey] = React.createRef();
                          }
                        
                          return (
                            <li
                              key={sectionIndex}
                              style={{
                                borderRadius: "5px",
                                margin: "10px 0",
                                padding: "7px",
                                overflow: "hidden",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                {/* Main header */}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    fontSize: "14px", // Adjusted font size for readability
                                    color: "#333",
                                  }}
                                >
                                  <ReactMarkdown>{header}</ReactMarkdown>
                                </div>
                                {/* Expand/Collapse toggle */}
                                {subheaders.length > 0 && (
                                  <div
                                    onClick={() => toggleSection1(uniqueKey)}
                                    style={{
                                      cursor: "pointer",
                                      color: expandedSections[uniqueKey]
                                        ? "#d9534f"
                                        : "#0275d8",
                                      fontSize: "10px",
                                      fontWeight: "500",
                                      minWidth: "100px",
                                      width: "auto",
                                      display: "flex",
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    {expandedSections[uniqueKey]
                                      ? "Click to Collapse"
                                      : "Click to Expand"}
                                  </div>
                                )}
                              </div>
                              {/* Subheaders */}
                              <div
                                ref={contentRefs.current[uniqueKey]}
                                style={{
                                  height: "0px",
                                  overflow: "hidden",
                                  transition: "height 0.3s ease",
                                  marginTop: "10px",
                                }}
                              >
                                <ul style={{ paddingLeft: "40px" }}>
                                  {subheaders.map((subheader, index) => (
                                    <li
                                    key={index}
                                    style={{
                                      fontSize: "14px",
                                      color: "#555",
                                      marginBottom: "5px",
                                      whiteSpace: "nowrap", // Prevents wrapping
                                      overflow: "hidden",   // Hides overflow
                                      textOverflow: "ellipsis", // Adds "..." for overflow
                                      maxWidth: "100%",    // Set a fixed width for truncation
                                    }}
                                    title={subheader} // Tooltip for full text
                                  >
                                    <div
                                      style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                      }}
                                    >
                                      <ReactMarkdown components={{ pre: "span", code: "span" }}>
                                        {subheader}
                                      </ReactMarkdown>
                                    </div>
                                  </li>
                                  
                                  ))}
                                </ul>
                              </div>
                            </li>
                          );
                        })}
                      </ul>


                      <div className="btn-wrapper-div-structure">
                        <button className={`toggle-editability-btn-${volumeIndex}`} onClick={() => toggleEditability(volumeIndex, volumeTag)} id="step23">
                          <FontAwesomeIcon icon={faEdit} /> {/* Replace Toggle Editability button with edit icon */}
                          {buttonText}
                        </button>
                        <button onClick={() => handleViewEditDraftClick(volumeIndex, volumeTag)} disabled={!hasDraft}>
                          View & Edit Draft
                        </button>

                      </div>
                    </div>
                  );
                })}

                {isGeneratingStructure && (
                    <div className="structure-modal-overlay">
                        <div className="structure-modal">
                            <h2 className="structure-modal-title">Generating New Proposal Structure...</h2>
                            <p className="structure-progress-step">{`Step ${currentStructureStep + 1} of ${totalStructureSteps}`}</p>
                            <div className="structure-progress-bar-container">
                                <div className="structure-progress-bar" style={{ width: `${structureProgressPercentage}%` }}></div>
                            </div>
                            <p className="structure-modal-text">
                                {proposalStructureMessages[currentStructureStep]}
                            </p>
                            <div className="scanning-modal-spinner">
                                <svg className="spinner" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="spinner-bg" cx="12" cy="12" r="10" strokeWidth="4"></circle>
                                    <path className="spinner-fg" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                )}

                {proposalStructureGenerated && (
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <button
                      onClick={() => addNewVolume(proposalStructureStage)}
                      style={{
                        width: 'auto',
                        height: '50px', // Set the height of the button to 50px
                        marginBottom: '20px', // Add some spacing between the button and the next component
                        backgroundColor: '#51c41b',
                      }}
                    >
                      Add Volume
                    </button>

                    <GenerateNEWProposalStructureSBIR
                      stage={proposalStructureStage}
                      onProposalStructureCreated={handleResponseFromGenerateNEWProposalStructure}
                      apiKey={apiKey}
                      setSelectedTab={setSelectedTab}
                      setProposalStructureStreamed={setProposalStructureStreamed}
                      complianceMatrixCreated={complianceMatrixCreated}
                      setProposalStructureStreaming={setProposalStructureStreaming}
                      selectedFile={selectedFile}
                      topics={getSelectedTopics()} // Pass the selected topics and subtopics
                      setIsGeneratingStructure={setIsGeneratingStructure}
                      setCurrentStructureStep={setCurrentStructureStep}
                    />
                  </div>
                )}





              </div>

            </div>
          </>
          )}


          {selectedTab === 'viewHowItWorks' && (
            <HowToUseGovExAISBIR />
          )}
          {selectedTab === 'viewReferral' && (
            <ReferralPage referralCode={referralCode} numReferrals={numReferrals} />
          )}







        </div>
        







        {(selectedTab === 'viewFirstDraft') && (
          <AISidebar
            isOpen={isAISidebarOpen}
            onClose={() => setIsAISidebarOpen(false)}
            onTextChange={(text) => setSelectedText(text)}
            text={selectedText}
            isAICollapsed={isAICollapsed}
            setIsAICollapsed={setIsAICollapsed}
            selectedRefFiles={selectedRefFiles}
            companyName={companyName}
            groupId={groupId}
            userID={userID}
          />
        )}
    </div>
  );
}

export default DashboardSBIR;
