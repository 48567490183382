import React, { useEffect } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop'; // Import the custom ScrollToTop component
import Documentation from './components/Documentation';
import Products from './components/Products';
import Solutions from './components/Solutions';
import TermsAndConditionsAndPrivacyPolicy from './components/TermsAndConditionsAndPrivacyPolicy';
import ReferralLandingPage from './components/ReferralLandingPage';
import CustomPricing from './components/CustomPricing';
import CustomPricingBaseTier from './components/CustomPricingBaseTier';
import CustomPricingInnovatorTier from './components/CustomPricingInnovatorTier';
import CustomPricingEnterpriseTier from './components/CustomPricingEnterpriseTier';
import CustomPricingUnlimitedTier from './components/CustomPricingUnlimitedTier';
import Team from './components/Team';
import Contact from './components/Contact';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Blog from './components/Blog';
import GovExProposal from './components/GovExProposal';
import OutlineGenerator from './components/OutlineGenerator.js';
import Article from './components/Article';
import Dashboard from './components/Dashboard';
import DashboardSBIR from './components/DashboardSBIR';
import PricingModelPage from './components/PricingModelPage';
import PricingModelPage2 from './components/PricingModelPage2';
import HowToUseSBIRDiagram from './components/HowToUseSBIRDiagram';
import PricingOld from './components/PricingOld';
import "./styles.css";

function App() {
  return (
        <Router>
          <ScrollToTop /> {/* Add the ScrollToTop component here */}
          <div className="App">
            {/* Navigation */}
            <Navbar />

            {/* Content */}
            <Routes>
              <Route path="/" element={<DashboardSBIR />} />
              <Route path="/#documentation" element={<Documentation />} />
              <Route path="/products" element={<Products />} />
              <Route path="/TermsAndConditionsAndPrivacyPolicy" element={<TermsAndConditionsAndPrivacyPolicy />} />
              <Route path="/referral/:referralCode" element={<ReferralLandingPage />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/CustomPricing" element={<CustomPricing />} />
              <Route path="/BaseTier" element={<CustomPricingBaseTier />} />
              <Route path="/InnovatorTier" element={<CustomPricingInnovatorTier />} />
              <Route path="/EnterpriseTier" element={<CustomPricingEnterpriseTier />} />
              <Route path="/UnlimitedTier" element={<CustomPricingUnlimitedTier />} />
              <Route path="/PricingModel" element={<PricingModelPage />} />
              <Route path="/Trial" element={<PricingModelPage2 />} />
              <Route path="/govEx-proposal" element={<GovExProposal />} />
              <Route path="/govEx-outline" element={<OutlineGenerator />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/blog/:articleId/:modifiedTitle" element={<Article />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/dashboard-grants" element={<DashboardSBIR />} />
              <Route path="/diagram" element={<HowToUseSBIRDiagram />} />
              <Route path="/GovEx-Pricing" element={<PricingOld />} />
            </Routes>

            {/* Footer */}
            <Footer />
          </div>
        </Router>
  );
}

export default App;
